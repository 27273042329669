import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SketchPicker } from "react-color";

import { Link } from "react-router-dom";
import {
  updateProductformData,
  getProductNameDatafetch,
  getProductdata,
  getCategoryDatafetch,
  getSubCategoryDatafetch,
} from "../Controller/Product";
import { useParams, useNavigate } from "react-router-dom";
import AddProductpopup from "./editproductpopup";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";

function EditProductData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [categories, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [openColorPickers, setOpenColorPickers] = useState([]);
  const [colorPickerField, setColorPickerField] = useState(null);
  const [colorPickerIndex, setColorPickerIndex] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [product, setProductData] = useState([]);

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    product_name: "",
    product_name_id: "",
    category_id: "",
    subcategory_id: "",
    return_period: "",
    cancellable: "",
    returnable: "",
    product_type: "",
    highlights: [],
    product_description: "",
    productvariant: "",
    favorite_product: "",
    about_product: "",
    ingredients: "",
    gst:"",
    discount:"",
    how_to_use: "",
    clinical_study_image: "",
    clinical_result_image: "",
    founder_tips_detail: "",
    shade_image: "",
    before_image: "",
    after_image: "",
    clinical_study: "",
    clinical_result: "",
    shade_title: "",
    color_size_details: [
      {
        color_code: "",
        shadeName:"",
        productShadeName: "",
        productShadeDetail: "",
        weight: "",
        ean_no:"",
        mrp_price: "",
        selling_price: "",
        productstock: "",
        show: false,
        images: [],
      },
    ],
  });
  const handleInputChange = (selectedValue, field, index) => {
    if (
      field === "product_description" ||
      field === "about_product" ||
      field === "ingredients" ||
      field === "how_to_use"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
    } else if (
      field === "cancellable" ||
      field === "returnable" ||
      field === "favorite_product"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue === "Yes" ? "Yes" : "No", // Ensure it's "Yes" or "No"
      }));
    } else if (field.startsWith("variant_")) {
      const variantIndex = parseInt(field.split("_")[1], 10);
      const sizeName = field.split("_")[3];
      setFormData((prevVal) => {
        const updatedColorSizeDetails = [...prevVal.color_size_details];
        if (!updatedColorSizeDetails[variantIndex]) {
          updatedColorSizeDetails[variantIndex] = {};
        }

        if (field.includes("color_code")) {
          updatedColorSizeDetails[variantIndex].color_code = selectedValue;
        } else if (field.includes("weight")) {
          updatedColorSizeDetails[variantIndex].weight = selectedValue;
        } else if (field.includes("shadeName")) {
          updatedColorSizeDetails[variantIndex].shadeName =
            selectedValue;
        }  else if (field.includes("productShadeName")) {
          updatedColorSizeDetails[variantIndex].productShadeName =
            selectedValue;
        } else if (field.includes("productShadeDetail")) {
          updatedColorSizeDetails[variantIndex].productShadeDetail =
            selectedValue;
        } else if (field.includes("mrp_price")) {
          updatedColorSizeDetails[variantIndex].mrp_price = selectedValue;
        }else if (field.includes("ean_no")) {
          updatedColorSizeDetails[variantIndex].ean_no = selectedValue;
        } else if (field.includes("selling_price")) {
          updatedColorSizeDetails[variantIndex].selling_price = selectedValue;
        } else if (field.includes("productstock")) {
          updatedColorSizeDetails[variantIndex].productstock = selectedValue;
        } else if (field.includes("show")) {
          updatedColorSizeDetails[variantIndex].show = selectedValue;
        } else {
          const updatedSizes = updatedColorSizeDetails[variantIndex].size || [];

          if (selectedValue) {
            updatedSizes.push(sizeName);
          } else {
            const sizeIndex = updatedSizes.indexOf(sizeName);
            if (sizeIndex !== -1) {
              updatedSizes.splice(sizeIndex, 1);
            }
          }

          updatedColorSizeDetails[variantIndex].size = updatedSizes;
        }

        return { ...prevVal, color_size_details: updatedColorSizeDetails };
      });
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [field]: "",
      }));
    }
  };

  
  const handleFileChangeForRow2 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        clinical_study_image: file,
      }));
    }
  };
  const handleFileChangeForRow3 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        clinical_result_image: file,
      }));
    }
  };
  const handleFileChangeForRow4 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, before_image: file }));
    }
  };
  const handleFileChangeForRow5 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, after_image: file }));
    }
  };

  const handleFileChangeForRow6 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, shade_image: file }));
    }
  };

  const [subCategories, setSubCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      } else if (!formData.sku) {
        setErrors((preError) => ({
          ...preError,
          sku: "Product sku no is required.",
        }));
      }else if (!formData.hsn) {
        setErrors((preError) => ({
          ...preError,
          hsn: "Product hsn no is required.",
        }));
      } else if (!formData.return_period) {
        setErrors((preError) => ({
          ...preError,
          return_period: "Return period is required.",
        }));
      } else if (!formData.productvariant) {
        setErrors((preError) => ({
          ...preError,
          productvariant: "Product variant is required.",
        }));
      } else if (!formData.category_id) {
        setErrors((preError) => ({
          ...preError,
          category_id: "Category is required.",
        }));
      } else if (!formData.subcategory_id) {
        setErrors((preError) => ({
          ...preError,
          subcategory_id: "Sub category is required.",
        }));
      } else if (!formData.product_type) {
        setErrors((preError) => ({
          ...preError,
          product_type: "Product type is required.",
        }));
      } else if (!formData.gst) {
        setErrors((preError) => ({
          ...preError,
          gst: "Gst is required.",
        }));
      } else if (!formData.discount) {
        setErrors((preError) => ({
          ...preError,
          discount: "Discount is required.",
        }));
      } else if (!formData.product_description) {
        setErrors((preError) => ({
          ...preError,
          product_description: "Product description is required.",
        }));
      }else if (!formData.about_product) {
        setErrors((preError) => ({
          ...preError,
          about_product: "About us is required.",
        }));
      }else if (!formData.ingredients) {
        setErrors((preError) => ({
          ...preError,
          ingredients: "Ingredient is required.",
        }));
      }else if (!formData.how_to_use) {
        setErrors((preError) => ({
          ...preError,
          how_to_use: "How to use  is required.",
        }));
      }
      else if (!formData.founder_tips_detail) {
        setErrors((preError) => ({
          ...preError,
          founder_tips_detail: "Founder tips   is required.",
        }));
      }else if (!formData.shade_title) {
        setErrors((preError) => ({
          ...preError,
          shade_title: "Shade finder title  is required.",
        }));
      }else if (!formData.clinical_study) {
        setErrors((preError) => ({
          ...preError,
          clinical_study: "Clinical study  is required.",
        }));
      }else if (!formData.clinical_result) {
        setErrors((preError) => ({
          ...preError,
          clinical_result: "Clinical result  is required.",
        }));
      }else {
        setIsSubmitting(true); 
        formData.return_period = document.getElementById("return_period").value;
        const updateResult = await updateProductformData(
          formData,
          adminInformation.token,
          formData.color_size_details
        );
        if (updateResult.data.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
            sku: "",
            hsn: "",
            category: "",
            product_description: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          // Handle error scenario
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const getproductfetchdata = async () => {
    const options = { id: edit };
    try {
      const result = await getProductdata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData1 = result.data.response.result[0];
        setSelectedCategory(responseData1.category_id);
        setSelectedSubCategory(responseData1.subcategory_id);

        setFormData({
          ...responseData1,
          id: responseData1._id,
        });
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    const options = {
      category_id: categoryId,
    };

    try {
      const result = await getSubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const handleFileChangeForRow = (e, index) => {
    const files = e.target.files;
    const updatedVariants = [...formData.color_size_details];

    // Remove existing images for the corresponding variant index
    updatedVariants[index].images = [];

    // Update the images array for the corresponding variant index
    updatedVariants[index].images = [...files];

    // Update the form data with the new images
    setFormData((prevFormData) => ({
      ...prevFormData,
      color_size_details: updatedVariants,
    }));
  };
  const handleFileChangeForRow1 = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevFormData) => {
      // Create a new highlights array with the new images as File objects
      const newHighlights = [
        {
          images: files, // Directly store File objects
        },
      ];

     
      return {
        ...prevFormData,
        highlights: newHighlights, // Replace the existing highlights with the new ones
      };
    });
  };

  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handleColorChange = (newColor) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      color_size_details: prevFormData.color_size_details.map(
        (variant, index) => {
          if (index === colorPickerIndex) {
            return {
              ...variant,
              color_code: newColor.hex,
            };
          }
          return variant;
        }
      ),
    }));
  };

  const toggleColorPicker = (index, field, e) => {
    e.preventDefault();
    const isOpen = openColorPickers.includes(index);
    if (isOpen) {
      setOpenColorPickers(openColorPickers.filter((i) => i !== index));
    } else {
      setOpenColorPickers([...openColorPickers, index]);
    }
    setColorPickerIndex(index);
    setColorPickerField(field);
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      color_size_details: [
        ...prevVal.color_size_details,
        {
          color_code: "",
          shadeName:"",
          productShadeName: "",
          productShadeDetail: "",
          productvariant: "",
          weight: "",
          mrp_price: "",
          selling_price: "",
          productstock: "",
          show: false,
          images: [],
        },
      ],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.color_size_details];
      updatedVariants.splice(index, 1);
      return { ...prevVal, color_size_details: updatedVariants };
    });
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    handleInputChange(categoryId, "category_id");

    setSelectedSubCategory(""); // Clear the selected subcategory when the category changes
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getproductfetchdata();
    fetchCategories();

    // Simulate a category change to load initial subcategories
    if (categories.length > 0) {
      const initialCategory = categories[0]._id;
      fetchSubCategories(initialCategory);
      setSelectedCategory(initialCategory);
    }
  }, []); // Empty dependency array means this effect runs only on mount
  const fetchProduct = async () => {
    const options = {};
    try {
      const result = await getProductNameDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setProductData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  // Modify the second useEffect to handle category and subcategory changes
  useEffect(() => {
    window.scrollTo(0, 0);
    getproductfetchdata();
    fetchCategories();
    fetchProduct();
    // Fetch subcategories only if a category is selected
    if (selectedCategory) {
      fetchSubCategories(selectedCategory);
      setFormData((prevVal) => ({
        ...prevVal,
        subcategory_id: "",
      }));
    }
  }, [selectedCategory]);

  useEffect(() => {
    setFormData((prevVal) => ({
      ...prevVal,
      subcategory_id: selectedSubCategory,
    }));
  }, [selectedSubCategory]);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div
            id="content-wrapper"
            className="d-flex flex-column all_product_hidden_edit"
          >
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Edit Product Content </h5>
                </div>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Product Name<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section"
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "product_name"
                                )
                              }
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                            >
                              <option value="">Select Product Name</option>
                              {product.map((productItem) => (
                                <option
                                  key={productItem._id}
                                  value={productItem.product_name}
                                >
                                  {productItem.product_name}
                                </option>
                              ))}
                            </select>

                            {errors?.product_name && (
                              <p style={{ color: "red" }}>
                                {errors?.product_name}
                              </p>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              SKU No<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section"
                              id="sku"
                              name="sku"
                              value={formData.sku}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "sku")
                              }
                            />
                            {errors?.sku ? (
                              <p style={{ color: "red" }}>{errors?.sku}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              HSN No<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section"
                              id="hsn"
                              name="hsn"
                              value={formData.hsn}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "hsn")
                              }
                            />
                            {errors?.hsn ? (
                              <p style={{ color: "red" }}>{errors?.hsn}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Return Period(In Days)<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section"
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "return_period"
                                )
                              }
                              id="return_period"
                              name="return_period"
                              value={formData.return_period}
                            >
                              <option value="">Select Return Day</option>
                              <option value="7">7</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                            </select>
                            {errors?.return_period && (
                              <p style={{ color: "red" }}>
                                {errors?.return_period}
                              </p>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Product Variant<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section"
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "productvariant"
                                );
                              }}
                              id="productvariant"
                              name="productvariant"
                              value={formData.productvariant}
                            >
                              <option value="">Select Variant </option>
                              <option value="30 ml">30 ml</option>
                              <option value="10 ml">10 ml</option>
                              <option value="5 ml">5 ml</option>
                            </select>
                            {errors?.productvariant ? (
                              <p style={{ color: "red" }}>
                                {errors?.productvariant}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">Category<span style={{ color: 'red' }}>*</span></label>
                            <select
                              className="form-control common_category_input_for_all_section"
                              onChange={handleCategoryChange}
                              value={selectedCategory}
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option
                                  key={category._id}
                                  value={category._id}
                                  selected={
                                    category._id === formData.category_id
                                  }
                                >
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            {errors?.category_id && (
                              <p style={{ color: "red" }}>
                                {errors?.category_id}
                              </p>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Sub Category<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section"
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "subcategory_id"
                                )
                              }
                              value={formData.subcategory_id}
                            >
                              <option value="">Select Sub Category</option>
                              {subCategories.map((subCategory) => (
                                <option
                                  key={subCategory._id}
                                  value={subCategory._id}
                                  selected={
                                    subCategory._id === formData.subcategory_id
                                  }
                                >
                                  {subCategory.name}
                                </option>
                              ))}
                            </select>
                            {errors?.subcategory_id && (
                              <p style={{ color: "red" }}>
                                {errors?.subcategory_id}
                              </p>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Product Type<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "product_type"
                                );
                              }}
                              id="product_type"
                              name="product_type"
                              value={formData.product_type}
                            >
                              <option value="">Select Product Type </option>
                              <option value="Foundation">Foundation</option>
                              <option value="Shade Adjusting Drops">
                                Shade Adjusting Drops
                              </option>
                            </select>
                            {errors?.product_type ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_type}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              GST<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "gst"
                                );
                              }}
                              id="gst"
                              name="gst"
                              value={formData.gst}
                            >
                              <option value="">Select GST </option>
                              <option value="8 %">8 %</option>
                              <option value="9 %">9 %</option>
                              <option value="12 %">12 %</option>
                              <option value="18 %">18 %</option>
                            </select>
                            {errors?.gst ? (
                              <p style={{ color: "red" }}>
                                {errors?.gst}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Discount<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="discount"
                            name="discount"
                            value={formData.discount}
                            onChange={(e) => handleInputChange(e.target.value, "discount")}
                          />
                            {errors?.discount ? (
                              <p style={{ color: "red" }}>
                                {errors?.discount}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputCheckbox">
                              Favorite Products
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input common_category_input_for_all_section"
                              id="favorite_product"
                              name="favorite_product"
                              checked={formData.favorite_product === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked ? "Yes" : "No",
                                  "favorite_product"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputCheckbox">
                              Returnable
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input"
                              id="returnable"
                              name="returnable"
                              checked={formData.returnable === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked ? "Yes" : "No",
                                  "returnable"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputCheckbox">
                              Cancellable
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input"
                              id="cancellable"
                              name="cancellable"
                              checked={formData.cancellable === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked ? "Yes" : "No",
                                  "cancellable"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: 'red' }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="product_description"
                            name="product_description"
                            value={formData.product_description}
                            onChange={(value) =>
                              handleInputChange(value, "product_description")
                            }
                          />
                          {errors?.product_description && (
                            <p style={{ color: "red" }}>
                              {errors?.product_description}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Shade</th>
                                <th>Shade Name</th>
                                <th>Product Shade Name</th>
                                <th>Product Shade Detail</th>
                                <th>EAN No</th>
                                {/* <th>Variant</th> */}
                                <th>Weight</th>
                                <th>MRP Price</th>
                                <th>Selling Price</th>
                                <th>Stock</th>
                                <th>Active</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.color_size_details.map(
                                (variant, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div>
                                          <div
                                            style={{ display: "inline-block" }}
                                          >
                                            <div
                                              onClick={(e) =>
                                                toggleColorPicker(
                                                  index,
                                                  "color_code",
                                                  e
                                                )
                                              } // Toggle color picker on click
                                              style={{
                                                width: "36px",
                                                height: "14px",
                                                borderRadius: "2px",
                                                backgroundColor:
                                                  variant.color_code,
                                              }}
                                            >
                                              Click
                                            </div>
                                            {openColorPickers.includes(
                                              index
                                            ) && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  zIndex: "2",
                                                }}
                                              >
                                                <SketchPicker
                                                  color={variant.color_code}
                                                  onChange={(color) =>
                                                    handleColorChange(color)
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control common_category_input_for_all_section "
                                          id={`shadeName${index}`}
                                          name={`shadeName${index}`}
                                          value={variant.shadeName}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_shadeName`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control common_category_input_for_all_section "
                                          id={`productShadeName${index}`}
                                          name={`productShadeName${index}`}
                                          value={variant.productShadeName}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_productShadeName`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                     
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control common_category_input_for_all_section "
                                          id={`productShadeDetail${index}`}
                                          name={`productShadeDetail${index}`}
                                          value={variant.productShadeDetail}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_productShadeDetail`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control common_category_input_for_all_section "
                                          id={`ean_no${index}`}
                                          name={`ean_no${index}`}
                                          value={variant.ean_no}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_ean_no`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`weight${index}`}
                                          name={`weight${index}`}
                                          value={variant.weight}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_weight`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`mrp_price${index}`}
                                          name={`mrp_price${index}`}
                                          value={variant.mrp_price}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_mrp_price`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`selling_price${index}`}
                                          name={`selling_price${index}`}
                                          value={variant.selling_price}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_selling_price`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`productstock${index}`}
                                          name={`productstock${index}`}
                                          value={variant.productstock}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              `variant_${index}_productstock`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          className="form-check"
                                          id={`show_${index}`}
                                          name={`show_${index}`}
                                          checked={variant.show}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.checked,
                                              `variant_${index}_show`,
                                              index
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        {index > 0 && (
                                          <button
                                            type="button"
                                            className="btn btn-danger remove-variant"
                                            onClick={() => removeVariant(index)}
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="8">
                                        <div className="d-flex flex-row">
                                          {/* Render input fields for images */}
                                          <input
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            onChange={(e) =>
                                              handleFileChangeForRow(e, index)
                                            }
                                          />
                                          {/* Display images */}
                                          {variant.images.map(
                                            (file, imageIndex) => (
                                              <div
                                                key={imageIndex}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    typeof file === "string"
                                                      ? `${imageURL}${file}`
                                                      : file instanceof Blob
                                                      ? URL.createObjectURL(
                                                          file
                                                        )
                                                      : ""
                                                  }
                                                  alt={`Image ${imageIndex}`}
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    margin: "5px",
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </tbody>
                          </table>
                          <br />
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn btn-success remove-variant"
                              onClick={addVariant}
                            >
                              Add Variant
                            </button>
                          </div>
                        </div>
                        <fieldset>
                          <legend>About Us</legend>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              About Product<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="about_product"
                              name="about_product"
                              value={formData.about_product}
                              onChange={(value) =>
                                handleInputChange(value, "about_product")
                              }
                            />
                                                         {errors?.about_product ? (
                              <p style={{ color: "red" }}>
                                {errors?.about_product}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Ingredients<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="ingredients"
                              name="ingredients"
                              value={formData.ingredients}
                              onChange={(value) =>
                                handleInputChange(value, "ingredients")
                              }
                            />
                             {errors?.ingredients ? (
                              <p style={{ color: "red" }}>
                                {errors?.ingredients}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              How to Use<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="how_to_use"
                              name="how_to_use"
                              value={formData.how_to_use}
                              onChange={(value) =>
                                handleInputChange(value, "how_to_use")
                              }
                            />
                             {errors?.how_to_use ? (
                              <p style={{ color: "red" }}>
                                {errors?.how_to_use}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Highlights</legend>
                          <div className="form-group">
                            <label htmlFor="highlight_images">
                              Highlight Images<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleFileChangeForRow1}
                            />
                            <div className="edit_product_main_image_heighlight">
                              {formData.highlights &&
                                formData.highlights.map(
                                  (highlight, highlightIndex) => (
                                    <div
                                      key={highlightIndex}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {highlight.images ? (
                                        highlight.images.map(
                                          (file, imageIndex) => (
                                            <div
                                              key={imageIndex}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Highlight ${imageIndex}`}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  margin: "5px",
                                                }}
                                              />
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <img
                                          src={`${imageURL}${highlight}`}
                                          alt={`Image ${highlightIndex}-${highlight}`}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            margin: "5px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="founder_tips_detail">
                              Founder's Tips<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="founder_tips_detail"
                              name="founder_tips_detail"
                              value={formData.founder_tips_detail}
                              onChange={(value) =>
                                handleInputChange(value, "founder_tips_detail")
                              }
                            />
                             {errors?.founder_tips_detail ? (
                              <p style={{ color: "red" }}>
                                {errors?.founder_tips_detail}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Shade Finder</legend>
                          <div className="form-group">
                            <label htmlFor="shade_title">
                              Shade Finder Title<span style={{ color: 'red' }}>*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="shade_title"
                              name="shade_title"
                              value={formData.shade_title}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "shade_title")
                              }
                            />
                             {errors?.shade_title ? (
                              <p style={{ color: "red" }}>
                                {errors?.shade_title}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">Shade Finder Image<span style={{ color: 'red' }}>*</span></label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader"
                                name="shade_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow6(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.shade_image ? (
                                  <img
                                    src={
                                      typeof formData.shade_image === "string"
                                        ? `${imageURL}${formData.shade_image}`
                                        : URL.createObjectURL(
                                            formData.shade_image
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Clinical Study</legend>
                          <div className="form-group">
                            <label htmlFor="clinical_study">
                              Clinical Study<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="clinical_study"
                              name="clinical_study"
                              value={formData.clinical_study}
                              onChange={(value) =>
                                handleInputChange(value, "clinical_study")
                              }
                            />
                             {errors?.clinical_study ? (
                              <p style={{ color: "red" }}>
                                {errors?.clinical_study}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">
                              Clinical Study Image<span style={{ color: 'red' }}>*</span>
                            </label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader"
                                name="clinical_study_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow2(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.clinical_study_image ? (
                                  <img
                                    src={
                                      typeof formData.clinical_study_image ===
                                      "string"
                                        ? `${imageURL}${formData.clinical_study_image}`
                                        : URL.createObjectURL(
                                            formData.clinical_study_image
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>

                          {/* Clinical Results */}
                          <div className="form-group">
                            <label htmlFor="clinical_result">
                              Clinical Results<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="clinical_result"
                              name="clinical_result"
                              value={formData.clinical_result}
                              onChange={(value) =>
                                handleInputChange(value, "clinical_result")
                              }
                            />
                             {errors?.clinical_result ? (
                          <p style={{ color: "red" }}>
                            {errors?.clinical_result}
                          </p>
                        ) : (
                          ""
                        )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="fullname">
                              Clinical Results Image<span style={{ color: 'red' }}>*</span>
                            </label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader"
                                name="clinical_result_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow3(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.clinical_result_image ? (
                                  <img
                                    src={
                                      typeof formData.clinical_result_image ===
                                      "string"
                                        ? `${imageURL}${formData.clinical_result_image}`
                                        : URL.createObjectURL(
                                            formData.clinical_result_image
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Before/After</legend>
                          <div className="form-group">
                            <label htmlFor="fullname">Before Image<span style={{ color: 'red' }}>*</span></label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader"
                                name="before_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow4(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.before_image ? (
                                  <img
                                    src={
                                      typeof formData.before_image === "string"
                                        ? `${imageURL}${formData.before_image}`
                                        : URL.createObjectURL(
                                            formData.before_image
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>

                          <div className="form-group">
                            <label htmlFor="fullname">After Image<span style={{ color: 'red' }}>*</span></label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader"
                                name="after_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow5(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.after_image ? (
                                  <img
                                    src={
                                      typeof formData.after_image === "string"
                                        ? `${imageURL}${formData.after_image}`
                                        : URL.createObjectURL(
                                            formData.after_image
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </fieldset>

                        <div className="form-group row">
                          <div className="col-md-12">
                          <button type="submit" className="btn btn-primary" disabled={isSubmitting} 
        style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
        {isSubmitting ? (
          <span>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...
          </span>
        ) : (
          'Update'
        )}
      </button>
          <Link to="/productListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="my-auto"> </div>
              </div>
            </footer>
          </div>
        </div>
        <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
      </div>
    </div>
  );
}

export default EditProductData;
