import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Upload } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { updateBlogformData } from "../../Component/Controller/Blog"; // Update imports accordingly
import { useLocation } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";
import AddblogModel from "./Editblogpopup";

function EditBlogData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [selectedItem, setSelectedItem] = useState({}); // Define setSelectedItem

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    short_description: "",
    long_description: "",
    sequence: "",
    second_title: "",
    top_image: null,
    left_images: null,
    right_images: null,
    // variants: [{ images: null }],
  });
  const handleFileChange = (fileList, index) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => {
        const updatedVariants = [...prevFormData.variants];
        // Check if the file has changed
        if (file !== updatedVariants[index].images) {
          updatedVariants[index].images = file;
        }
        return { ...prevFormData, variants: updatedVariants };
      });
    }
  };

  const handleInputChange = (value, field) => {
    if (field === "title") {
      setFormData((prevVal) => ({
        ...prevVal,
        title: value,
      }));
    } else if (field === "short_description") {
      setFormData((prevVal) => ({
        ...prevVal,
        short_description: value,
      }));
    } else if (field === "long_description") {
      setFormData((prevVal) => ({
        ...prevVal,
        long_description: value,
      }));
    } else if (field === "sequence") {
      setFormData((prevVal) => ({
        ...prevVal,
        sequence: value,
      }));
    } else {
      const name = field;
      const inputValue = value;

      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  const handleFileChange1 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, top_image: file }));
    }
  };
  const handleFileChange2 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, left_images: file }));
    }
  };
  const handleFileChange3 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, right_images: file }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required.",
        }));
      } else if (!formData.short_description) {
        setErrors((preError) => ({
          ...preError,
          short_description: "Short Description is required.",
        }));
      } else if (!formData.sequence) {
        setErrors((preError) => ({
          ...preError,
          sequence: "Sequence is required.",
        }));
      } else if (!formData.short_description) {
        setErrors((preError) => ({
          ...preError,
          short_description: "Short description is required.",
        }));
      } else if (!formData.long_description) {
        setErrors((preError) => ({
          ...preError,
          long_description: "Long description is required.",
        }));
      } else if (!formData.top_image) {
        setErrors((preError) => ({
          ...preError,
          top_image: "Front image is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateBlogformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            title: "",
            long_description: "",
            top_image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          //  localStorage.removeItem('TOKEN');
          //  localStorage.removeItem('ADMIN-INFO');
          //  navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [...prevVal.variants, { images: null }],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const locationState = location.state;
    if (locationState && locationState.selectedItem) {
      setSelectedItem(locationState.selectedItem);
      // Set formData state with selectedItem's values
      setFormData({
        ...formData,
        id: locationState.selectedItem._id,
        title: locationState.selectedItem.title,
        second_title: locationState.selectedItem.second_title,
        short_description: locationState.selectedItem.short_description,
        long_description: locationState.selectedItem.long_description,
        sequence: locationState.selectedItem.sequence,
        top_image: locationState.selectedItem.top_image,
        left_images: locationState.selectedItem.left_images,
        right_images: locationState.selectedItem.right_images,
      });
    }
  }, [location.state]);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Edit Blog Content </h5>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputText" >
                            Title<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="title"
                            name="title" // Make sure the name attribute is set to 'title'
                            value={formData.title}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "title")
                            }
                          />

                          {errors.title && (
                            <p style={{ color: "red" }}>{errors.title}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputText">
                            Second Title<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="second_title"
                            name="second_title" // Make sure the name attribute is set to 'title'
                            value={formData.second_title}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "second_title")
                            }
                          />

                          {errors.second_title && (
                            <p style={{ color: "red" }}>
                              {errors.second_title}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputText">
                            Sequence<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="sequence"
                            value={formData.sequence}
                            name="sequence"
                            onChange={(e) =>
                              handleInputChange(e.target.value, "sequence")
                            }
                          />
                          {errors.sequence && (
                            <p style={{ color: "red" }}>{errors.sequence}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Short Description
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="short_description"
                            name="short_description"
                            value={formData.short_description}
                            onChange={(value) =>
                              handleInputChange(value, "short_description")
                            }
                          />
                          {errors.short_description && (
                            <p style={{ color: "red" }}>
                              {errors.short_description}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Long Description
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="long_description"
                            name="long_description"
                            value={formData.long_description}
                            onChange={(value) =>
                              handleInputChange(value, "long_description")
                            }
                          />
                          {errors.long_description && (
                            <p style={{ color: "red" }}>
                              {errors.long_description}
                            </p>
                          )}
                        </div>
                        <div className="row">
                          {/* <p style={{ color: "blue" }}>Recommended size: 636x850 (width x height)px</p> */}
                          <div className="form-group">
                            <label htmlFor="fullname">Front Image</label>
                            <ImgCrop rotate aspect={636 / 850}>
                              <Upload
                                className="avatar-uploader"
                                name="top_image"
                                onChange={({ fileList }) =>
                                  handleFileChange1(fileList)
                                }
                                listType="picture-card"
                                fileList={null}
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.top_image ? (
                                  <img
                                    src={
                                      typeof formData.top_image === "string"
                                        ? `${imageURL}${formData.top_image}`
                                        : URL.createObjectURL(
                                            formData.top_image
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">Left Image</label>
                            <ImgCrop rotate aspect={636 / 850}>
                              <Upload
                                className="avatar-uploader"
                                name="left_images"
                                onChange={({ fileList }) =>
                                  handleFileChange2(fileList)
                                }
                                listType="picture-card"
                                fileList={null}
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.left_images ? (
                                  <img
                                    src={
                                      typeof formData.left_images === "string"
                                        ? `${imageURL}${formData.left_images}`
                                        : URL.createObjectURL(
                                            formData.left_images
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">Right Image</label>
                            <ImgCrop rotate aspect={636 / 850}>
                              <Upload
                                className="avatar-uploader"
                                name="right_images"
                                onChange={({ fileList }) =>
                                  handleFileChange3(fileList)
                                }
                                listType="picture-card"
                                fileList={null}
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.right_images ? (
                                  <img
                                    src={
                                      typeof formData.right_images === "string"
                                        ? `${imageURL}${formData.right_images}`
                                        : URL.createObjectURL(
                                            formData.right_images
                                          )
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </div>
                        {/* <div className="row">
                              
                        {formData.variants.map((variant, index) => (
                          <div className="form-group" key={index}>
                            <label htmlFor={`images_${index}`}>Images</label>
                            <div className="image-upload-container">
                              <div className="image-upload-item">
                                <ImgCrop rotate aspect={310 / 213}>
                                  <Upload
                                    className="avatar-uploader"
                                    name={`images_${index}`}
                                    onChange={({ fileList }) => handleFileChange(fileList, index)}
                                    listType="picture-card"
                                    fileList={null}
                                    beforeUpload={() => false}
                                    style={{ width: '100%', height: '100%' }}
                                  >
                                    {variant.images ? (
                                      <img src={typeof variant.images === 'string' ? `${imageURL}${variant.images}` : URL.createObjectURL(variant.images)} alt={`images_${index}`} className="image-preview" />
                                    ) : (
                                      <div>
                                        <InboxOutlined />
                                        <div className="ant-upload-text">Upload</div>
                                      </div>
                                    )}
                                  </Upload>
                                </ImgCrop>
                                <br></br>
                                <div className="col-md-2">
                                  <br></br>
                                  {index > 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-danger remove-variant"
                                      onClick={() => removeVariant(index)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        </div> */}

                        {/* <div className="col-md-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={addVariant}
                          >
                            Add
                          </button>
                        </div> */}
                        <br></br>

                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#e7aeb6",
                            color: "#fff",
                            borderColor: "#e7aeb6",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Update"
                          )}
                        </button>
                        <Link to="/bLogListPage">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#e7aeb6",
                              color: "#fff",
                              borderColor: "#e7aeb6",
                              marginLeft: "12px",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default EditBlogData;
