import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Form } from "react-bootstrap";
import { addCoupenformData } from "../Controller/Coupen";
import {
  getCategoryDatafetch,
  getSubCategoryDatafetch,
} from "../Controller/Product";
import { Link, useNavigate } from "react-router-dom";
import AddCoupenpopup from "./AddCoupenpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Coupen.css";

function AddCoupenData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [subCategories, setSubCategoryData] = useState([]);
  const [categories, setCategoryData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    coupon_code: "",
    discount: "",
    flat_discount: "",
    description:"",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    quantity: "",
    eligible_amount: "",
    max_amount: "",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData((prevVal) => ({
      ...prevVal,
      [fieldName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "", // Clear any previous errors for this field
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      if (!formData.coupon_code) {
        setErrors((preError) => ({
          ...preError,
          coupon_code: "Coupon code is required.",
        }));
      }  if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: "Description is required.",
        }));
      } else if (!formData.start_date) {
        setErrors((preError) => ({
          ...preError,
          start_date: "Start date is required.",
        }));
      } else if (!formData.start_time) {
        setErrors((preError) => ({
          ...preError,
          start_time: "Start time is required.",
        }));
      } else if (!formData.end_date) {
        setErrors((preError) => ({
          ...preError,
          end_date: "End Date is required.",
        }));
      } else if (!formData.end_time) {
        setErrors((preError) => ({
          ...preError,
          end_time: "End time is required.",
        }));
      } else if (!formData.quantity) {
        setErrors((preError) => ({
          ...preError,
          quantity: "Quantity is required.",
        }));
      } else if (!formData.eligible_amount) {
        setErrors((preError) => ({
          ...preError,
          eligible_amount: "Eligible amount is required.",
        }));
      } else if (!formData.max_amount) {
        setErrors((preError) => ({
          ...preError,
          max_amount: "Max amount is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addCoupenformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            coupon_code: "",
            discount: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          //   localStorage.removeItem('TOKEN');
          // localStorage.removeItem('ADMIN-INFO');
          // navigate("/login");
          setErrors((preError) => ({
            ...preError,
            coupon_code:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  const fetchSubCategories = async (categoryId) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      category_id: categoryId,
    };

    try {
   
      const result = await getSubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleSelectClick = () => {
    fetchCategories();
  };
  const handleCategorySelect = (categoryId) => {
    fetchSubCategories(categoryId);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Dragger } = Upload;

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Coupon </h1>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        {/* <div className="col-md-6">
                        <label htmlFor="exampleInputText">Category</label>
                        <select
  className="form-control"
  onChange={(e) => {
    handleInputChange(e,"category_id");
    handleCategorySelect(e.target.value.split("|")[0], e.target.value.split("|")[1]);
  }}
  onClick={handleSelectClick}
>

                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                        {errors?.category_id ? <p style={{ color: "red" }}>{errors?.category_id}</p> : ""}
                      </div>

                              <div className="col-md-6">
                                <label htmlFor="exampleInputText">Sub Category</label>
                                <select
  className="form-control"
  onChange={(e) => handleInputChange(e, "subcategory_id")}
>

                                  <option value="">Select Sub Category</option>
                                  {subCategories.map((subCategory) => (
                                    <option key={subCategory._id} value={subCategory._id}>
                                      {subCategory.name}
                                    </option>
                                  ))}
                                </select>
                                {errors?.subcategory_id ? <p style={{ color: "red" }}>{errors?.subcategory_id}</p> : ""}
                              </div>
        */}
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">Coupon Code<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="coupon_code"
                            name="coupon_code"
                            placeholder="Enter Coupen Code"
                            onChange={(e) =>
                              handleInputChange(e, "coupon_code")
                            }
                          />
                          {errors?.coupon_code ? (
                            <p style={{ color: "red" }}>
                              {errors?.coupon_code}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">Description<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="description"
                            name="description"
                            placeholder="Enter Description"
                            onChange={(e) =>
                              handleInputChange(e, "description")
                            }
                          />
                          {errors?.description ? (
                            <p style={{ color: "red" }}>
                              {errors?.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="discount">Discount</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="discount"
                            name="discount"
                            placeholder="Enter Discount"
                            onChange={(e) => handleInputChange(e, "discount")}
                          />
                          {errors?.discount ? (
                            <p style={{ color: "red" }}>{errors?.discount}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="discount">Flat Discount</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="flat_discount"
                            name="flat_discount"
                            placeholder="Enter Flat Discount"
                            onChange={(e) =>
                              handleInputChange(e, "flat_discount")
                            }
                          />
                          {errors?.flat_discount ? (
                            <p style={{ color: "red" }}>
                              {errors?.flat_discount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="start_date">
                            Start Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control common_category_input_for_all_section "
                            id="start_date"
                            name="start_date"
                            placeholder="Enter Start Date"
                            min={new Date().toISOString().split("T")[0]} // Set the min attribute to today's date
                            onChange={(e) => handleInputChange(e, "start_date")}
                          />
                          {errors?.start_date ? (
                            <p style={{ color: "red" }}>{errors?.start_date}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 start_time_for_luar">
                          <label htmlFor="start_time">
                            Start Time<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control common_category_input_for_all_section "
                            id="start_time"
                            name="start_time"
                            placeholder="Enter Start Time"
                            onChange={(e) => handleInputChange(e, "start_time")}
                          />
                          {errors?.start_time ? (
                            <p style={{ color: "red" }}>{errors?.start_time}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="end_date">
                            End Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control common_category_input_for_all_section "
                            id="end_date"
                            name="end_date"
                            placeholder="Enter End Date"
                            min={new Date().toISOString().split("T")[0]} // Set the min attribute to today's date
                            onChange={(e) => handleInputChange(e, "end_date")}
                          />
                          {errors?.end_date ? (
                            <p style={{ color: "red" }}>{errors?.end_date}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="end_time">
                            End Time<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control common_category_input_for_all_section "
                            id="end_time"
                            name="end_time"
                            placeholder="Enter End Time"
                            onChange={(e) => handleInputChange(e, "end_time")}
                          />
                          {errors?.end_time ? (
                            <p style={{ color: "red" }}>{errors?.end_time}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="quantity">
                            Quantity<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control common_category_input_for_all_section "
                            id="quantity"
                            name="quantity"
                            placeholder="Enter Quantity"
                            onChange={(e) => handleInputChange(e, "quantity")}
                          />
                          {errors?.quantity ? (
                            <p style={{ color: "red" }}>{errors?.quantity}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">
                            Eligible Amount
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control common_category_input_for_all_section "
                            id="eligible_amount"
                            name="eligible_amount"
                            placeholder="Enter Eligible Amount"
                            onChange={(e) =>
                              handleInputChange(e, "eligible_amount")
                            }
                          />
                          {errors?.eligible_amount ? (
                            <p style={{ color: "red" }}>
                              {errors?.eligible_amount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">
                            Maximum Amount
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control common_category_input_for_all_section "
                            id="max_amount"
                            name="max_amount"
                            placeholder="Enter Maximum Amount"
                            onChange={(e) => handleInputChange(e, "max_amount")}
                          />
                          {errors?.max_amount ? (
                            <p style={{ color: "red" }}>{errors?.max_amount}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/coupenListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCoupenData;
