import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import { statusCategoryData } from '../Controller/Review';

const ReviewStatus = ({ popUpOpenChange, togglePopUpChange, itemIdToChange, status }) => {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const navigate = useNavigate();
  const adminInformation = JSON.parse(adminInformationString);

  const handleCancel = () => {
    togglePopUpChange();
  };

  const handleUpdateStatus = async () => {
    const options = { id: itemIdToChange, status: status };
    try {
      const result = await statusCategoryData(options, adminInformation.token);
      if (result.data && result.data.status === true) {
        togglePopUpChange(); // Close the dialog on success
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <Dialog
    open={popUpOpenChange}
    onClose={handleCancel}
    maxWidth="md"
    PaperProps={{
      className: "myDialogPopUp",
    }}
  >
    <div className="myDrawer">
      <div className="myMainDrawerClass">
        <div>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            className="myFilterClass"
          > Change Review Status
          </Typography>
        </div>
        <div>
          <IconButton onClick={togglePopUpChange}>
            <IoMdClose />
          </IconButton>
        </div>
      </div>
      <Typography
        variant="body1"
        component="div"
        className="my_delete_popup_paragraph"
      >
         Are you sure you want to {status === "A" ? "approve" : "disapprove"} this review?
      </Typography>
      <DialogActions>
       
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdateStatus} color="primary" autoFocus>
          Confirm
        </Button>
       {/*} <Button onClick={handleLogout} color="primary">
          Ok
  </Button>*/}
      </DialogActions>
    </div>
  </Dialog>
  );
};

export default ReviewStatus;
