import React, { useState } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../../AllCssFile.css"; 
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { addFaqformData} from  "../../Controller/OrderFaq"; 
import Addfaqpopup from "./Addorderfaqpopup"; 
function OrderFaqCreate() { 
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
    
  });

  const handleInputChange = (value, field) => {
    if (field === 'answer') {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === 'question') {
      setFormData((prevVal) => ({
        ...prevVal,
        question: value,  // Corrected from 'title' to 'question'
      }));
    } else {
      const name = field;
      const inputValue = value;
  
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
  
  
  
  
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.question) {
        setErrors((preError) => ({
          ...preError,
          question: 'Question is required.',
        }));
      }else if (!formData.answer) {
        setErrors((preError) => ({
          ...preError,
          answer: 'Answer is required.',
        }));
      }else{
        setIsSubmitting(true);
        const updateResult = await addFaqformData(formData, adminInformation.token);
        if(updateResult.data.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
              question: "",
              answer: "",
            }));
            setIsSubmitting(false);
            togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Order & Payment Faq Content </h5>
                  </div>
                </div>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                          <div className="form-group">
                            <label htmlFor="exampleInputText">Question<span style={{ color: "red" }}>*</span></label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section "
                              id="question"
                              name="question"
                              onChange={(e) => handleInputChange(e.target.value, 'question')}
                            />
                              {errors?.question?(<p style={{color:"red"}}>{errors?.question}</p>):''}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Answer<span style={{ color: "red" }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="answer"
                              name="answer"
                              onChange={(value) => handleInputChange(value, 'answer')}
                            />
                             {errors?.answer?(<p style={{color:"red"}}>{errors?.answer}</p>):''}
                          </div>
                          <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/orderfaqListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Addfaqpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
        </div>
      </div>
    </div>
  );
}
export default OrderFaqCreate;
