import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Link, useNavigate } from "react-router-dom";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import {
  addCategoryformData,
} from "../Controller/Collection";
import {getGenderDatafetch} from "../Controller/Product";
import AddCategoryModel from "./AddCollectionpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Collection.css";


function AddCollectionData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [gender, setGenderData] = useState([]);
  const [formData, setFormData] = useState({
    collection_name: "",
    variants: [
      {
        gender: "",
        name: "",
        slug:"",
         },
    ],
    // show_home_page: "",
    // collection_image: null, 
  });

  const handleInputChange = (value, field) => { 
    //  if (field === "show_home_page") {
    //   setFormData((prevVal) => ({
    //     ...prevVal,
    //     [field]: value ? "Yes" : "No",
    //   }));
    // }else 
    if (field === 'collection_name') {
      setFormData((prevVal) => ({
        ...prevVal,
        collection_name: value,
      }));
    } else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
  

  
  

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, collection_image: file }));
  };
  const fetchGender = async () => {
    const options = {};
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setGenderData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
const handleVariantChange = (value, index) => {
  const [gender_id, name, slug] = value.split("-");
  setFormData((prevFormData) => {
    const updatedVariants = [...prevFormData.variants];
    updatedVariants[index] = { gender_id, name, slug };
    return { ...prevFormData, variants: updatedVariants };
  });
};
  const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    if (!formData.collection_name) {
      setErrors((prevError) => ({
        ...prevError,
        collection_name: "Collection name is required.",
      }));
    } else {
      console.log(formData);
      const updateResult = await addCategoryformData(
        {
          ...formData,
          variants: formData.variants.map((variant) => ({
            gender_id: variant.gender_id,
            name: variant.name,
            slug: variant.slug,
          })),
        },
        adminInformation.token
      );
      if (updateResult.status === 200) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          collection_name: "",
        }));
        togglePopUp();
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
         setErrors((prevError) => ({
          ...prevError,
          collection_name:
            "Collection name already exists. Please choose a different name.",
        }));
      }
    }
  } catch (error) {
    console.error("Error updating profile:", error);
  }
};
  /*************************************************************
          End Function
   *************************************************************/
 
          const addVariant = () => {
            setFormData((prevVal) => ({
              ...prevVal,
              variants: [...prevVal.variants, { gender_id: "", name: "",slug: ""}],
            }));
          };
  
          const removeVariant = (index) => {
            setFormData((prevVal) => {
              const updatedVariants = [...prevVal.variants];
              updatedVariants.splice(index, 1);
              return { ...prevVal, variants: updatedVariants };
            });
          };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchGender();
  }, []);

  const { Dragger } = Upload;
  
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Collection </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="fullname">Collection Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="collection_name"
                          name="collection_name"
                          placeholder="Enter Collection Name"
                          value={formData.collection_name}  // Add this line
                          onChange={(e) => handleInputChange(e.target.value, 'collection_name')}  // Modify this line
                        />
                        {errors?.collection_name ? (
                          <p style={{ color: "red" }}>
                            {errors?.collection_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="exampleInputCheckbox">Home Page(show)</label>
                          <input
                            type="checkbox"
                            style={{ marginLeft: "14px", borderColor: "#df1616" }}
                            className="form-check-input"
                            id="show_home_page"
                            name="show_home_page"
                            checked={formData.show_home_page === "Yes"}
                            onChange={(e) => handleInputChange(e.target.checked, "show_home_page")}
                          />
                        </div>
                      
                        </div>

                          <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                                <input type="file" className="form-control"  id="collection_image" name="collection_image"
                                  onChange={handleFileChange1}
                            />
                            </div> */}
         <div className="row">
 {formData.variants.map((variant, index) => (
  <div key={index} className="col-md-2">
    <div className="form-group">
      <label htmlFor={`gender_${index}`}>Gender</label>
      <select
        className="form-control"
        id={`gender_${index}`}
        name={`gender_${index}`}
        value={`${variant.gender_id}-${variant.name}-${variant.slug}`}
        onChange={(e) => handleVariantChange(e.target.value, index)}
      >
        <option value="">Select Gender</option>
        {gender.map((genders) => (
          <option key={genders._id} value={`${genders._id}-${genders.name}-${genders.gender_slug}`}>
            {genders.name}
          </option>
        ))}
      </select>
      <br />
      <div>
        {index > 0 && (
          <button
            type="button"
            className="btn btn-danger remove-variant"
            onClick={() => removeVariant(index)}
          >
            Remove
          </button>
        )}
      </div>
    </div>
  </div>
))}

</div>

<div className="col-md-2">
  <button
    type="button"
    className="btn btn-primary"
    onClick={addVariant}
  >
    Add
  </button>
</div>
<br></br>
                      <button
                        type="submit"
                        className="btn btn-primary"
                       
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            
            </div>
            <AddCategoryModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCollectionData;
