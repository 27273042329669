import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload } from "antd";
import "../../AllCssFile.css";
import "react-quill/dist/quill.snow.css";

import { Link } from "react-router-dom";
import { updateProductnameformData, getProductnamewudata } from "../../Controller/Product";
import { useParams, useNavigate } from "react-router-dom";
import AddProductpopup from "./Editproductnamepopup";

function EditProductNameData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const { edit } = useParams();
  const [errors, setErrors] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };

  const [formData, setFormData] = useState({
    product_name: "",
   
  });
  const handleInputChange = (selectedValue, field, index) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: selectedValue,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [field]: "",
    }));
};
 const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      }else {
        setIsSubmitting(true); 
        const updateResult = await updateProductnameformData(formData, adminInformation.token);
        if (updateResult.data.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          // Handle error scenario
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

const getproductfetchdata = async () => {
  const options = { id: edit };
  try {
    const result = await getProductnamewudata(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData1 = result.data.response.result[0];
      setFormData({
        ...responseData1,
        id: responseData1._id,
      });
      
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};

useEffect(() => {
  window.scrollTo(0, 0);
  getproductfetchdata();
}, []);

return (
  <div>
    <div id="page-top">
      <div id="wrapper">
        <SideBar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div className="container-fluid">
              <div className="about_us_page_showing_page_heading">
                <h5>Edit Product Content </h5>
              </div>
              <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                <div className="card-body">
                  <div className="responsive">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">Product Name<span style={{ color: 'red' }}>*</span></label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section "
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                              onChange={(e) => handleInputChange(e.target.value, "product_name")}
                            />
                            {errors?.product_name ? <p style={{ color: "red" }}>{errors?.product_name}</p> : ""}
                          </div>
                        </div>

                      <div className="form-group row">
                        <div className="col-md-12">
                                            
<button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/listProductName">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="my-auto"> </div>
            </div>
          </footer>
        </div>
      </div>
      <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
    </div>
  </div>
);
}

export default EditProductNameData;
