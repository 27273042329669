import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { login, verifyLoginOtp } from "../Controller/Account";
import "../AllCssFile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");

  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Password visibility state
  const [showOtp, setShowOtp] = useState(false); // OTP visibility state
  /*************************************************************
   * Function Name  : handleChange
   * Purposs        : This function is used for store input value in states
   * Created Date   : 08-01-2024
   *************************************************************/
  const handleChange = (e) => {
    setFormData((preVal) => ({
      ...preVal,
      [e.target.name]: e.target.value,
    }));
    setErrors((...preError) => ({
      ...preError,
      [e.target.name]: "",
    }));
  }; //End of FUnction

  /*************************************************************
   * Function Name  : handleLogin
   * Purposs        : This function is used generate login OTP
   * Created Date   : 08-01-2024
   *************************************************************/
  const handleLogin = async () => {
    if (!formData?.email) {
      setErrors((...preError) => ({
        ...preError,
        email: "Email is required.",
      }));
    } else if (!formData.password) {
      setErrors((...preError) => ({
        ...preError,
        password: "Password is required.",
      }));
    } else {
      const options = { email: formData?.email, password: formData.password };
      const result = await login(options);
      if (result.status) {
        setIsOTPSent(true);
        setSuccess((preSuccess) => ({
          ...preSuccess,
          formSuccess: result.message,
        }));
        setCountdown(60);
        setIsCounting(true);
      } else {
        setIsOTPSent(false);
        setErrors((...preError) => ({
          ...preError,
          formError: result.message,
        }));
      }
    }
  }; //End of FUnction

  /*************************************************************
   * Function Name  : handleVerifyOTP
   * Purposs        : This function is used handle verify otp
   * Created Date   : 08-01-2024
   *************************************************************/
  const handleVerifyOTP = async () => {
    try {
      if (!formData?.email) {
        setErrors((...preError) => ({
          ...preError,
          email: "Email is required.",
        }));
      } else if (!formData.otp) {
        setErrors((...preError) => ({
          ...preError,
          otp: "One Time Password is required.",
        }));
      } else {
        const options = { email: formData?.email, otp: formData.otp };
        const result = await verifyLoginOtp(options);
        if (result.status === true) {
          navigate("/dashboard", { replace: true });
        } else {
          setErrors((...preError) => ({
            ...preError,
            otp: "Please enter correct Otp.",
          }));
        }
      }
    } catch (error) {
      setErrors((...preError) => ({
        ...preError,
        formError: "error",
      }));
    }
  }; //End of Function

  /*************************************************************
   * Function Name  : handleEmailChnage
   * Purposs        : This function is used for chnage email id
   * Created Date   : 08-01-2024
   *************************************************************/
  const handleEmailChnage = async () => {
    //Empty OTP Input Box
    setFormData((preVal) => ({
      ...preVal,
      password: "",
      otp: "",
    }));
    setIsOTPSent(false);
    setCountdown(0);
  }; //End of Function

  /*************************************************************
   * Purposs        : This hooks is used for handle otp sent countdown
   * Created Date   : 08-01-2024
   *************************************************************/
  useEffect(() => {
    let countdownInterval;
    if (isCounting) {
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown((prevCountdown) => prevCountdown - 1);
        } else {
          clearInterval(countdownInterval);
          setIsCounting(false);
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown, isCounting]);
/*************************************************************
   * Function Name  : toggleShowPassword
   * Purposs        : This function is used to toggle password visibility
   * Created Date   : 08-01-2024
   *************************************************************/
const toggleShowPassword = () => {
  setShowPassword(!showPassword);
};

/*************************************************************
 * Function Name  : toggleShowOtp
 * Purposs        : This function is used to toggle OTP visibility
 * Created Date   : 08-01-2024
 *************************************************************/
const toggleShowOtp = () => {
  setShowOtp(!showOtp);
};
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* <div className="row"> */}
                  {/* <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> */}
                  {/* <div className="col-lg-6 d-none d-lg-block  ">
                    <img
                      src="img/login_luar.jpg"
                      alt=""
                      className="bg-login-image"
                    ></img>
                  </div> */}
                 <div className="col-lg-6 d-none d-lg-block" style={{ marginLeft: '209px' }}>

                    {!isOTPSent && (
                      <>
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">Welcome </h1>
                          </div>
                          <form className="user">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control form-control-user common_category_input_for_all_section "
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                onChange={handleChange}
                                value={formData?.email}
                              />
                              {errors?.email ? (
                                <p style={{ color: "red" }}>{errors?.email}</p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="form-group position-relative">
                              <input
                                 type={showPassword ? "text" : "password"}
                                name="password"
                                className="form-control form-control-user common_category_input_for_all_section "
                                id="exampleInputPassword"
                                placeholder="Password"
                                onChange={handleChange}
                              />
                               <span
                                className="position-absolute"
                                style={{ right: "10px", top: "10px", cursor: "pointer" }}
                                onClick={toggleShowPassword}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEye : faEyeSlash}
                                />
                              </span>
                              {errors?.password ? (
                                <p style={{ color: "red" }}>
                                  {errors?.password}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            {errors?.formError ? (
                              <p style={{ color: "red" }}>
                                {errors?.formError}
                              </p>
                            ) : (
                              ""
                            )}
                            <NavLink
                              to={false}
                              onClick={handleLogin}
                              className="btn btn-primary btn-user btn-block" style={{ 
                                backgroundColor: "#e7aeb6",
                                color: "#fff",
                                borderColor: "#e7aeb6"
                              }}
                            >
                              {" "}
                              Login{" "}
                            </NavLink>
                          </form>
                          <hr />
                          {/* <div className="text-center">
                            <NavLink
                              className="small"
                              to="/forgot-password"
                              style={{ color: "#891621", fontWeight: "bold" }}
                            >
                              Forgot Password?
                            </NavLink>
                          </div> */}
                        </div>
                      </>
                    )}
                    {/* OTP SECTION */}
                    {isOTPSent && (
                      <>
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Welcome 
                            </h1>
                            {success?.formSuccess ? (
                              <p style={{ color: "green" }}>
                                {success?.formSuccess}{" "}
                                <NavLink to={false} onClick={handleEmailChnage}>
                                  Change
                                </NavLink>{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            {errors?.formError ? (
                              <p style={{ color: "red" }}>
                                {errors?.formError}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <form className="user">
                          <div className="form-group position-relative">
                              <input
                                type={showOtp ? "text" : "password"}
                                name="otp"
                                className="form-control form-control-user common_category_input_for_all_section "
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter One Time Password."
                                onChange={handleChange}
                              />
                               <span
                                className="position-absolute"
                                style={{ right: "10px", top: "10px", cursor: "pointer" }}
                                onClick={toggleShowOtp}
                              >
                                <FontAwesomeIcon
                                  icon={showOtp ? faEye : faEyeSlash}
                                />
                              </span>
                              {errors?.otp ? (
                                <p style={{ color: "red" }}>{errors?.otp}</p>
                              ) : (
                                ""
                              )}
                            </div>
                            <NavLink
                              to={false}
                              onClick={handleVerifyOTP}
                              className="btn btn-primary btn-user btn-block" style={{ 
                                backgroundColor: "#e7aeb6",
                                color: "#fff",
                                borderColor: "#e7aeb6"
                              }}
                            >
                              Submit
                            </NavLink>
                          </form>
                          <hr />
                          <div className="text-center">
                            {isCounting ? (
                              <p style={{ color: "red" }}>
                                {" "}
                                {" Time Left :"} {countdown}{" "}
                              </p>
                            ) : (
                              <NavLink
                                className="small"
                                to={false}
                                onClick={handleLogin}
                              >
                                {" "}
                                Resend OTP{" "}
                              </NavLink>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
