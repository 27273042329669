import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { updateCollectionformData, getCollectionData } from '../Controller/Collection';
import { getGenderDatafetch } from "../Controller/Product";
import AddCollectionpopup from "./AddCollectionpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Collection.css";
import { useNavigate, useParams } from "react-router-dom";

function EditCollectionData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [gender, setGenderData] = useState([]);
  const [errors, setErrors] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    collection_name: "",
    variants: [
      {
        gender_id: "",
        name: "",
        slug: "",
      },
    ],
  });

  const handleInputChange = (value, field) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: value,
    }));
  };

  const fetchGender = async () => {
    const options = {};
    try {
      const result = await getGenderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setGenderData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching gender data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.collection_name) {
        setErrors({ collection_name: 'Collection name is required.' });
      } else {
        const updateResult = await updateCollectionformData(formData, adminInformation.token);
        if (updateResult.status === true) {
          setFormData({
            collection_name: "",
            variants: [],
          });
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
          localStorage.removeItem('ADMIN-INFO');
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const getCollectionData1 = async () => {
    const options = { id: edit };
    try {
      const result = await getCollectionData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id;
        setFormData({
          ...modifiedData,
          collection_name: responseData.name,
          variants: responseData.variants.map(variant => ({
            gender_id: variant.gender_id,
            name: variant.gender_name,
            slug: variant.gender_slug
          })),
        });
        fetchGender();
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching collection data:", error);
    }
  };

  const handleVariantChange = (value, index) => {
    const [gender_id, name, slug] = value.split("-");
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[index] = { gender_id, name, slug };
      return { ...prevFormData, variants: updatedVariants };
    });
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [...prevVal.variants, { gender_id: "", name: "", slug: "" }],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCollectionData1();
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Collection </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="fullname">Collection Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="collection_name"
                          name="collection_name"
                          placeholder="Enter Collection Name"
                          value={formData.collection_name}
                          onChange={(e) => handleInputChange(e.target.value, 'collection_name')}
                        />
                        {errors.collection_name && (
                          <p style={{ color: "red" }}>{errors.collection_name}</p>
                        )}
                      </div>

                      <div className="row">
                        {formData.variants.map((variant, index) => (
                          <div key={index} className="col-md-2">
                            <div className="form-group">
                              <label htmlFor={`gender_${index}`}>Gender</label>
                              <select
                                className="form-control"
                                id={`gender_${index}`}
                                name={`gender_${index}`}
                                value={`${variant.gender_id}-${variant.name}-${variant.slug}`}
                                onChange={(e) => handleVariantChange(e.target.value, index)}
                              >
                                <option value="">Select Gender</option>
                                {gender.map((genders) => (
                                  <option
                                    key={genders._id}
                                    value={`${genders._id}-${genders.name}-${genders.gender_slug}`}
                                    selected={variant.gender_id === genders._id}
                                  >
                                    {genders.name}
                                  </option>
                                ))}
                              </select>
                              <br />
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="btn btn-danger remove-variant"
                                  onClick={() => removeVariant(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="col-md-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={addVariant}
                        >
                          Add
                        </button>
                      </div>
                      <br />
                      <button
                        type="submit"
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCollectionpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCollectionData;
