import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
  updateBannerformData,
  getBannerData,
  getbrandDatafetch,
} from "../../Controller/Banner";
import { imageURL } from "../../Config/constant";
import AddbannerModel from "./Editbannerpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { useLocation,Link } from "react-router-dom";
import "react-dates/initialize";
import { getCategoryDatafetch } from "../../Controller/Product";
import "./Banner.css";
import { useParams, useNavigate } from "react-router-dom";
import ImgCrop from "antd-img-crop";

function EditBannerData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [selectedItem, setSelectedItem] = useState({}); // Define setSelectedItem
  const location = useLocation();
  const [subCategories, setSubCategoryData] = useState([]);
  const { edit } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    page_name: "",
    button_title: "",
    button_url: "",
    title: "",
    banner_image: [],
    bottom_banner_image:[],
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleFileChangeForRow1 = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevFormData) => {
      // Create a new highlights array with the new images as File objects
      const newHighlights = [
        {
          images: files, // Directly store File objects
        },
      ];

      return {
        ...prevFormData,
        banner_image: newHighlights, // Replace the existing highlights with the new ones
      };
    });
  };
  const handleFileChangeForRow2 = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevFormData) => {
      // Create a new highlights array with the new images as File objects
      const newHighlights = [
        {
          images: files, // Directly store File objects
        },
      ];

      return {
        ...prevFormData,
        bottom_banner_image: newHighlights, // Replace the existing highlights with the new ones
      };
    });
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    if (field === "page_name") {
      setFormData((prevVal) => ({
        ...prevVal,
        page_name: value,
      }));
      handleCategorySelect(value);
    } else if (field === "button_title") {
      setFormData((prevVal) => ({
        ...prevVal,
        button_title: value,
      }));
    } else if (field === "title") {
      setFormData((prevVal) => ({
        ...prevVal,
        title: value,
      }));
    } else if (field === "button_url") {
      setFormData((prevVal) => ({
        ...prevVal,
        button_url: value,
      }));
    } else {
      const name = field;
      const inputValue = value; // Error occurs here
      // ...
    }
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: "Page name is required.",
        }));
      }
      //  else if (!formData.button_title) {
      //   setErrors((preError) => ({
      //     ...preError,
      //     button_title: 'Button title is required.',
      //   }));
      // } else if (!formData.button_url) {
      //   setErrors((preError) => ({
      //     ...preError,
      //     button_url: 'Button url is required.',
      //   }));
      // }
      else {
        setIsSubmitting(true);
        const updateResult = await updateBannerformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            page_name: "",
            button_title: "",
            button_url: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          //   localStorage.removeItem('TOKEN');
          // localStorage.removeItem('ADMIN-INFO');
          // navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
   * Function Name  : getbannerdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
 
  const fetchSubCategories = async (page_name) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      brand: page_name,
    };

    try {
      const result = await getbrandDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const handleCategorySelect = (page_name) => {
    fetchSubCategories(page_name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const locationState = location.state || {};

    if (locationState && locationState.selectedItem) {
      setSelectedItem(locationState.selectedItem);
      // Set formData state with selectedItem's values
      setFormData({
        ...formData,
        id: locationState.selectedItem._id,
        page_name: locationState.selectedItem.page_name,
        button_title: locationState.selectedItem.button_title,
        button_url: locationState.selectedItem.button_url,
        banner_image: locationState.selectedItem.banner_image,
        bottom_banner_image: locationState.selectedItem.bottom_banner_image,
        category_id: locationState.selectedItem.category_id,
        title: locationState.selectedItem.title,
      });
    }
    fetchCategories();
  }, [location.state]);
  /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Banner </h1>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-4">
                          <label htmlFor="fullname">
                            Page Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control common_category_input_for_all_section "
                            onChange={(e) => {
                              handleInputChange(e, "page_name"); // Pass event and field name
                              handleCategorySelect(e.target.value);
                            }}
                            id="page_name"
                            name="page_name"
                            value={formData.page_name} // Set value to match formData
                          >
                            <option value="">Select Page Name</option>
                            <option value="home">Home Page</option>
                            <option value="shop_page">Shop Page</option>
                            <option value="be_our_stocklists">Be Our Stockists Page</option>
                          </select>
                          {errors?.page_name ? (
                            <p style={{ color: "red" }}>{errors?.page_name}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        {formData.page_name === "shop_page" && (
                          <div className="col-md-4">
                            <label htmlFor="category_id">Category</label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={handleInputChange}
                              name="category_id"
                              id="category_id"
                              value={formData.category_id}
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            {errors.category_id && (
                              <p style={{ color: "red" }}>
                                {errors.category_id}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="col-md-4">
                          <label htmlFor="button_title">Title</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="title"
                            name="title"
                            onChange={(e) => handleInputChange(e, "title")}
                            value={formData.title}
                            placeholder="Enter  Title"
                          />
                          {errors.title && (
                            <p style={{ color: "red" }}>{errors.title}</p>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="button_title">Button Title</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="button_title"
                            name="button_title"
                            onChange={(e) =>
                              handleInputChange(e, "button_title")
                            }
                            value={formData.button_title}
                            placeholder="Enter Button Title"
                          />
                          {errors.button_title && (
                            <p style={{ color: "red" }}>
                              {errors.button_title}
                            </p>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="button_url">Button Url</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="button_url"
                            name="button_url"
                            onChange={(e) => handleInputChange(e, "button_url")}
                            value={formData.button_url}
                            placeholder="Enter Button URL"
                          />
                          {errors.button_url && (
                            <p style={{ color: "red" }}>{errors.button_url}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="banner_images">Top Banner Images </label>
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleFileChangeForRow1}
                        />
                        {formData.banner_image &&
                          formData.banner_image.map(
                            (banner_images, highlightIndex) => (
                              <div
                                key={highlightIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {banner_images.images ? (
                                  banner_images.images.map(
                                    (file, imageIndex) => (
                                      <div
                                        key={imageIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={`Banner Image ${imageIndex}`}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            margin: "5px",
                                          }}
                                        />
                                      </div>
                                    )
                                  )
                                ) : (
                                  <img
                                    src={`${imageURL}${banner_images}`}
                                    alt={`Image ${highlightIndex}`}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      margin: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            )
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="banner_images">Bottom Banner Images </label>
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleFileChangeForRow2}
                        />
                        {formData.bottom_banner_image &&
                          formData.bottom_banner_image.map(
                            (bottom_banner_image, highlightIndex) => (
                              <div
                                key={highlightIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {bottom_banner_image.images ? (
                                  bottom_banner_image.images.map(
                                    (file, imageIndex) => (
                                      <div
                                        key={imageIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={`Bottom Banner Image ${imageIndex}`}
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            margin: "5px",
                                          }}
                                        />
                                      </div>
                                    )
                                  )
                                ) : (
                                  <img
                                    src={`${imageURL}${bottom_banner_image}`}
                                    alt={`Image ${highlightIndex}`}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      margin: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            )
                          )}
                      </div>
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/bannerList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBannerData;
