import React, { useState,useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu  } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { updateCategoryformData,getCategoryData,getCategoryDatafetch} from '../Controller/Category';
import AddCategorypopup from "./EditCategorypopup";
// import { DateRangePicker } from "react-dates";
import { BsThreeDots } from "react-icons/bs";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Category.css";
import { Form } from "react-bootstrap";
import { Link,useNavigate ,useParams } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import CategoryDelete from "./CategoryDelete";
import ReactQuill from "react-quill";


function EditCategoryData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
    const [errors, setErrors] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      name: "",
     
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
    
    
    
  
    const handleInputChange = (value, field) => {
      if (field === 'category_name') {
        setFormData((prevVal) => ({
          ...prevVal,
          category_name: value,
        }));
      }else if (field === "header_show") {
        setFormData((prevVal) => ({
            ...prevVal,
            [field]: value === false ? "No" : "Yes", 
          }));
        }  else {
        const name = field;
        const inputValue = value;
  
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: inputValue,
        }));
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
      }
    };
  
  
   
   
    
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.category_name) {
        setErrors((preError) => ({
          ...preError,
          category_name: 'Category name is required.',
        }));
      }else {
        setIsSubmitting(true); 
        const updateResult = await updateCategoryformData(formData, adminInformation.token);
        if(updateResult.status === true){
          setFormData((prevFormData) => ({
              ...prevFormData,
              category_name: "",
              category_description: "",
            category_image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
      }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  

 /*************************************************************
 * Function Name  : getcategorydata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
 const getcategorydata = async () => {
  const options = { id: edit };
  try {
    const result = await getCategoryData(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      // Replace _id with id in the response data
      const modifiedData = { ...responseData, id: responseData._id };
      delete modifiedData._id; // Remove the _id property
      setFormData((prevFormData) => ({
        ...prevFormData,
        category_name: responseData.name, // Change here
        ...modifiedData,
      }));
    
    } else {
      localStorage.removeItem('TOKEN');
          localStorage.removeItem('ADMIN-INFO');
          navigate("/login");
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};


  useEffect(() => {
    window.scrollTo(0, 0);
    getcategorydata();
  }, []);
   /*************************************************************
          End Function
   *************************************************************/
          const navigate = useNavigate();
          const [itemIdToDelete, setItemIdToDelete] = useState(null);
          const [currentPage, setCurrentPage] = useState(1); // add this line
          const [totalItems, setTotalItems] = useState(0);
          const [searchValue, setSearchValue] = useState("");
        
          const [popUpdeOpen, setPopUpdeOpen] = useState(false);
          const [categoryList, setCategoryData] = useState([]);
          const togglePopUpde = (itemId) => {
            setPopUpdeOpen(!popUpdeOpen);
              setItemIdToDelete(itemId);
              getcategorydata();
          };
        /*************************************************************
         * Function Name  : getsocialdata
         * Purposs        : This function is used get data
         * Created Date   : 09-01-2024
         *************************************************************/
        const getcategoryfedata = async (page,name) => {
          const options = {
            page: page,
            name:name
          };
        
          try {
            const result = await getCategoryDatafetch(options, adminInformation.token);
        
            if (result.data && result.data.response) {
              const responseData = result.data.response.result.category;
              const totalcount = result.data.response.result.totalcount;
        
              setCategoryData(responseData);
               setTotalItems(totalcount);
            } else {
              localStorage.removeItem('TOKEN');
          localStorage.removeItem('ADMIN-INFO');
          navigate("/login");
              console.error("Unexpected response format:", result.data);
            }
          } catch (error) {
            
            console.error("Error fetching banner data:", error);
          }
        };
        const handleRowClick = (itemId) => {
         // history.push(`/createEventEditPage/${itemId}`);
          navigate(`/editCategoryData/${itemId}`);
        };
        const handlePageChange = (event, page) => {
          setCurrentPage(page);
          getcategoryfedata(page);
        };
        const handleSearch = (value) => {
          setSearchValue(value);
          getcategoryfedata(currentPage, value);
        };
          useEffect(() => {
            window.scrollTo(0, 0);
            getcategoryfedata(currentPage,searchValue);
          }, [currentPage,searchValue]);
          

  const dropdownMenu = (itemId) => (
    <Menu>
      <Menu.Item key="2" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUpde(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );
  const { Search } = Input;
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Category </h1>
                <div className="card shadow mb-4"  style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="fullname">Category Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section "
                          id="category_name"
                          name="category_name"
                          placeholder="Enter Category Name"
                          value={formData.category_name}
                          onChange={(e) => handleInputChange(e.target.value, 'category_name')}  // Modify this line
                        />
                        {errors?.category_name ? (
                          <p style={{ color: "red" }}>
                            {errors?.category_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="col-md-6">
                          <label htmlFor="exampleInputCheckbox">Header (Active/Inactive)</label>
                          <input
                              type="checkbox"
                              style={{ marginLeft: "14px", borderColor: "#df1616" }}
                              className="form-check-input"
                              id="header_show"
                              name="header_show"
                              checked={formData.header_show === "Yes"}
                              onChange={(e) => handleInputChange(e.target.checked, "header_show")}
                            />

                        </div> */}
                      {/* <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="category_description"
                            value={formData.category_description}
                            name="category_description"
                            onChange={(value) => handleInputChange(value, 'category_description')}
                          />
                           {errors?.category_description ? (
                          <p style={{ color: "red" }}>
                            {errors?.category_description}
                          </p>
                        ) : (
                          ""
                        )}
                          </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="category_image"
                          name="category_image"
                          onChange={handleFileChange1}
                        />
                        {formData.category_image && (
                          <p>Current File: {formData.category_image}</p>
                        )}
                      </div> */}

<button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/categoryList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
              
            </div>
            <AddCategorypopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
            <CategoryDelete
              popUpdeOpen={popUpdeOpen}
              togglePopUpde={togglePopUpde}
              itemIdToDelete={itemIdToDelete}
            ></CategoryDelete>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCategoryData;
