import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest} from './API';
/*********************************************************
 *  This function is use to getNewsletterDatafetch api
 *********************************************************/
export const getNewsletterDatafetch = async (options ,authToken)=>{
    try{
        const {page, email}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'waitlist/list-waitlist',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

 