import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./Component/DashBoardMain/DashBaord";
import Login from "./Component/LoginPage/Login";
import ConatctUsTable from "./Component/Contact Us/ConatctUsTable";
import Forgotpage from "./Component/ForgotPage/Forgotpage";
import ContatcUsAddressTable from "./Component/Contact Us/ContatcUsAddressTable";
import ContatcUsEnquiryTable from "./Component/Contact Us/ContactUsEnquiry";
import HomePage from "./Component/Home Page/HomePage";
import CreateCategories from "./Component/Blog Page/CreateCategories";

import EDITPROFILE from "./Component/LoginPage/EditProfile";

// Routes
import SOCIALICON from "./Component/GeneralData/SocialIcon/SocialIcon";
import SOCIALICONCREATEFORM from "./Component/GeneralData/SocialIcon/SocialIconFormCreate";
import SOCIALICONEDITFORM from "./Component/GeneralData/SocialIcon/SocialiconEdit";
import NewsletterList from "./Component/GeneralData/Newsletter/newsletterList";
import WaitList from "./Component/GeneralData/Waitlist/WaitlistList";
import GETINTOUCH from "./Component/GeneralData/GetInTouch/GetInTouch";
import GETINTOUCHEDITFORM from "./Component/GeneralData/GetInTouch/GetInTouchEdit";
import CREATEGETINTOUCH from "./Component/GeneralData/GetInTouch/GetInTouchFormCreate";

import BANNER from "./Component/Cms/Banner/ListBannerData";
import CREATEBANNER from "./Component/Cms/Banner/AddBannerData";
import EDITBANNERDATA from "./Component/Cms/Banner/EditBannerData";

import HEADINGListPage from "./Component/Cms/Heading/ListHeadingData";
import HEADINGCREATEPage from "./Component/Cms/Heading/AddHeadingData";
import HEADINGEditPage from "./Component/Cms/Heading/EditHeadingData";

import CATEGORY from "./Component/Category/ListCategoryData";
import CREATECATEGORY from "./Component/Category/AddCategoryData";
import EDITCATEGORY from "./Component/Category/EditCategoryData";
import SUBCATEGORY from "./Component/SubCategory/ListSubCategoryData";
import CREATESUBCATEGORY from "./Component/SubCategory/AddSubCategoryData";
import EDITSUBCATEGORY from "./Component/SubCategory/EditSubCategoryData";
import SIZE from "./Component/Size/ListSizeData";
import CREATESIZE from "./Component/Size/AddSizeData";
import EDITSIZE from "./Component/Size/EditSizeData";
import GENDER from "./Component/Gender/ListGenderData";
import CREATEGENDER from "./Component/Gender/AddGenderData";
import EDITGENDER from "./Component/Gender/EditGenderData";
import COLOUR from "./Component/Color/ListColorData";
import CREATECOLOUR from "./Component/Color/AddColorData";
import EDITCOLOUR from "./Component/Color/EditColorData";
import COLLECTION from "./Component/Collection/ListCategoryData";
import CREATECOLLECTION from "./Component/Collection/AddCollectionData";
import EDITCOLLECTION from "./Component/Collection/EditCollectionData";
import UserListPage from "./Component/User/ListUser";
import ORDERDETAIL from "./Component/Order/orderDetail";



import BlogCREATEPage from "./Component/Blog Page/BlogCreate";
import BLogListPage from "./Component/Blog Page/ListBlog";
import BlogEditPage from "./Component/Blog Page/EditBlogData";
import AboutCREATEPage from "./Component/About/AboutCreate";
import AboutListPage from "./Component/About/ListAbout";
import AboutEditPage from "./Component/About/EditAboutData";

import HomeCREATEPage from "./Component/Home/HomeCreate";
import HomeListPage from "./Component/Home/ListHome";
import HomeEditPage from "./Component/Home/EditHomeData";

import AboutProductCREATEPage from "./Component/Cms/Product-detail/AddProductdetailData";
import AboutProductListPage from "./Component/Cms/Product-detail/ListProductData";
import AboutProductEditPage from "./Component/Cms/Product-detail/EditProductData";

import RefundCREATEPage from "./Component/Refund/RefundCreate";
import RefundListPage from "./Component/Refund/ListRefund";
import RefundEditPage from "./Component/Refund/EditRefundData";
import PrivacyCREATEPage from "./Component/Privacy/PrivacyCreate";
import PrivacyListPage from "./Component/Privacy/ListPrivacy";
import PrivacyEditPage from "./Component/Privacy/EditPrivacyData";
import REVIEWLIST from "./Component/Review/ListReviewData";

import ContactCREATEPage from "./Component/Contact/ContactCreate";
import ContactListPage from "./Component/Contact/ListContact";
import ContactEditPage from "./Component/Contact/EditContactData";

import EmailCREATEPage from "./Component/Email/AddEmailData";
import EmailListPage from "./Component/Email/ListEmailData";
import EmailEditPage from "./Component/Email/EditEmailData";
import PRODUCTCREATEPage from "./Component/Product/ProductCreate";
import PRODUCTCREATENamePage from "./Component/Product/Product_Name/ProductNameCreate";
import PRODUCTNAMEEditPage from "./Component/Product/Product_Name/EditProductNameData";
import PRODUCTLISTNamePage from "./Component/Product/Product_Name/ListProductName";
import PRODUCTListPage from "./Component/Product/ListProduct";
import PRODUCTEditPage from "./Component/Product/EditProductData";
import TESTIMONIALCREATEPage from "./Component/Testmonial/TestimonialCreate";
import TESTIMONIALListPage from "./Component/Testmonial/ListTestimonial";
import TESTIMONIALEditPage from "./Component/Testmonial/EditTestimonalData";
import TermLISTPAGE from "./Component/Terms_Condition/ListTerm";
import TermCREATEPage from "./Component/Terms_Condition/TermCreate";
import TermEditPage from "./Component/Terms_Condition/EditTermData";
import PRODUCTBULKDATA from "./Component/Product/ProductBulkCreate";
import ORDERLIST from "./Component/Order/ListOrder";
import BULKORDERLIST from "./Component/Order/BulkOrder";
import COUPENCREATEPage from "./Component/Coupen/AddCoupenData";
import COUPENListPage from "./Component/Coupen/ListCoupenData";
import COUPENEditPage from "./Component/Coupen/EditCoupenData";
import TRICKERCREATEPage from "./Component/Tricker/AddTrickerData";
import TRICKERListPage from "./Component/Tricker/ListTrickerData";
import TRICKEREditPage from "./Component/Tricker/EditTrickerData";
import FeactureCREATEPage from "./Component/Feacture/AddFeactureData";
import FeactureListPage from "./Component/Feacture/ListFeactureData";
import FeactureEditPage from "./Component/Feacture/EditFeactureData";

import FAQISTPAGE from "./Component/Cms/faq/ListFaq";
import FAQREATEPage from "./Component/Cms/faq/FaqCreate";
import FAQEditPage from "./Component/Cms/faq/EditFaqData";

import SHIPPINGFAQISTPAGE from "./Component/Cms/shipping_faq/ListShippingFaq";
import SHIPPINGFAQREATEPage from "./Component/Cms/shipping_faq/ShippingFaqCreate";
import SHIPPINGFAQEditPage from "./Component/Cms/shipping_faq/EditShippingFaqData";

import ORDERFAQISTPAGE from "./Component/Cms/orderfaq/ListOrderFaq";
import ORDERFAQREATEPage from "./Component/Cms/orderfaq/OrderFaqCreate";
import ORDERFAQEditPage from "./Component/Cms/orderfaq/EditOrderFaqData";

import REVIEWDETAIL from "./Component/Review/reviewDetail"

import RETURNFAQISTPAGE from "./Component/Cms/returnfaq/ListReturnFaq";
import RETURNFAQREATEPage from "./Component/Cms/returnfaq/ReturnFaqCreate";
import RETURNFAQEditPage from "./Component/Cms/returnfaq/EditReturnFaqData";

import GIFTFAQISTPAGE from "./Component/Cms/giftfaq/ListGiftFaq";
import GIFTFAQREATEPage from "./Component/Cms/giftfaq/GiftFaqCreate";
import GIFTFAQEditPage from "./Component/Cms/giftfaq/EditGiftFaqData";

import OTHERFAQISTPAGE from "./Component/Cms/otherfaq/ListOtherFaq";
import OTHERFAQREATEPage from "./Component/Cms/otherfaq/OtherFaqCreate";
import OTHERFAQEditPage from "./Component/Cms/otherfaq/EditOtherFaqData";
import StockWithList from "./Component/GeneralData/Stock/StockList"


import DISCOUNTCREATEPage from "./Component/Discount/AddDiscountData";
import DISCOUNTListPage from "./Component/Discount/ListDiscountData";
import DISCOUNTEditPage from "./Component/Discount/EditDiscountData";


const AuthenticatedRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("ADMIN-INFO") !== "";
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("ADMIN-INFO") !== "";
  });

  const navigate = useNavigate();

  useEffect(() => {
    const adminInfo = localStorage.getItem("ADMIN-INFO");
    const currentPath = window.location.pathname;

    if (adminInfo !== "") {
      setIsAuthenticated(true);
    } else if (currentPath !== "/login" && currentPath !== "/forgot-password") {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          {/*}  <Route path="/dashboard" element={<Dashboard />} />*/}
          <Route
            path="/dashboard"
            element={<AuthenticatedRoute element={<Dashboard />} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/contactUsTable" element={<ConatctUsTable />} />

          {/*main route*/}
          {/*General Data */}
          <Route path="/socialIconList" element={<SOCIALICON />} />
          <Route
            path="/socialIconCreateForm"
            element={<SOCIALICONCREATEFORM />}
          />
          <Route
            path="/socialiconEdit/:edit"
            element={<SOCIALICONEDITFORM />}
          />
          <Route path="/newsletterList" element={<NewsletterList />} />
          <Route path="/waitList" element={<WaitList />} />
          <Route path="/stockwithList" element={<StockWithList />} />
          <Route path="/getInTouchList" element={<GETINTOUCH />} />
          <Route path="/createGetInTouch" element={<CREATEGETINTOUCH />} />
          <Route
            path="/GetInTouchEdit/:edit"
            element={<GETINTOUCHEDITFORM />}
          />
             <Route path="/forgot-password" element={<Forgotpage />} />
          {/*Cms Data */}
          <Route path="/bannerList" element={<BANNER />} />
          <Route path="/createBanner" element={<CREATEBANNER />} />
          <Route path="/editBannerData" element={<EDITBANNERDATA />} />
          {/*Cms Data */}
          <Route path="/categoryList" element={<CATEGORY />} />
          <Route path="/createCategory" element={<CREATECATEGORY />} />
          <Route path="/editCategoryData/:edit" element={<EDITCATEGORY />} />
          <Route path="/subcategoryList" element={<SUBCATEGORY />} />
          <Route path="/createSubCategory" element={<CREATESUBCATEGORY />} />
          <Route path="/editSubCategoryData/:edit" element={<EDITSUBCATEGORY />} />
          <Route path="/sizeList" element={<SIZE />} />
          <Route path="/createSize" element={<CREATESIZE />} />
          <Route path="/editSizeData/:edit" element={<EDITSIZE />} />
          <Route path="/genderList" element={<GENDER />} />
          <Route path="/createGender" element={<CREATEGENDER />} />
          <Route path="/editGenderData/:edit" element={<EDITGENDER />} />
          <Route path="/colourList" element={<COLOUR />} />
          <Route path="/createColour" element={<CREATECOLOUR />} />
          <Route path="/editColourData/:edit" element={<EDITCOLOUR />} />
          <Route path="/collectionList" element={<COLLECTION />} />

          <Route path="/reviewList" element={<REVIEWLIST />} />

          <Route path="/createCollection" element={<CREATECOLLECTION />} />
          <Route path="/editCollectionData/:edit" element={<EDITCOLLECTION />} />

          <Route path="/bLogListPage" element={<BLogListPage />} />
          <Route path="/createblogdata" element={<BlogCREATEPage />} />
          <Route path="/editBlogData" element={<BlogEditPage />} />

          <Route path="/aboutListPage" element={<AboutListPage />} />
          <Route path="/createAboutdata" element={<AboutCREATEPage />} />
          <Route path="/editAboutData" element={<AboutEditPage />} />

          <Route path="/homeListPage" element={<HomeListPage />} />
          <Route path="/createHomedata" element={<HomeCREATEPage />} />
          <Route path="/editHomeData" element={<HomeEditPage />} />
          <Route path="/aboutProductListPage" element={<AboutProductListPage />} />
          <Route path="/createAboutProductdata" element={<AboutProductCREATEPage />} />
          <Route path="/editAboutProductData" element={<AboutProductEditPage />} />

          <Route path="/refundListPage" element={<RefundListPage />} />
          <Route path="/createRefunddata" element={<RefundCREATEPage />} />
          <Route path="/editRefundData" element={<RefundEditPage />} />
          <Route path="/privacyListPage" element={<PrivacyListPage />} />
          <Route path="/createPrivacydata" element={<PrivacyCREATEPage />} />
          <Route path="/editPrivacyData" element={<PrivacyEditPage />} />

          <Route path="/contactListPage" element={<ContactListPage />} />
          <Route path="/createContactdata" element={<ContactCREATEPage />} />
          <Route path="/editContactData" element={<ContactEditPage />} />
          {/* <Route path="/editBlogData/:edit" element={<BlogEditPage />} /> */}
          <Route path="/emailListPage" element={<EmailListPage />} />
          <Route path="/createEmaildata" element={<EmailCREATEPage />} />
          <Route path="/editEmailData/:edit" element={<EmailEditPage />} />

          <Route path="/userList" element={<UserListPage />} />
          <Route path="/productListPage" element={<PRODUCTListPage />} />
          <Route path="/productBulkData" element={<PRODUCTBULKDATA />} />
          <Route path="/createProductData" element={<PRODUCTCREATEPage />} />
          <Route path="/createProductName" element={<PRODUCTCREATENamePage />} />
          <Route path="/editProductNameData/:edit" element={<PRODUCTNAMEEditPage />} />
          <Route path="/listProductName" element={<PRODUCTLISTNamePage />} />
          <Route path="/editProductData/:edit" element={<PRODUCTEditPage />} />
          <Route path="/orderDetail" element={<ORDERDETAIL />} />
          <Route path="/reviewDetail" element={<REVIEWDETAIL />} />
         
          <Route path="/discountListPage" element={<DISCOUNTListPage />} />
          <Route path="/createDiscountData" element={<DISCOUNTCREATEPage />} />
          <Route path="/editDiscountData/:edit" element={<DISCOUNTEditPage />} />

          <Route path="/headingListPage" element={<HEADINGListPage />} />
          <Route path="/createHeadingData" element={<HEADINGCREATEPage />} />
          <Route path="/editHeadingData/:edit" element={<HEADINGEditPage />} />
          <Route path="/trickerListPage" element={<TRICKERListPage />} />
          <Route path="/createTrickerData" element={<TRICKERCREATEPage />} />
          <Route path="/editTrickerData/:edit" element={<TRICKEREditPage />} />
         
          <Route path="/feactureListPage" element={<FeactureListPage />} />
          <Route path="/createFeactureData" element={<FeactureCREATEPage />} />
          <Route path="/editFeactureData/:edit" element={<FeactureEditPage />} />
         
          
          <Route
            path="/testimonialListPage"
            element={<TESTIMONIALListPage />}
          />
          <Route
            path="/createTestimonialData"
            element={<TESTIMONIALCREATEPage />}
          />
          <Route
            path="/editTestimonialData/:edit"
            element={<TESTIMONIALEditPage />}
          />
          <Route path="/orderList" element={<ORDERLIST />} />
          <Route path="/bulkOrderList" element={<BULKORDERLIST />} />
          <Route path="/coupenListPage" element={<COUPENListPage />} />
          <Route path="/createCoupenData" element={<COUPENCREATEPage />} />
          <Route path="/editCoupenData/:edit" element={<COUPENEditPage />} />
          <Route path="/termConditionListPage" element={<TermLISTPAGE />} />
          <Route path="/createTermConditionData" element={<TermCREATEPage />} />
          <Route path="/editTermConditionData/:edit" element={<TermEditPage />} />

          <Route path="/faqListPage" element={<FAQISTPAGE />} />
          <Route path="/createFaqData" element={<FAQREATEPage />} />
          <Route path="/editFaqData/:edit" element={<FAQEditPage />} />

          <Route path="/shippingfaqListPage" element={<SHIPPINGFAQISTPAGE />} />
          <Route path="/createShippingFaqData" element={<SHIPPINGFAQREATEPage />} />
          <Route path="/editShippingFaqData/:edit" element={<SHIPPINGFAQEditPage />} />
          <Route path="/orderfaqListPage" element={<ORDERFAQISTPAGE />} />
          <Route path="/createOrderFaqData" element={<ORDERFAQREATEPage />} />
          <Route path="/editOrderFaqData/:edit" element={<ORDERFAQEditPage />} />
          <Route path="/returnfaqListPage" element={<RETURNFAQISTPAGE />} />
          <Route path="/createReturnFaqData" element={<RETURNFAQREATEPage />} />
          <Route path="/editReturnFaqData/:edit" element={<RETURNFAQEditPage />} />
          <Route path="/giftfaqListPage" element={<GIFTFAQISTPAGE />} />
          <Route path="/createGiftFaqData" element={<GIFTFAQREATEPage />} />
          <Route path="/editGiftFaqData/:edit" element={<GIFTFAQEditPage />} />
          <Route path="/otherfaqListPage" element={<OTHERFAQISTPAGE />} />
          <Route path="/createOtherFaqData" element={<OTHERFAQREATEPage />} />
          <Route path="/editOtherFaqData/:edit" element={<OTHERFAQEditPage />} />



          {/*main route*/}

          <Route
            path="/contactUsAddresstable"
            element={<ContatcUsAddressTable />}
          />
          <Route
            path="/contactUsEnquiryTable"
            element={<ContatcUsEnquiryTable />}
          />


          <Route path="/homePage" element={<HomePage />} />
          <Route path="/createCategories" element={<CreateCategories />} />

          <Route path="/editProfile" element={<EDITPROFILE />} />
         
        </Routes>
      </div>
    </>
  );
};
export default App;
