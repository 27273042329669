import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import {
  updateCoupenformData,
  getCoupenData,
} from "../Controller/Tricker";
import AddCoupenpopup from "./EditTrickerpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Tricker.css";
import { useParams,useNavigate,Link } from "react-router-dom";

function EditTrickerData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    tricker: "",
    
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.tricker) {
        setErrors((preError) => ({
          ...preError,
          tricker: "Ticker is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateCoupenformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            tricker: "",
          
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
          localStorage.removeItem('ADMIN-INFO');
          navigate("/login");
          setErrors((preError) => ({
            ...preError,
            tricker:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
   * Function Name  : getCoupenData
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getCoupenfetchData = async () => {
    const options = { id: edit };
    try {
      const result = await getCoupenData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        // Replace _id with id in the response data
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id; // Remove the _id property
        setFormData(modifiedData);
      } else {
       localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCoupenfetchData();
  }, []);
  /*************************************************************
          End Function
   *************************************************************/
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Search } = Input;
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Ticker </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor="tricker">Ticker<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="tricker"
                            name="tricker"
                            placeholder="Enter Ticker"
                            value={formData.tricker}
                            onChange={handleInputChange}
                          />
                          {errors?.tricker ? (
                            <p style={{ color: "red" }}>
                              {errors?.tricker}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                          </div>
                          <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/trickerListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>

             
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTrickerData;
