import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { updateCoupenformData, getCoupenData } from "../Controller/Coupen";
import {
  getCategoryDatafetch,
  getSubCategoryDatafetch,
} from "../Controller/Product";
import AddCoupenpopup from "./EditCoupenpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Coupen.css";
import { useParams, useNavigate, Link } from "react-router-dom";

function EditCoupenData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [subCategories, setSubCategoryData] = useState([]);
  const [categories, setCategoryData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    coupon_code: "",
    discount: "",
    flat_discount: "",
    start_date: "",
    start_time: "",
    description: "",
    end_date: "",
    end_time: "",
    quantity: "",
    eligible_amount: "",
    max_amount: "",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.coupon_code) {
        setErrors((preError) => ({
          ...preError,
          coupon_code: "Coupon code is required.",
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: "Description is required.",
        }));
      } else if (!formData.start_date) {
        setErrors((preError) => ({
          ...preError,
          start_date: "Start date is required.",
        }));
      } else if (!formData.start_time) {
        setErrors((preError) => ({
          ...preError,
          start_time: "Start time is required.",
        }));
      } else if (!formData.end_date) {
        setErrors((preError) => ({
          ...preError,
          end_date: "End Date is required.",
        }));
      } else if (!formData.end_time) {
        setErrors((preError) => ({
          ...preError,
          end_time: "End time is required.",
        }));
      } else if (!formData.quantity) {
        setErrors((preError) => ({
          ...preError,
          quantity: "Quantity is required.",
        }));
      } else if (!formData.eligible_amount) {
        setErrors((preError) => ({
          ...preError,
          eligible_amount: "Eligible amount is required.",
        }));
      } else if (!formData.max_amount) {
        setErrors((preError) => ({
          ...preError,
          max_amount: "Max amount is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateCoupenformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            coupon_code: "",
            discount: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
          setErrors((preError) => ({
            ...preError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
   * Function Name  : getCoupenData
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getCoupenfetchData = async () => {
    const options = { id: edit };
    try {
      const result = await getCoupenData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        // Replace _id with id in the response data
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id; // Remove the _id property
        setFormData(modifiedData);
        fetchSubCategories(responseData.category_id);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const fetchSubCategories = async (categoryId) => {
    const options = {
      // Add any additional options needed for fetching sub-categories
      category_id: categoryId,
    };

    try {
      const result = await getSubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };
  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleSelectClick = () => {
    fetchCategories();
  };
  const handleCategorySelect = (categoryId) => {
    fetchSubCategories(categoryId);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getCoupenfetchData();
  }, []);
  /*************************************************************
          End Function
   *************************************************************/

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCategories();
  }, []);

  const { Search } = Input;
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Coupon </h1>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        {/* <div className="col-md-6">
                        <label htmlFor="exampleInputText">Category</label>
                        <select
  className="form-control"
  onChange={(e) => {
    handleInputChange(e,"category_id");
    handleCategorySelect(e.target.value.split("|")[0], e.target.value.split("|")[1]);
  }}
  onClick={handleSelectClick}
>

                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category._id} value={category._id}  selected={category._id === formData.category_id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                        {errors?.category_id ? <p style={{ color: "red" }}>{errors?.category_id}</p> : ""}
                      </div>

                              <div className="col-md-6">
                                <label htmlFor="exampleInputText">Sub Category</label>
                                <select
                                    className="form-control"
                                    onChange={(e) => handleInputChange(e, "subcategory_id")}
                                    value={formData.subcategory_id}
                                  >
                                  <option value="">Select Sub Category</option>
                                  {subCategories.map((subCategory) => (
                                    <option key={subCategory._id} value={subCategory._id} selected={subCategory._id === formData.subcategory_id}>
                                      {subCategory.name}
                                    </option>
                                  ))}
                                </select>
                                {errors?.subcategory_id ? <p style={{ color: "red" }}>{errors?.subcategory_id}</p> : ""}
                              </div> */}
                        <div className="col-md-6">
                          <label htmlFor="coupon_code" >
                            Coupon Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="coupon_code"
                            name="coupon_code"
                            placeholder="Enter Coupen Code"
                            value={formData.coupon_code}
                            onChange={handleInputChange}
                          />
                          {errors?.coupon_code ? (
                            <p style={{ color: "red" }}>
                              {errors?.coupon_code}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="description"
                            value={formData.description}
                            name="description"
                            placeholder="Enter Description"
                            onChange={(e) =>
                              handleInputChange(e, "description")
                            }
                          />
                          {errors?.description ? (
                            <p style={{ color: "red" }}>
                              {errors?.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="discount" className="all_top_and_bottom_for_input_text">Discount</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="discount"
                            name="discount"
                            value={formData.discount}
                            placeholder="Enter Discount"
                            onChange={handleInputChange}
                          />
                          {errors?.discount ? (
                            <p style={{ color: "red" }}>{errors?.discount}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="discount" className="all_top_and_bottom_for_input_text">Flat Discount</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="flat_discount"
                            name="flat_discount"
                            value={formData.flat_discount}
                            placeholder="Enter Flat Discount"
                            onChange={handleInputChange}
                          />
                          {errors?.flat_discount ? (
                            <p style={{ color: "red" }}>
                              {errors?.flat_discount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="start_date" className="all_top_and_bottom_for_input_text">
                            Start Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control common_category_input_for_all_section"
                            id="start_date"
                            name="start_date"
                            placeholder="Enter Sart Date"
                            value={formData.start_date}
                            min={new Date().toISOString().split("T")[0]} // Set the min attribute to today's date
                            onChange={(e) => handleInputChange(e, "start_date")}
                          />
                          {errors?.start_date ? (
                            <p style={{ color: "red" }}>{errors?.start_date}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="start_time" className="all_top_and_bottom_for_input_text">
                            Start Time<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control common_category_input_for_all_section"
                            id="start_time"
                            name="start_time"
                            placeholder="Enter Start Time"
                            value={formData.start_time}
                            onChange={(e) => handleInputChange(e, "start_time")}
                          />
                          {errors?.start_time ? (
                            <p style={{ color: "red" }}>{errors?.start_time}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="end_date" className="all_top_and_bottom_for_input_text">
                            End Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control common_category_input_for_all_section"
                            id="end_date"
                            name="end_date"
                            placeholder="Enter End Date"
                            value={formData.end_date}
                            onChange={(e) => handleInputChange(e, "end_date")}
                          />
                          {errors?.end_date ? (
                            <p style={{ color: "red" }}>{errors?.end_date}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="end_time" className="all_top_and_bottom_for_input_text">
                            End Time<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control common_category_input_for_all_section"
                            id="end_time"
                            name="end_time"
                            value={formData.end_time}
                            placeholder="Enter End Time"
                            onChange={(e) => handleInputChange(e, "end_time")}
                          />
                          {errors?.end_time ? (
                            <p style={{ color: "red" }}>{errors?.end_time}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="quantity" className="all_top_and_bottom_for_input_text">
                            Quantity<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control common_category_input_for_all_section"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter Quantity"
                            value={formData.quantity}
                            onChange={(e) => handleInputChange(e, "quantity")}
                          />
                          {errors?.quantity ? (
                            <p style={{ color: "red" }}>{errors?.quantity}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code" className="all_top_and_bottom_for_input_text">
                            Eligible Amount
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control common_category_input_for_all_section"
                            id="eligible_amount"
                            name="eligible_amount"
                            value={formData.eligible_amount}
                            placeholder="Enter Eligible Amount"
                            onChange={(e) =>
                              handleInputChange(e, "eligible_amount")
                            }
                          />
                          {errors?.eligible_amount ? (
                            <p style={{ color: "red" }}>
                              {errors?.eligible_amount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code" className="all_top_and_bottom_for_input_text">
                            Maximum Amount
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control common_category_input_for_all_section"
                            id="max_amount"
                            name="max_amount"
                            value={formData.max_amount}
                            placeholder="Enter Maximum Amount"
                            onChange={(e) => handleInputChange(e, "max_amount")}
                          />
                          {errors?.max_amount ? (
                            <p style={{ color: "red" }}>{errors?.max_amount}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        style={{
                          backgroundColor: "#e7aeb6",
                          color: "#fff",
                          borderColor: "#e7aeb6",
                        }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Update"
                        )}
                      </button>
                      <Link to="/coupenListPage">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#e7aeb6",
                            color: "#fff",
                            borderColor: "#e7aeb6",
                            marginLeft: "12px",
                          }}
                        >
                          <span>Cancel</span>
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCoupenData;
