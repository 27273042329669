import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import "./EditForm.css";
import { Link, useNavigate } from "react-router-dom";
import { getProfile, updateProfile } from '../Controller/Account';
import Editprofilepopmodel from "./Editprofilepopmodel";

function EditProfile() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const [popUpOpen, setPopUpOpen] = useState(false);
  const navigate = useNavigate();

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    admin_first_name: "",
    admin_middle_name: "",
    admin_last_name: "",
    admin_email: "",
    admin_phone: "",
    admin_address: "",
    admin_city: "",
    admin_state: "",
    admin_country: "",
    admin_pincode: "",
  });

  const getprofiledata = async () => {
    const options = {}; // Add any necessary options for API call
    try {
        const result = await getProfile(options, adminInformation.token);
        if (result.data && result.data.response) {
            const responseData = result.data.response.userData;
            setFormData({
                ...formData,
                admin_first_name: responseData.admin_first_name,
                admin_middle_name: responseData.admin_middle_name,
                admin_last_name: responseData.admin_last_name,
                admin_email: responseData.admin_email,
                admin_phone: responseData.admin_phone.toString(), // Ensure admin_phone is set as string
                admin_address: responseData.admin_address,
                admin_city: responseData.admin_city,
                admin_state: responseData.admin_state,
                admin_country: responseData.admin_country,
                admin_pincode: responseData.admin_pincode,
            });
        } else {
            console.error("Unexpected response format:", result.data);
        }
    } catch (error) {
        console.error("Error fetching profile data:", error);
    }
};

  useEffect(() => {
    window.scrollTo(0, 0);
    getprofiledata();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'admin_phone' ? value.toString() : value, // Ensure admin_phone is always a string
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error message for this field on change
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const phoneNumber = formData.admin_phone;
        
        if (!formData.admin_first_name) {
            setErrors((preError) => ({
                ...preError,
                admin_first_name: 'First name is required.',
            }));
        } else if (!formData.admin_last_name) {
            setErrors((preError) => ({
                ...preError,
                admin_last_name: 'Last name is required.',
            }));
        } else if (!formData.admin_email) {
            setErrors((preError) => ({
                ...preError,
                admin_email: 'Email is required.',
            }));
        } else if (!formData.admin_phone || formData.admin_phone.length !== 10) {
            setErrors((preError) => ({
                ...preError,
                admin_phone: 'Phone No must be 10 digits long.',
            }));
        } else if (!formData.admin_address) {
            setErrors((preError) => ({
                ...preError,
                admin_address: 'Address is required.',
            }));
        } else if (!formData.admin_city) {
            setErrors((preError) => ({
                ...preError,
                admin_city: 'City is required.',
            }));
        } else if (!formData.admin_state) {
            setErrors((preError) => ({
                ...preError,
                admin_state: 'State is required.',
            }));
        } else if (!formData.admin_country) {
            setErrors((preError) => ({
                ...preError,
                admin_country: 'Country is required.',
            }));
        } else if (!formData.admin_pincode) {
            setErrors((preError) => ({
                ...preError,
                admin_pincode: 'Pincode is required.',
            }));
        } else {
            const updateResult = await updateProfile(formData, adminInformation.token);

            if (updateResult.status === 200) {
                const updatedAdminInformation = {
                    ...adminInformation,
                    admin_first_name: updateResult.data.response.result.admin_first_name,
                    admin_middle_name: updateResult.data.response.result.admin_middle_name,
                    admin_last_name: updateResult.data.response.result.admin_last_name,
                    admin_email: updateResult.data.response.result.admin_email,
                    admin_phone: updateResult.data.response.result.admin_phone,
                    admin_address: updateResult.data.response.result.admin_address,
                    admin_city: updateResult.data.response.result.admin_city,
                    admin_state: updateResult.data.response.result.admin_state,
                    admin_country: updateResult.data.response.result.admin_country,
                    admin_pincode: updateResult.data.response.result.admin_pincode,
                };

                localStorage.setItem('ADMIN-INFO', JSON.stringify(updatedAdminInformation));
                togglePopUp();
                // navigate("/dashboard");
            }
        }
    } catch (error) {
        console.error("Error updating profile:", error);
    }
  };

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Profile</h1>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9",}}>
                  <div className="card-body">
                  <form onSubmit={handleSubmit}>
                      <div className="form-group  row">
                      <div className="col-md-3">
                        <label htmlFor="fullname">First Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_first_name"
                          name="admin_first_name"
                          value={formData.admin_first_name}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_first_name?(<p style={{color:"red"}}>{errors?.admin_first_name}</p>):''}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="fullname">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_middle_name"
                          name="admin_middle_name"
                          value={formData.admin_middle_name}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_middle_name?(<p style={{color:"red"}}>{errors?.admin_middle_name}</p>):''}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="fullname">Last Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_last_name"
                          name="admin_last_name"
                          value={formData.admin_last_name}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_last_name?(<p style={{color:"red"}}>{errors?.admin_last_name}</p>):''}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="fullname">Email<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_email"
                          name="admin_email"
                          value={formData.admin_email}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_email?(<p style={{color:"red"}}>{errors?.admin_email}</p>):''}
                      </div>
                      </div>
                      <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="fullname">Phone No<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="number"
                          className="form-control"
                          id="admin_phone"
                          name="admin_phone"
                          value={formData.admin_phone}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_phone?(<p style={{color:"red"}}>{errors?.admin_phone}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Address<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_address"
                          address="admin_address"
                          value={formData.admin_address}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_address?(<p style={{color:"red"}}>{errors?.admin_address}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">City<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_city"
                          name="admin_city"
                          value={formData.admin_city}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_city?(<p style={{color:"red"}}>{errors?.admin_city}</p>):''}
                      </div>
                      </div>
                      <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="fullname">State<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_state"
                          name="admin_state"
                          value={formData.admin_state}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_state?(<p style={{color:"red"}}>{errors?.admin_state}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Country<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_country"
                          name="admin_country"
                          value={formData.admin_country}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_country?(<p style={{color:"red"}}>{errors?.admin_country}</p>):''}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="fullname">Pincode<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="admin_pincode"
                          name="admin_pincode"
                          value={formData.admin_pincode}
                          onChange={handleInputChange}
                        />
                        {errors?.admin_pincode?(<p style={{color:"red"}}>{errors?.admin_pincode}</p>):''}
                      </div>
                      </div>
                      <button type="submit" className="btn btn-primary"  style={{ 
                          backgroundColor: "#e7aeb6",
                          color: "#fff",
                          borderColor: "#e7aeb6"
                        }}>
                        Submit
                      </button>
                      <Link to="/dashboard">
                      <button type="submit" className="btn btn-primary"  style={{ 
                          backgroundColor: "#e7aeb6",
                          color: "#fff",
                          borderColor: "#e7aeb6",marginLeft: 15
                        }}>
                        Cancel
                      </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Editprofilepopmodel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
