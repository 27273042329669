import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import OrderPopUp from "./OrderPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import { imageURL } from '../Config/constant';
import Pagination from "@mui/material/Pagination";
import { getOrderDatafetch,getOrderShippingstatus,getOrderDataExcel } from "../Controller/Order";
import { BsThreeDots } from "react-icons/bs";
import OrderShippingPopUp from "./OrderShippingPopUp";
import Excel from "exceljs";


function ListOrder() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [popUpOpenshipping, setPopUpOpenshipping] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [itemIdTocancelstatus, setItemIdcancelstatus] = useState(null);
  const [orderList, setOrderData] = useState([]);
  const [itemIdPassShipping, setItemIdShipping] = useState(null);
  const togglePopUp = (itemId, status) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(status);
    getOrderdata();
  };

  const togglePopUpstatus = (itemId, status) => {
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    setItemIdcancelstatus(status);
    getOrderdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getOrderdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getOrderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.orders;
        const totalcount = result.data.response.result.totalCount;
        setOrderData(responseData);
        setTotalItems(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        // console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getOrderdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getOrderdata(currentPage, value);
  };

  function formatStatus(status) {
    if (typeof status === 'string' && status.length > 0) {
      return status
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return status;
  }

  const getOrderDataship = async (order_id) => {
    try {
      const response = await axios({
        method: 'post',
        url: 'https://api.luarbeauty.in/admin/order/api/orders/show',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            id: order_id,
            auth: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ3OTMzOTgsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzIyNzU3MTgxLCJqdGkiOiJ2aTBaMTl1Q09CbW0yVlNVIiwiaWF0IjoxNzIxODkzMTgxLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcyMTg5MzE4MSwiY2lkIjo0NDY0NjI3LCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.72EduExE0M_yoMzHZBHVtWemNYK9nJm1wbIDv6tBQb8"
        },
    });
    
      if(response.data){
          let statusToSend = response.data.shipments.status;
          // Check if the status is "Pending"
          if (statusToSend === "PENDING") {
            statusToSend = "Processing";
          }
    
      const options = {
        shipment_order_id: order_id,
        status: formatStatus(statusToSend)
      };
      try {
        const resultstatus = await getOrderShippingstatus(options, adminInformation.token);
      } catch (error) {
        console.error("Error creating order:", error);
      }
    }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  //  useEffect(() => {
  //   const ordersWithShipmentId = orderList.filter(order => order.shipment_order_id);
  //   ordersWithShipmentId.forEach(order => {
  //     getOrderDataship(order.shipment_order_id);
  //   });
  // }, [orderList]);



  const downloadExcel = async () => {
    try {
      const result = await getOrderDataExcel({}, adminInformation.token);
      console.log("result", result);
  
      if (result.status === true || result.status === 200) {
        const orders = result.data.response.editData; // Extract orders from result.data
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Orders");
  
        // Define column headers
        worksheet.columns = [
          { header: "Order ID", key: "order_id", width: 15 },
          { header: "First Name", key: "firstName", width: 15 },
          { header: "Last Name", key: "lastName", width: 15 },
          { header: "Email", key: "email", width: 25 },
          { header: "Phone", key: "phone", width: 15 },
          { header: "Address", key: "address", width: 25 },
          { header: "Product Name", key: "product_name", width: 50 },
          { header: "Quantity", key: "qty", width: 10 },
          { header: "Price", key: "price", width: 15 },
          { header: "Payment Mode", key: "payment_mode", width: 15 },
          { header: "Payment Status", key: "payment_status", width: 15 },
          { header: "Discount", key: "discount", width: 10 },
          { header: "Coupon", key: "coupon", width: 10 },
          { header: "Status", key: "status", width: 15 },
          { header: "Transaction ID", key: "transaction_id", width: 20 },
          { header: "Created At", key: "createdAt", width: 25 },
          { header: "Created Date", key: "created_date", width: 25 },
          { header: "Current IP", key: "current_ip", width: 20 },
          { header: "Latitude", key: "latitude", width: 15 },
          { header: "Longitude", key: "longitude", width: 15 },
          { header: "Updated At", key: "updatedAt", width: 25 },
        ];
  
        // Add data rows
        orders.forEach((order) => {
          worksheet.addRow({
            order_id: order.order_id,
            firstName: order.firstName,
            lastName: order.lastName,
            email: order.email,
            phone: order.phone,
            address: `${order.apartment},${order.company},${order.city},${order.state},${order.country},${order.pincode}`,
            product_name: Array.isArray(order.product_name) ? order.product_name.join(', ') : order.product_name,
            qty: order.qty,
            price: order.price,
            payment_mode: order.payment_mode,
            payment_status: order.payment_status,
            discount: order.discount,
            coupon: order.coupon,
            status: order.status,
            transaction_id: order.tranaction_id,
            createdAt: order.createdAt,
            created_date: order.created_date,
            current_ip: order.current_ip,
            latitude: order.latitude,
            longitude: order.longitude,
            updatedAt: order.updatedAt,
          });
        });
  
        // Write workbook to buffer
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Create a blob from buffer
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        // Create a temporary anchor element
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.download = "orders.xlsx";
  
        // Programmatically trigger a click event on the anchor element
        anchor.click();
      } else {
        console.error("Error downloading Excel data:", result.message);
      }
    } catch (error) {
      console.error("Error downloading Excel data:", error);
    }
  };
  

 
  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const handleViewDetail = (item) => {
    navigate('/orderDetail', { state: { selectedItem: item } });
  };
  const handleViewShipping = (item) => {
    setPopUpOpenshipping(!popUpOpenshipping);
    setItemIdShipping(item);
    getOrderdata();
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
  useEffect(() => {
    orderList.forEach(order => {
      console.log(order.shipment_order_id);
      if (order.shipment_order_id) {
      
        getOrderDataship(order.shipment_order_id);
      }
    });
  }, [orderList]);
  const dropdownMenu = (itemId, status, shipment_order_id, item, shipment_id) => {
    let menuOptions;
      menuOptions = (
        <>
         {status !== "Pending" && status.toLowerCase() !== "canceled" && (
        <Menu.Item key="3" onClick={() => togglePopUpstatus(shipment_order_id)}>
            <FaEdit />
            <span className="show_span_edit">Cancel</span>
          </Menu.Item>
             )}
          <Menu.Item key="4" onClick={() => handleViewDetail(item)}>
            <FaEdit />
            <span className="show_span_edit">View Details</span>
          </Menu.Item>
          {status === "Pending" && ( 
          <Menu.Item key="5" onClick={() => handleViewShipping(item)}>
          <FaEdit />
          <span className="show_span_edit">Order Shipping</span>
        </Menu.Item>
          )} 
        </>
      );
    //}
  
    return <Menu>{menuOptions}</Menu>;
  };
  

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column all_product_hidden_edit">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Orders List </h1>
                {/* <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search title"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                    >
                      Orders Table List
                    </h6>
                    <div className="my_add_event_form_field subcatgory_select_option" style={{ float: "right" }}>
                    {/* <Form.Group controlId="formDate">
                      <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search title"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                          className="input_type_search_common"
                        />
                      </div>
                    </Form.Group> */}
                    <div>
                    <button
                          onClick={downloadExcel}
                          className="btn btn-success ml-2"
                        >
                          Download Excel
                        </button>
                    </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Order Id</th>
                            <th scope="col" style={{ width: '10px' }}>User Details</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price (Rs)</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Payment Mode</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Delivery Status</th>
                            <th scope="col">Action</th> 
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.length > 0 ? (
                            orderList.map((item, index) => (
                              
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                               
                                <td>{item.order_id}</td>
                                {/* <td style={{ textTransform: 'capitalize' }}> 
                                {item.firstName} {item.lastName},{item.email},{item.phone}</td> */}
                                 <td>
            {capitalizeFirstLetter(item.firstName)} {capitalizeFirstLetter(item.lastName)}, {item.email.toLowerCase()}, {item.phone}
        </td>

                                <td style={{ textTransform: 'capitalize' }}>
          {Array.isArray(item.product_name) ? (
            <>
              {item.product_name[0]}
              {item.product_name.length > 1 && '...'}
            </>
          ) : (
            typeof item.product_name === 'string' && item.product_name.split(",")[0]
          )}
        </td>
                                {/* <td style={{ textTransform: 'capitalize' }}>
                                  {item.product_name.join(", ")}
                                </td> */}
                                <td>{item.qty}</td>
                                <td>{item.price} </td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td style={{ textTransform: 'capitalize' }}>{item.payment_mode}</td>
                                <td
                                  style={{
                                    color:
                                      item.payment_status === "Pending"
                                        ? "red"
                                        : "#19a170",
                                  }}
                                >
                                  {item.payment_status}
                                </td>
                                <td
  style={{
    color: item.status === "Canceled" || item.status === "Pending" ? "red" : "green",
    textTransform: "none" // Ensure textTransform doesn't interfere
  }}
>
  {formatStatus(item.status)}
</td>
<td>
                                  <Dropdown
                                    overlay={() => dropdownMenu(item._id, item.status, item.shipment_order_id, item, item.shipment_id)}
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                               {/*} <td>
                                  {item.order_status !== "Cancelled"  && item.order_status !== "Accept"? (
                                    <Dropdown
                                      overlay={() =>
                                        dropdownMenu(
                                          item._id,
                                          item.order_status
                                        )
                                      }
                                      placement="bottomLeft"
                                      arrow
                                    >
                                      <BsThreeDots />
                                    </Dropdown>
                                  ) : null}
                                    </td>*/}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
      <OrderShippingPopUp
        popUpOpenshipping={popUpOpenshipping}
        handleViewShipping={handleViewShipping}
        itemIdPassShipping={itemIdPassShipping}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
        itemIdTocancelstatus={itemIdTocancelstatus}
      />
    </div>
  );
}

export default ListOrder;
