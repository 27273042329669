import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Upload } from "antd";
import { useNavigate,Link } from "react-router-dom";
import { updateAboutformData } from "../Controller/Privacy"; // Update imports accordingly
import { useLocation } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";
import AddblogModel from "./EditPrivacypopup";

function EditPrivacyData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [selectedItem, setSelectedItem] = useState({}); // Define setSelectedItem

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    image: null,
  });

  const handleInputChange = (value, field) => {
    if (field === "title") {
      setFormData((prevVal) => ({
        ...prevVal,
        title: value,
      }));
    } else if (field === "description") {
      setFormData((prevVal) => ({
        ...prevVal,
        description: value,
      }));
    } else {
      const name = field;
      const inputValue = value;

      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  const handleFileChange1 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required.",
        }));
      }else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Description is required.',
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateAboutformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            title: "",
            description: "",
            image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [...prevVal.variants, { images: null }],
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const locationState = location.state;
    if (locationState && locationState.selectedItem) {
      setSelectedItem(locationState.selectedItem);
      // Set formData state with selectedItem's values
      setFormData({
        ...formData,
        id: locationState.selectedItem._id,
        title: locationState.selectedItem.title,
        description: locationState.selectedItem.description,
        image: locationState.selectedItem.image,
      });
    }
  }, [location.state]);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Edit Privacy Policy Content </h5>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputText">Title<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="title"
                            name="title" // Make sure the name attribute is set to 'title'
                            value={formData.title}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "title")
                            }
                          />

                          {errors.title && (
                            <p style={{ color: "red" }}>{errors.title}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={(value) =>
                              handleInputChange(value, "description")
                            }
                          />
                          {errors.description && <p style={{ color: "red" }}>{errors.description}</p>}
                        </div>
                        <div className="row">
                          <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                            <ImgCrop rotate aspect={636 / 850}>
                              <Upload
                                className="avatar-uploader"
                                name="image"
                                onChange={({ fileList }) =>
                                  handleFileChange1(fileList)
                                }
                                listType="picture-card"
                                fileList={null}
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.image ? (
                                  <img
                                    src={
                                      typeof formData.image === "string"
                                        ? `${imageURL}${formData.image}`
                                        : URL.createObjectURL(formData.image)
                                    }
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </div>

                        <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/privacyListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default EditPrivacyData;
