import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { addBannerformData, getbrandDatafetch } from "../../Controller/Banner";
import { getCategoryDatafetch } from "../../Controller/Product";
import AddbannerModel from "./Addbannerpopup";
import { Link, useNavigate } from "react-router-dom";
import "react-dates/initialize";
import "./Banner.css";

function AddBannerData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [categories, setCategoryData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    page_name: "",
    button_title: "",
    button_url: "",
    category_id: "",
    title: "",
    banner_image: [],
    bottom_banner_image:[],
  });
  const handleFileChangeForRow1 = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevFormData) => {
      const updatedHighlights = [...prevFormData.banner_image];
      // Always add images to the last highlight
      const lastHighlight = updatedHighlights[updatedHighlights.length - 1];
      const existingImages = lastHighlight ? [...lastHighlight.images] : [];
      const newImages = [...existingImages, ...files];
      if (lastHighlight) {
        // If last highlight exists, update its images
        lastHighlight.images = newImages;
      } else {
        // If no highlight exists, create a new one
        updatedHighlights.push({ images: newImages });
      }
      return {
        ...prevFormData,
        banner_image: updatedHighlights,
      };
    });
  };
  const handleFileChangeForRow2 = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevFormData) => {
      const updatedHighlights = [...prevFormData.bottom_banner_image];
      // Always add images to the last highlight
      const lastHighlight = updatedHighlights[updatedHighlights.length - 1];
      const existingImages = lastHighlight ? [...lastHighlight.images] : [];
      const newImages = [...existingImages, ...files];
      if (lastHighlight) {
        // If last highlight exists, update its images
        lastHighlight.images = newImages;
      } else {
        // If no highlight exists, create a new one
        updatedHighlights.push({ images: newImages });
      }
      return {
        ...prevFormData,
        bottom_banner_image: updatedHighlights,
      };
    });
  };
  const handleFileChange = (fileList, fieldName) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: file }));
    }
  };
  const removeImageFromRow1 = (rowIndex, imageIndex) => {
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.banner_image];

      // Check if the highlight at the specified rowIndex exists
      if (updatedVariants[rowIndex] && updatedVariants[rowIndex].images) {
        const updatedImages = [...updatedVariants[rowIndex].images];

        // Remove the image at the specified imageIndex
        updatedImages.splice(imageIndex, 1);

        // Update the images array for the specific highlight
        updatedVariants[rowIndex].images = updatedImages;
      }

      return {
        ...prevFormData,
        banner_image: updatedVariants,
      };
    });
  };
  const removeImageFromRow2 = (rowIndex, imageIndex) => {
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.bottom_banner_image];

      // Check if the highlight at the specified rowIndex exists
      if (updatedVariants[rowIndex] && updatedVariants[rowIndex].images) {
        const updatedImages = [...updatedVariants[rowIndex].images];

        // Remove the image at the specified imageIndex
        updatedImages.splice(imageIndex, 1);

        // Update the images array for the specific highlight
        updatedVariants[rowIndex].images = updatedImages;
      }

      return {
        ...prevFormData,
        bottom_banner_image: updatedVariants,
      };
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };

  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  useEffect(() => {
    if (formData.page_name === "shop_page") {
      fetchCategories();
    }
  }, [formData.page_name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.page_name) {
        setErrors((preError) => ({
          ...preError,
          page_name: "Page name is required.",
        }));
      } else {
        setIsSubmitting(true); 
        const updateResult = await addBannerformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData({
            page_name: "",
            button_title: "",
            button_url: "",
            banner_image: [], // Ensure banner_image is reset to an empty array
            bottom_banner_image: [], // Ensure banner_image is reset to an empty array
          });
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Banner </h1>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-4">
                          <label htmlFor="page_name">
                            Page Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control common_category_input_for_all_section "
                            onChange={handleInputChange}
                            id="page_name"
                            name="page_name"
                            value={formData.page_name}
                          >
                            <option value="">Select Page Name</option>
                            <option value="home">Home Page</option>
                            <option value="shop_page">Shop Page</option>
                            <option value="be_our_stocklists">Be Our Stockists Page</option>
                          </select>
                          {errors.page_name && (
                            <p style={{ color: "red" }}>{errors.page_name}</p>
                          )}
                        </div>
                        {formData.page_name === "shop_page" && (
                          <div className="col-md-4">
                            <label htmlFor="category_id">Category</label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={handleInputChange}
                              name="category_id"
                              id="category_id"
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            {errors.category_id && (
                              <p style={{ color: "red" }}>
                                {errors.category_id}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="col-md-4">
                          <label htmlFor="button_title">Title</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="title"
                            name="title"
                            onChange={handleInputChange}
                            value={formData.title}
                            placeholder="Enter  Title"
                          />
                          {errors.title && (
                            <p style={{ color: "red" }}>{errors.title}</p>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="button_title">Button Title</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="button_title"
                            name="button_title"
                            onChange={handleInputChange}
                            value={formData.button_title}
                            placeholder="Enter Button Title"
                          />
                          {errors.button_title && (
                            <p style={{ color: "red" }}>
                              {errors.button_title}
                            </p>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="button_url">Button Url</label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="button_url"
                            name="button_url"
                            onChange={handleInputChange}
                            value={formData.button_url}
                            placeholder="Enter Button URL"
                          />
                          {errors.button_url && (
                            <p style={{ color: "red" }}>{errors.button_url}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="banner_image">Top Banner Images </label>
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => handleFileChangeForRow1(e)}
                        />

                        {formData.banner_image.map((banner_images, index1) => (
                          <div key={index1}>
                            {banner_images.images.map((file, imageIndex) => (
                              <div
                                key={imageIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`Banner Image ${imageIndex}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: "5px",
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() =>
                                    removeImageFromRow2(index1, imageIndex)
                                  }
                                  style={{ cursor: "pointer", color: "red" }}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>

                      <div className="form-group">
                        <label htmlFor="banner_image">Bottom Banner Images </label>
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => handleFileChangeForRow2(e)}
                        />

                        {formData.bottom_banner_image.map((bottom_banner_image, index1) => (
                          <div key={index1}>
                            {bottom_banner_image.images.map((file, imageIndex) => (
                              <div
                                key={imageIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`Bottom Banner Image ${imageIndex}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: "5px",
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() =>
                                    removeImageFromRow1(index1, imageIndex)
                                  }
                                  style={{ cursor: "pointer", color: "red" }}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>

                      <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/bannerList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBannerData;
