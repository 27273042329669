import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { updateEmailformData, getEmailData } from "../Controller/Email";
import AddCoupenpopup from "./EditEmailpopup";
import { Link, useNavigate } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./email.css";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";

function EditEmailData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    mail_type: "",
    from_email: "",
    from_email1: "",
    subject: "",
    mail_header: "",
    mail_body: "",
    mail_footer: "",
    html: "",
  });

  const handleInputChange = (e, name) => {
    const value = e.target ? e.target.value : e; // Handle the case where e is the value directly (from ReactQuill)
    const fieldName = name || e.target.name; // Use the provided name or e.target.name if available

    setFormData((prevVal) => ({
      ...prevVal,
      [fieldName]: value,
    }));

    setErrors((prevError) => ({
      ...prevError,
      [fieldName]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.mail_type) {
        setErrors((preError) => ({
          ...preError,
          mail_type: "Email Type is required.",
        }));
      } else if (!formData.from_email) {
        setErrors((preError) => ({
          ...preError,
          from_email: "Email From is required.",
        }));
      } else if (!formData.subject) {
        setErrors((preError) => ({
          ...preError,
          subject: "Subject is required.",
        }));
      } else if (!formData.mail_header) {
        setErrors((preError) => ({
          ...preError,
          mail_header: "Mail Header is required.",
        }));
      } else if (!formData.mail_body) {
        setErrors((preError) => ({
          ...preError,
          mail_body: "Mail Body is required.",
        }));
      } else if (!formData.mail_footer) {
        setErrors((preError) => ({
          ...preError,
          mail_footer: "Mail Footer is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateEmailformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            mail_type: "",
            from_email: "",
            subject: "",
            mail_header: "",
            mail_body: "",
            mail_footer: "",
            html: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
          setErrors((preError) => ({
            ...preError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const getCoupenfetchData = async () => {
    const options = { id: edit };
    try {
      const result = await getEmailData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id;
        setFormData(modifiedData);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCoupenfetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Search } = Input;
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Email Template </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">
                            Email Type<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control common_category_input_for_all_section"
                            onChange={(e) => {
                              handleInputChange(e, "mail_type");
                            }}
                            value={formData.mail_type}
                          >
                            <option value="">Select Email Type</option>
                            <option value="Order Confirm">Order Confirm</option>
                            <option value="Subscribe Newsletter">Subscribe Newsletter</option>
                            <option value="Forgot Password">Forgot Password</option>
                            <option value="Stockists">Stocklist</option>
                          </select>
                          {errors?.mail_type ? (
                            <p style={{ color: "red" }}>{errors?.mail_type}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">
                            Email From<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="from_email"
                            name="from_email"
                            placeholder="Enter Email From"
                            value={formData.from_email}
                            onChange={(e) => {
                              handleInputChange(e, "from_email");
                            }}
                          />
                          {errors?.from_email ? (
                            <p style={{ color: "red" }}>{errors?.from_email}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        
                        <div className="col-md-12">
                          <label
                            htmlFor="subject"
                            className="all_top_and_bottom_for_input_text"
                          >
                            Email Subject<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="subject"
                            name="subject"
                            placeholder="Enter Subject"
                            value={formData.subject}
                            onChange={(e) => handleInputChange(e, "subject")}
                          />
                          {errors?.subject ? (
                            <p style={{ color: "red" }}>{errors?.subject}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="mail_header"
                            className="all_top_and_bottom_for_input_text"
                          >
                            Mail Header<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="mail_header"
                            name="mail_header"
                            value={formData.mail_header}
                            onChange={(value) =>
                              handleInputChange(value, "mail_header")
                            }
                          />

                          {errors?.mail_header ? (
                            <p style={{ color: "red" }}>
                              {errors?.mail_header}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="discount"
                            className="all_top_and_bottom_for_input_text"
                          >
                            Mail Body<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="mail_body"
                            name="mail_body"
                            value={formData.mail_body}
                            onChange={(value) =>
                              handleInputChange(value, "mail_body")
                            }
                          />

                          {errors?.mail_body ? (
                            <p style={{ color: "red" }}>{errors?.mail_body}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="discount"
                            className="all_top_and_bottom_for_input_text"
                          >
                            Mail Footer<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="mail_footer"
                            name="mail_footer"
                            value={formData.mail_footer}
                            onChange={(value) =>
                              handleInputChange(value, "mail_footer")
                            }
                          />

                          {errors?.mail_footer ? (
                            <p style={{ color: "red" }}>
                              {errors?.mail_footer}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        style={{
                          backgroundColor: "#e7aeb6",
                          color: "#fff",
                          borderColor: "#e7aeb6",
                        }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Update"
                        )}
                      </button>
                      <Link to="/emailListPage">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#e7aeb6",
                            color: "#fff",
                            borderColor: "#e7aeb6",
                            marginLeft: "12px",
                          }}
                        >
                          <span>Cancel</span>
                        </button>
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEmailData;
