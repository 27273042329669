import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Link, useNavigate } from "react-router-dom";
// import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Form } from "react-bootstrap";
import {
  addCategoryformData,
  getCategoryDatafetch,
} from "../Controller/Category";
import AddCategoryModel from "./AddCategorypopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Category.css";
import ReactQuill from "react-quill";


function AddCategoryData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  
  const [formData, setFormData] = useState({
    category_name: "",
  });

  const handleInputChange = (value, field) => {
    if (field === 'category_description') {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === 'category_name') {
      setFormData((prevVal) => ({
        ...prevVal,
        category_name: value,
      }));
    }else {
      const name = field;
      const inputValue = value;
    
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: '',
      }));
    }
  };
  

  
  

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, category_image: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.category_name) {
        setErrors((prevError) => ({
          ...prevError,
          category_name: "Category name is required.",
        }));
      }else {
        setIsSubmitting(true); 
        const updateResult = await addCategoryformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            category_name: "",
            header_show:"",
            category_description: "",
            category_image: null, // Clear the uploaded file
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
          localStorage.removeItem('ADMIN-INFO');
          navigate("/login");
          setErrors((prevError) => ({
            ...prevError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
 
  
  
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const { Dragger } = Upload;
  
  // const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column"> 
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Category </h1>
                <div className="card shadow mb-4"  style={{ backgroundColor: "#fff8f9" }}>
                
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="fullname">Category Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section "
                          id="category_name"
                          name="category_name"
                          placeholder="Enter Category Name"
                          value={formData.category_name}  // Add this line
                          onChange={(e) => handleInputChange(e.target.value, 'category_name')}  // Modify this line
                        />
                        {errors?.category_name ? (
                          <p style={{ color: "red" }}>
                            {errors?.category_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="col-md-6">
                          <label htmlFor="exampleInputCheckbox">Header (Active/Inactive)</label>
                          <input
                            type="checkbox"
                            style={{ marginLeft: "14px", borderColor: "#df1616" }}
                            className="form-check-input"
                            id="header_show"
                            name="header_show"
                            checked={formData.header_show === "Yes"}
                            onChange={(e) => handleInputChange(e.target.checked, "header_show")}
                          />

                        </div> */}

                      {/* <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="category_description"
                            name="category_description"
                            onChange={(value) => handleInputChange(value, 'category_description')}
                          />


                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                                <input type="file" className="form-control"  id="category_image" name="category_image"
                                  onChange={handleFileChange1}
                            />
                            </div> */}
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/categoryList">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>

        
                    </form>
                  </div>
                </div>
              </div>
            
            </div>
            <AddCategoryModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryData;
