import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from './API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getBlogDatafetch = async (options , authToken)=>{
    try{
        const {page,title}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'about/list-about',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

export const statusCategoryData = async (options , authToken)=>{
  try{
      const {id,status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'blog/status-category',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const statusBlogData = async (options , authToken)=>{
  try{
      const {id,status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'about/status-about',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getblogdata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'about/get-about',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getblogcategorydata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'blog/get-blog_category',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const getBlogcategoryDatafetch = async (options , authToken)=>{
  try{
      const {page,blog_category_name}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'blog/list-blog_category',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 

 /*********************************************************
 *  This function is use to addAboutformData api
 *********************************************************/
 export const addAboutformData = async (options, authToken) => {
  try {
    const { title,description,image,image_2} = options;
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);
    formData.append('image_2', image_2);

    const posrData = {
      url: API_ADMIN_URL + 'about/add-about',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    console.log(posrData);
    const res = await postRequest(posrData);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

   // End of Function

   export const addBlogcategoryformData = async (options, authToken) => {
    try {
      const { blog_category_name } = options;
      const posrData = {
        url: API_ADMIN_URL + 'blog/add-blog_category',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      
      const res = await postRequest(posrData);
      
      if (res.status === 200) {
        return res.data; // assuming res.data contains the response data
      } else {
        return { status: false, message: res.data?.statusMessage };
      }
    } catch (error) {
      console.error(error);
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
  
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateAboutformData = async (options, authToken) => {
    try {
      const { title,description,image,image_2,id } = options;
      const postData = {
        url: API_ADMIN_URL + 'about/edit-about',
        postData: options,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  export const updateBlogcategoryformData = async (options, authToken) => {
    try {
      const {blog_category_name, id } = options;
      const postData = {
        url: API_ADMIN_URL + 'blog/edit-blog_category',
        postData: options,
        headers: {
          authToken: authToken,
         
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteBlogDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'about/delete-about',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

export const deleteBlogcategoryDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'blog/delete-blog_category',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
