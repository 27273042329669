import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload } from "antd";
import "../../AllCssFile.css";
import "react-quill/dist/quill.snow.css";
import { addProductNameformData, } from "../../Controller/Product";
import AddProductpopup from "./AddProductpopup";
import { Link } from "react-router-dom";
function ProductNameCreate() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    product_name: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      }else {
        setIsSubmitting(true); 
        const updateResult = await addProductNameformData(formData, adminInformation.token);
        if (updateResult.data.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleInputChange = (selectedValue, field, index) => {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [field]: "",
      }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Product Name </h5>
                  </div>
                </div>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">Product Name<span style={{ color: 'red' }}>*</span></label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section "
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                              onChange={(e) => handleInputChange(e.target.value, "product_name")}
                            />
                            {errors?.product_name ? <p style={{ color: "red" }}>{errors?.product_name}</p> : ""}
                          </div>
                        </div>
                       
<button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/listProductName">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default ProductNameCreate;

