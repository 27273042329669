import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import Logoutpopup from "./logout";

function Header() {
  const navigate = useNavigate();
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  let firstname = "";
  let middlename = "";
  let lastname = "";

  if (adminInformationString !== "") {
    try {
      const adminInformation = JSON.parse(adminInformationString);
      firstname = `${adminInformation.admin_first_name}`;
      middlename = `${adminInformation.admin_middle_name}`;
      lastname = `${adminInformation.admin_last_name}`;
    } catch (error) {
      console.error("Error parsing admin information:", error);
    }
  } else {
    navigate("/login");
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">


          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <nav className="navbar navbar-expand navbar-light  topbar mb-4 static-top shadow" style={{ backgroundColor: "#fff8f9",}}>
                <button
                  id="sidebarToggleTop"
                  className="btn btn-link d-md-none rounded-circle mr-3"
                  onClick={changeStyle1}
                >
                  <i className="fa fa-bars"></i>
                </button>
                <ul className="navbar-nav ml-auto">

                  <div className="topbar-divider d-none d-sm-block"></div>

                  <li className="nav-item dropdown no-arrow">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                        {capitalizeFirstLetter(firstname)}  {capitalizeFirstLetter(middlename)}  {capitalizeFirstLetter(lastname)}
                      </span>
                      <img
                        className="img-profile rounded-circle"
                        src="../img/undraw_profile.svg"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="userDropdown"
                    >
                      <Link to="/editProfile">
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                      </Link>
                      <div className="dropdown-divider"></div>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-toggle="modal"
                        data-target="#logoutModal"
                        onClick={togglePopUp}
                      >
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </nav>
              <Logoutpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
