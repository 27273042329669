import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { addDiscountformData} from "../Controller/Discount";
import { getCategoryDatafetch,getSubCategoryDatafetch} from "../Controller/Product";

import AddCoupenpopup from "./AddDiscountpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Discount.css";

function AddDiscountData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [subCategories, setSubCategoryData] = useState([]);
  const [categories, setCategoryData] = useState([]);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    coupon_code: "",
    discount: "",
    category_id:"",
    subcategory_id:"",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData((prevVal) => ({
      ...prevVal,
      [fieldName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "", // Clear any previous errors for this field
    }));
  };
  

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.discount) {
        setErrors((preError) => ({
          ...preError,
          discount: "Discount is required.",
        }));
      } else if (!formData.discount_type) {
        setErrors((preError) => ({
          ...preError,
          discount_type: "Discount Type is required.",
        }));
      } else {
        const updateResult = await addDiscountformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            discount_type: "",
            discount: "",
          })); 
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
          setErrors((preError) => ({
            ...preError,
            discount_type:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
          const fetchSubCategories = async (categoryId) => {
            const options = {
              // Add any additional options needed for fetching sub-categories
              category_id: categoryId,
            };
          
            try {
              console.log(options);
              const result = await getSubCategoryDatafetch(options, adminInformation.token);
              if (result.data && result.data.response) {
                const responseData = result.data.response.result;
                setSubCategoryData(responseData);
              } else {
                console.error("Unexpected response format:", result.data);
              }
            } catch (error) {
              console.error("Error fetching sub-category data:", error);
            }
          };
          const fetchCategories = async () => {
            const options = {};
            try {
              const result = await getCategoryDatafetch(options, adminInformation.token);
              if (result.data && result.data.response) {
                const responseData = result.data.response.result;
                setCategoryData(responseData);
              } else {
                localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
                console.error("Unexpected response format:", result.data);
              }
            } catch (error) {
              console.error("Error fetching banner data:", error);
            }
          };
          const handleSelectClick = () => {
            fetchCategories();
          };
          const handleCategorySelect = (categoryId) => {
  
            fetchSubCategories(categoryId);
          };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Dragger } = Upload;
  
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Discount </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="exampleInputText">Type</label>
                        <select className="form-control" onChange={(e) => {
                                handleInputChange(e,"discount_type");
                              }}
                          >
                          <option value="">Select Type</option>
                          <option value="first_time_user">First Time User</option>
                        </select>
                        {errors?.discount_type ? <p style={{ color: "red" }}>{errors?.discount_type}</p> : ""}
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="exampleInputText">Category</label>
                        <select
  className="form-control"
  onChange={(e) => {
    handleInputChange(e,"category_id");
    handleCategorySelect(e.target.value.split("|")[0], e.target.value.split("|")[1]);
  }}
  onClick={handleSelectClick}
>

                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                        {errors?.category_id ? <p style={{ color: "red" }}>{errors?.category_id}</p> : ""}
                      </div>

                              <div className="col-md-6">
                                <label htmlFor="exampleInputText">Sub Category</label>
                                <select
  className="form-control"
  onChange={(e) => handleInputChange(e, "subcategory_id")}
>

                                  <option value="">Select Sub Category</option>
                                  {subCategories.map((subCategory) => (
                                    <option key={subCategory._id} value={subCategory._id}>
                                      {subCategory.name}
                                    </option>
                                  ))}
                                </select>
                                {errors?.subcategory_id ? <p style={{ color: "red" }}>{errors?.subcategory_id}</p> : ""}
                              </div>
        */}
                        <div className="col-md-12">
                          <label htmlFor="discount">Discount (Percentage)</label>
                          <input
                            type="number"
                            className="form-control"
                            id="discount"
                            name="discount"
                            placeholder="Enter Discount"
                            onChange={(e) => handleInputChange(e, "discount")}
                          />
                          {errors?.discount ? (
                            <p style={{ color: "red" }}>
                              {errors?.discount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
             
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDiscountData;
