import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from './API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getBannerDatafetch = async (options , authToken)=>{
    try{
        const {page,clinical_title}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'aboutproduct/list-about-product',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function


export const getCategoryDatafetch = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/product-all',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function

export const statusCategoryData = async (options , authToken)=>{
  try{
      const {id,status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'aboutproduct/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getBannerData = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'banner/get-banner',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
 export const addBannerformData = async (options, authToken) => {
    try {
      const { clinical_title,clinical_detail,product_id, shade_title,before_title, clinical_image,shade_image,before_image,after_image} = options;
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('clinical_title', clinical_title);
      formData.append('clinical_detail', clinical_detail);
      formData.append('product_id', product_id);
      formData.append('shade_title', shade_title);
      formData.append('before_title', before_title);
      formData.append('clinical_image', clinical_image);
      formData.append('shade_image', shade_image);
      formData.append('before_image', before_image);
      formData.append('after_image', after_image);
      const posrData = {
        url: API_ADMIN_URL + 'aboutproduct/add-about-product',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
      console.log(posrData);
  
      const res = await postRequest(posrData);
      console.log(res);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateBannerformData = async (options, authToken) => {
    try {
      const { clinical_title,clinical_detail,product_id, shade_title,before_title, clinical_image,shade_image,before_image,after_image, id } = options;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('clinical_title', clinical_title);
      formData.append('clinical_detail', clinical_detail);
      formData.append('product_id', product_id);
      formData.append('shade_title', shade_title);
      formData.append('before_title', before_title);
      formData.append('clinical_image', clinical_image);
      formData.append('shade_image', shade_image);
      formData.append('before_image', before_image);
      formData.append('after_image', after_image);

      const postData = {
        url: API_ADMIN_URL + 'aboutproduct/edit-about-product',
        postData: formData, // Pass the FormData object
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };

      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
};

 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteBannerData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'aboutproduct/delete-about-product',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
export const getbrandDatafetch = async (options , authToken)=>{
  try{
      const {brand}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'banner/list-all-brand',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 