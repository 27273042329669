import React, { useState,useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link,useNavigate  } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import NewsletterDelete from "./NewsletterDelete";
import { getNewsletterDatafetch} from '../../Controller/Newsletter';
import "./NewsletterData.css";

function NewsletterList() {
  // Delete Popup
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this 
  const [totalItems, setTotalItems] = useState(0);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [newsletterList, setNewsletterData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
      setItemIdToDelete(itemId);
       getNewsletterdata();
  };
  const handleSearch = (value) => {
    console.log("Search Value:", value);
    setSearchValue(value);
    getNewsletterdata(currentPage, value);
  };
/*************************************************************
 * Function Name  : getsocialdata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
const getNewsletterdata =  async (page, email) => {
  const options = {
    page: page,
    email:email, // Include additional search options
  };
  try {
    const result = await getNewsletterDatafetch(options ,adminInformation.token);
    if (result.data && result.data.response) {
      const responseData =result.data.response.result.newsletterData;
      const totalcount = result.data.response.result.totalcount;
      setNewsletterData(responseData);
      setTotalItems(totalcount);
     
    } else {
      localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
const handleRowClick = (itemId) => {
 // history.push(`/createEventEditPage/${itemId}`);
  navigate(`/socialiconEdit/${itemId}`);
};

const handlePageChange = (event, page) => {
  setCurrentPage(page);
  getNewsletterdata(page);
};
  

  
  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsletterdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  
  const dropdownMenu = (itemId) => (
    <Menu>
      <Menu.Item key="2" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );
  
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Newsletters List</h1>
                <p className="mb-4">
                  
                </p>
                {/* <div className="table_head_main_class">
                 
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search email"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold">
                    Newsletters List Table
                    </h6>
                    <div className="my_add_event_form_field subcatgory_select_option" style={{ float: "right" }}>
                    {/* <Form.Group controlId="formDate">
                      <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search email"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                          className="input_type_search_common"
                        />
                      </div>
                    </Form.Group> */}
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheckTitle"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckTitle"
                                >
                                  S No
                                </label>
                              </div> */}
                              S.No
                            </th>
                            <th scope="col">Email</th>
                           {/*} <th scope="col">Action</th>*/}
                          </tr>
                        </thead>
                        <tbody>
                        {newsletterList.length > 0 ? (
                        newsletterList.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customCheckTitle${item.id}`}
                                />
                                <label 
                                  className="custom-control-label"
                                  htmlFor={`customCheckTitle${item.id}`}
                                >
                                  {(currentPage - 1) * 10 + index + 1}
                                </label>
                              </div> */}
                              {(currentPage - 1) * 10 + index + 1}
                            </td>
                            <td>{item.email}</td>
                           
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">No data found</td>
                        </tr>
                      )}
                        </tbody>
                      </table>
                      <p>Show {((currentPage - 1) * 10) + 1} to {Math.min(currentPage * 10, totalItems)} of {totalItems}</p>
                      
                      <div className="table_pagination_sales_order">
                      <Pagination count={Math.ceil(totalItems / 10)} page={currentPage} onChange={(event, page) => handlePageChange(event, page)} />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsletterDelete
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      ></NewsletterDelete>
    </div>
  );
}
export default NewsletterList;
