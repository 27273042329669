import React, { useEffect, useState } from "react";
import "./DashBaord.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Header from "../HeaderDashBoard/Header";
import SideBar from "../SideBar/SideBar";
import Footer from "../Footer/Footer";
import { Chart } from "chart.js/auto";
import { getproducttotalDatafetch } from "../Controller/Product";
import { getordertotalDatafetch } from "../Controller/Order";
import { getcategorytotalDatafetch } from "../Controller/Category";
import { getcoupontotalDatafetch } from "../Controller/Coupen";
import { getusertotalDatafetch } from "../Controller/User";
import { getsubtotalDatafetch } from "../Controller/Subcategory";
import {getReviewDatafetch} from "../Controller/Review";

function Dashboard() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [totalproduct, setproducttotal] = useState([]);
  const [totalorder, setordertotal] = useState([]);
  const [totalcategory, setcategorytotal] = useState([]);
  const [totalcoupon, setcoupontotal] = useState([]);
  const [totaluser, setusertotal] = useState([]);
  const [totalsub, setsubtotal] = useState([]);
  const [totalReview, setReviewtotal] = useState([]);
  // Login for chart
  // useEffect(() => {

  //   const ctx = document.getElementById("myAreaChart").getContext("2d");

  //   new Chart(ctx, {
  //     type: "line",
  //     data: {
  //       labels: [
  //         "January",
  //         "February",
  //         "March",
  //         "April",
  //         "May",
  //         "June",
  //         "July",
  //         "August",
  //         "September",
  //         "October",
  //         "November",
  //         "December",
  //       ],
  //       datasets: [
  //         {
  //           label: "Sample Data",
  //           data: [65, 59, 80, 81, 56, 55, 40, 30, 45, 60, 75, 90],
  //           fill: true,
  //           borderColor: "rgba(75,192,192,1)",
  //           backgroundColor: "rgba(75,192,192,0.2)",
  //         },
  //       ],
  //     },
  //     options: {
  //       scales: {
  //         x: [
  //           {
  //             type: "category",
  //             labels: [
  //               "January",
  //               "February",
  //               "March",
  //               "April",
  //               "May",
  //               "June",
  //               "July",
  //               "August",
  //               "September",
  //               "October",
  //               "November",
  //               "December",
  //             ],
  //           },
  //         ],
  //         y: [
  //           {
  //             ticks: {
  //               beginAtZero: true,
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   });
  // }, []);
  // for pie chart
  /* useEffect(() => {
    const ctx = document.getElementById("myPieChart").getContext("2d");

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Category 1", "Category 2", "Category 3"],
        datasets: [
          {
            data: [30, 50, 20],
            backgroundColor: ["red", "blue", "green"], 
          },
        ],
      },
      options: {
      },
    });
  }, []);*/
  const getproducttotal = async () => {
    const options = {};
    try {
      const result = await getproducttotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        console.log(result.data.response.result);
        setproducttotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getordertotal = async () => {
    const options = {};
    try {
      const result = await getordertotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setordertotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getcategorytotal = async () => {
    const options = {};
    try {
      const result = await getcategorytotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setcategorytotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getcoupontotal = async () => {
    const options = {};
    try {
      const result = await getcoupontotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setcoupontotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getusertotal = async () => {
    const options = {};
    try {
      const result = await getusertotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setusertotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getsubcategorytotal = async () => {
    const options = {};
    try {
      const result = await getsubtotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setsubtotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getReviewtotal = async () => {
    const options = {};
    try {
      const result = await getReviewDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setReviewtotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getproducttotal();
    getordertotal();
    getReviewtotal();
    getcategorytotal();
    getcoupontotal();
    getusertotal();
    getsubcategorytotal();
    const adminInfo = localStorage.getItem("ADMIN-INFO");
    if (adminInfo === null) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <div>
      <body id="page-top">
        {/*  <!-- Page Wrapper --> */}
        <div id="wrapper">
          {/*  <!-- Sidebar --> */}
          <SideBar></SideBar>
          {/*  <!-- End of Sidebar --> */}

          {/*  <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/*  <!-- Main Content --> */}
            <div id="content">
              {/*  <!-- Topbar --> */}
              <Header></Header>
              {/*  <!-- End of Topbar --> */}

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {/*  <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  {/* <Link to="/login" className="collapse-item  welcome_heading"> */}
                    <h1 className="h3 mb-0 text-gray-800 welcome_heading">
                      Welcome To Dashboard
                    </h1>
                  {/* </Link> */}
                </div>

                <div className="row">
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/categoryList"
                                className="collapse-item"
                                style={{ fontSize: "14px" }}
                              >
                                Categories : {totalcategory}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/subcategoryList"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Sub Categories : {totalsub}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/productListPage"
                                className="collapse-item"
                                style={{ fontSize: "14px" }}
                              >
                                Products : {totalproduct}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/orderList"
                                className="collapse-item"
                                style={{ fontSize: "14px" }}
                              >
                                Orders : {totalorder}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/coupenListPage"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Coupons : {totalcoupon}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/userList"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Users : {totaluser}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/reviewList"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Reviews : {totalReview}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  <!-- Content Row --> */}

                <div className="row">
                  {/* <div className="col-xl-12 col-lg-7">
                    <div className="card shadow mb-12">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Earnings Overview
                        </h6>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="dropdown-header">
                              Dropdown Header:
                            </div>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="chart-area">
                          <canvas
                            id="myAreaChart"
                            width="1100"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/*}  <div className="col-xl-4 col-lg-5">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Revenue Sources
                        </h6>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="dropdown-header">
                              Dropdown Header:
                            </div>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="chart-pie pt-4 pb-2 text-center my_pie_chart_class">
                          <canvas id="myPieChart"></canvas>
                        </div>
                        <div className="mt-4 text-center small">
                          <span className="mr-2">
                            <i className="fas fa-circle text-primary"></i>{" "}
                            Direct
                          </span>
                          <span className="mr-2">
                            <i className="fas fa-circle text-success"></i>{" "}
                            Social
                          </span>
                          <span className="mr-2">
                            <i className="fas fa-circle text-info"></i> Referral
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>

                {/*   <!-- Content Row --> */}
                {/*} <div className="row">
                  <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Projects
                        </h6>
                      </div>
                     
                      <div className="card-body">
                        <h4 className="small font-weight-bold">
                          Server Migration{" "}
                          <span className="float-right">20%</span>
                        </h4>
                        <div className="progress mb-4">
                          <div
                            className="progress-bar bg-danger a2"
                            role="progressbar"
                            style={{ width: "20%" }}
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>

                        <h4 className="small font-weight-bold">
                          Sales Tracking{" "}
                          <span className="float-right">40%</span>
                        </h4>
                        <div className="progress mb-4">
                          <div
                            className="progress-bar bg-warning a3"
                            role="progressbar"
                            style={{ width: "40%" }}
                            aria-valuenow="40"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>

                        <h4 className="small font-weight-bold">
                          Customer Database{" "}
                          <span className="float-right">60%</span>
                        </h4>
                        <div className="progress mb-4">
                          <div
                            className="progress-bar a7"
                            role="progressbar"
                            style={{ width: "60%" }}
                            aria-valuenow="60"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>

                        <h4 className="small font-weight-bold">
                          Payout Details{" "}
                          <span className="float-right">80%</span>
                        </h4>
                        <div className="progress mb-4">
                          <div
                            className="progress-bar bg-info a4"
                            role="progressbar"
                            style={{ width: "80%" }}
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>

                        <h4 className="small font-weight-bold">
                          Account Setup{" "}
                          <span className="float-right">Complete!</span>
                        </h4>
                        <div className="progress">
                          <div
                            className="progress-bar bg-success a5"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-primary text-white shadow">
                          <div className="card-body">
                            Primary
                            <div className="text-white-50 small">#4e73df</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-success text-white shadow">
                          <div className="card-body">
                            Success
                            <div className="text-white-50 small">#1cc88a</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-info text-white shadow">
                          <div className="card-body">
                            Info
                            <div className="text-white-50 small">#36b9cc</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-warning text-white shadow">
                          <div className="card-body">
                            Warning
                            <div className="text-white-50 small">#f6c23e</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-danger text-white shadow">
                          <div className="card-body">
                            Danger
                            <div className="text-white-50 small">#e74a3b</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-secondary text-white shadow">
                          <div className="card-body">
                            Secondary
                            <div className="text-white-50 small">#858796</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-light text-black shadow">
                          <div className="card-body">
                            Light
                            <div className="text-black-50 small">#f8f9fc</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="card bg-dark text-white shadow">
                          <div className="card-body">
                            Dark
                            <div className="text-white-50 small">#5a5c69</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Illustrations
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="text-center">
                          <img
                            className="img-fluid px-3 px-sm-4 mt-3 mb-4 a6"
                            src="img/undraw_posting_photo.svg"
                            alt="..."
                          />
                        </div>
                        <p>
                          Add some quality, svg illustrations to your project
                          courtesy of{" "}
                          <a
                            target="_blank"
                            rel="nofollow"
                            href="https://undraw.co/"
                          >
                            unDraw
                          </a>
                          , a constantly updated collection of beautiful svg
                          images that you can use completely free and without
                          attribution!
                        </p>
                        <a
                          target="_blank"
                          rel="nofollow"
                          href="https://undraw.co/"
                        >
                          Browse Illustrations on unDraw &rarr;
                        </a>
                      </div>
                    </div>

                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Development Approach
                        </h6>
                      </div>
                      <div className="card-body">
                        <p>
                          SB Admin 2 makes extensive use of Bootstrap 4 utility
                          classNamees in order to reduce CSS bloat and poor page
                          performance. Custom CSS classNamees are used to create
                          custom components and custom utility classNamees.
                        </p>
                        <p className="mb-0">
                          Before working with this theme, you should become
                          familiar with the Bootstrap framework, especially the
                          utility classNamees.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
              {/*   <!-- /.container-fluid --> */}
            </div>
            {/*   <!-- End of Main Content -->

                                        <!-- Footer --> */}
            <Footer></Footer>
            {/* <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; Your Website 2021</span>
                </div>
              </div>
            </footer> */}
            {/* <!-- End of Footer --> */}
          </div>
          {/*  <!-- End of Content Wrapper --> */}
        </div>
        {/*  <!-- End of Page Wrapper -->

                                <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        {/*  <!-- Logout Modal--> */}
        {/*}  <div
          className="modal fade"
          id="logoutModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Ready to Leave?
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                Select "Logout" below if you are ready to end your current
                session.
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a className="btn btn-primary" href="login.html">
                  Logout
                </a>
              </div>
            </div>
          </div>
                              </div>*/}
      </body>
    </div>
  );
}

export default Dashboard;
