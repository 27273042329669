import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getProductDatafetch = async (options , authToken)=>{
    try{
        const {page,product_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'product/list-product',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const getproducttotalDatafetch = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/total-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function

export const getProductNamreDatafetch = async (options , authToken)=>{
  try{
      const {page,product_name}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/list-product-name',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getProductdata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function


export const getProductnamewudata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get_id-product-name',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 
export const getFeacturedata = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'feacture/get-allFeacture',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
 /*********************************************************
 *  This function is use to addBlogformData api
 *********************************************************/
 export const addProductformData = async (options, authToken, color_size_details) => {
  try {
    const { sku,hsn,gst,ean_no,shadeName,discount,product_name,product_name_id,product_type,highlights,shade_title,before_image,after_image,clinical_study_image,clinical_result_image,founder_tips_detail,shade_image,clinical_study,clinical_result, return_period,about_product,ingredients,how_to_use,favorite_product,productvariant, cancellable, returnable, category_id, subcategory_id, product_description } = options;
    const formData = new FormData();
    formData.append('product_name', product_name);
    formData.append('product_name_id', product_name_id);
    formData.append('sku', sku);
    formData.append('hsn', hsn);
    formData.append('product_type', product_type);
    formData.append('gst', gst);
    formData.append('discount', discount);
    formData.append('shade_title', shade_title);
    formData.append('shade_image', shade_image);
    formData.append('subcategory_id', subcategory_id);
    formData.append('favorite_product', favorite_product);
    formData.append('category_id', category_id);
    formData.append('category', options.category); // Add category field
    formData.append('product_description', product_description);
    formData.append('return_period', return_period);
    formData.append('about_product', about_product);
    formData.append('ingredients', ingredients);
    formData.append('how_to_use', how_to_use);
    formData.append('cancellable', cancellable);
    formData.append('returnable', returnable);
    formData.append('productvariant', productvariant); 
    formData.append('founder_tips_detail', founder_tips_detail); 
    formData.append('clinical_study', clinical_study); 
    formData.append('clinical_result', clinical_result); 
    highlights[0].images.forEach((image, imageIndex) => {
      formData.append(`highlights_${imageIndex}`, image);
  });
  
    formData.append("clinical_result_image", clinical_result_image);
    formData.append("clinical_study_image", clinical_study_image);
    formData.append("before_image", before_image);
    formData.append("after_image", after_image);
    color_size_details.forEach((color_size_detail, index) => {
      formData.append(`color_code_${index}`, color_size_detail.color_code);
      formData.append(`weight_${index}`, color_size_detail.weight);
       formData.append(`shadeName_${index}`, color_size_detail.shadeName); 
      formData.append(`productShadeName_${index}`, color_size_detail.productShadeName);
      formData.append(`productShadeDetail_${index}`, color_size_detail.productShadeDetail);
      formData.append(`ean_no_${index}`, color_size_detail.ean_no);
      formData.append(`mrp_price_${index}`, color_size_detail.mrp_price);
      formData.append(`selling_price_${index}`, color_size_detail.selling_price);
      formData.append(`productstock_${index}`, color_size_detail.productstock);
      formData.append(`show_${index}`, color_size_detail.show);
      const imagesArray = color_size_detail.images;
      // Append all images to FormData object
      console.log(imagesArray);
      imagesArray.forEach((image, imageIndex) => {
        formData.append(`images_${index}`, image);
      });
  
    });
    
    const postData = {
      url: API_ADMIN_URL + 'product/add-product',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    const res = await postRequest(postData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};







   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateProductformData = async (options, authToken, color_size_details) => {
    try {
      const { id,sku,hsn,gst,discount,ean_no,shadeName,product_name,highlights,product_type,shade_title,before_image,after_image,clinical_study_image,clinical_result_image,founder_tips_detail,shade_image,clinical_study,clinical_result, return_period,about_product,ingredients,how_to_use,favorite_product,productvariant, cancellable, returnable, category_id, subcategory_id, product_description } = options;
   console.log("color_size_details",color_size_details);
      const formData = new FormData();
      formData.append('id', id);
      formData.append('product_name', product_name);
      formData.append('sku', sku);
      formData.append('hsn', hsn);
      formData.append('shade_title', shade_title);
      formData.append('gst', gst);
    formData.append('discount', discount);
      formData.append('subcategory_id', subcategory_id);
      formData.append('product_type', product_type);
      formData.append('favorite_product', favorite_product);
      formData.append('category_id', category_id);
      formData.append('category', options.category); // Add category field
      formData.append('product_description', product_description);
      formData.append('return_period', return_period);
      formData.append('about_product', about_product);
      formData.append('ingredients', ingredients);
      formData.append('how_to_use', how_to_use);
      formData.append('cancellable', cancellable);
      formData.append('returnable', returnable);
      formData.append('productvariant', productvariant); 
      formData.append('founder_tips_detail', founder_tips_detail); 
      formData.append('clinical_study',clinical_study); 
      formData.append('clinical_result',clinical_result); 
      if (highlights[0] && highlights[0].images !== undefined) {
        highlights[0].images.forEach((image, imageIndex) => {
          formData.append(`highlights_${imageIndex}`, image);
        });
      } else {
        highlights.forEach((image, imageIndex) => {
          formData.append(`highlights_${imageIndex}`, image);
        });
      }
      
    
      formData.append("shade_image",shade_image);
      formData.append("clinical_result_image", clinical_result_image);
      formData.append("clinical_study_image", clinical_study_image);
      formData.append("before_image", before_image);
      formData.append("after_image", after_image);
      color_size_details.forEach((color_size_detail, index) => {
        formData.append(`color_code_${index}`, color_size_detail.color_code);
        formData.append(`weight_${index}`, color_size_detail.weight);
         formData.append(`shadeName_${index}`, color_size_detail.shadeName); 
        formData.append(`ean_no_${index}`, color_size_detail.ean_no);
        formData.append(`mrp_price_${index}`, color_size_detail.mrp_price);
        formData.append(`productShadeName_${index}`, color_size_detail.productShadeName);
        formData.append(`productShadeDetail_${index}`, color_size_detail.productShadeDetail);
        formData.append(`selling_price_${index}`, color_size_detail.selling_price);
        formData.append(`productstock_${index}`, color_size_detail.productstock);
        formData.append(`show_${index}`, color_size_detail.show);
        const imagesArray = color_size_detail.images;
        // Append all images to FormData object
        imagesArray.forEach((image, imageIndex) => {
          formData.append(`images_${index}`, image);
        });
    
      });
      
      const postData = {
        url: API_ADMIN_URL + 'product/edit-product',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
      const res = await postRequest(postData);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      console.error("Error:", error);
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteProductDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'product/delete-product',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

export const deleteProductNameDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'product/delete-product-name',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
/*********************************************************
 *  This function is use to getCategoryDatafetch api
 *********************************************************/
export const getCategoryDatafetch = async (options , authToken)=>{
    try{
        const {}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'category/get-category-data',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const geStatusDatafetch = async (options , authToken)=>{
  try{
      const {id,out_stock_status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/outofstock-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getSubCategoryDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'subcategory/get-subcategory-by-id',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const getsubCategoryDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'subcategory/all-subcategory',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const getCollectionDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'collection/get-allcollection',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const getGenderDatafetch = async (options , authToken) =>{
  try{
      const {category_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'gender/get-allgender',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const getColorDatafetch = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'color/get-allcolor',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const getSizeDatafetch = async (options , authToken) =>{
  try{
      const {gender_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'size/get-size-byId',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};

export const statusCategoryData = async (options , authToken)=>{
  try{
      const {id,status }= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 


export const statusProductCategoryData = async (options , authToken)=>{
  try{
      const {id,status }= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/status-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 

export const addProductBulkData = async (options, authToken) => {
  try {
    const { file} = options;
    const formData = new FormData();
    if (options.file) {
      formData.append('file', options.file);
    }
    const postData = {
      url: API_ADMIN_URL + 'product/bulk-upload',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    const res = await postRequest(postData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
export const addProductNameformData = async (options, authToken) => {
  try {
    const { product_name } = options;
    const postData = {
      url: API_ADMIN_URL + 'product/add-product-name',
      postData: options,
      headers: {
        authToken: authToken,
      },
    };
    const res = await postRequest(postData);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
export const getProductNameDatafetch = async (options, authToken) => {
  try {
    const {  } = options;
    const postData = {
      url: API_ADMIN_URL + 'product/get-product-name',
      postData: options,
      headers: {
        authToken: authToken,
      },
    };
    const res = await postRequest(postData);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

export const updateProductnameformData = async (options, authToken) => {
  try {
    const { product_name } = options;
    const postData = {
      url: API_ADMIN_URL + 'product/edit-product-name',
      postData: options,
      headers: {
        authToken: authToken,
      },
    };
    const res = await postRequest(postData);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

