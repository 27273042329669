import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import { SketchPicker } from "react-color";
import "../AllCssFile.css";
import ReactQuill from "react-quill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import {
  addProductformData,
  getCategoryDatafetch,
  getProductNameDatafetch,
  getSubCategoryDatafetch,
  getCollectionDatafetch,
  getGenderDatafetch,
  getColorDatafetch,
} from "../Controller/Product";
import AddProductpopup from "./AddProductpopup";
import { useParams, useNavigate } from "react-router-dom";
import ImgCrop from "antd-img-crop";

function ProductCreate() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [subCategories, setSubCategoryData] = useState([]);
  const [colorPickerIndex, setColorPickerIndex] = useState(null);
  const [categories, setCategoryData] = useState([]);
  const [product, setProductData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    product_name: "",
    category_id: "",
    subcategory_id: "",
    return_period: "",
    cancellable: "",
    returnable: "",
    favorite_product: "",
    productvariant: "",
    about_product: "",
    ingredients: "",
    how_to_use: "",
    sku:"",
    gst:"",
    discount:"",
    hsn:"",
    product_description: "",
    highlights: [],
    clinical_study_image: "",
    clinical_result_image: "",
    founder_tips_detail: "",
    product_type:"",
    shade_image: "",
    before_image: "",
    after_image: "",
    clinical_study: "",
    clinical_result: "",
    shade_title: "",
    variants: [
      {
        color_code: "",
        shadeName:"",
        productShadeName: "",
        productShadeDetail: "",
        weight: "",
        ean_no:"",
        mrp_price: "",
        selling_price: "",
        productstock: "",
        show: false,
        images: [],
      },
    ],
  });
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [openColorPickers, setOpenColorPickers] = useState([]);
  const [colorPickerField, setColorPickerField] = useState(null); // Add this state

  const handleFileChangeForRow = (e, rowIndex) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      const existingImages = updatedVariants[rowIndex].images; // Get existing images for the specific variant
      const newImages = [...existingImages, ...files]; // Concatenate existing images with newly selected images
      updatedVariants[rowIndex] = {
        ...updatedVariants[rowIndex],
        images: newImages, // Store all selected images for the specific variant
      };
      return {
        ...prevFormData,
        variants: updatedVariants,
      };
    });
  };

  const removeImageFromRow = (rowIndex, imageIndex) => {
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      const updatedImages = [...updatedVariants[rowIndex].images];
      updatedImages.splice(imageIndex, 1); // Remove image from selected images for the specific row
      updatedVariants[rowIndex].images = updatedImages; // Update images array for the specific variant
      return {
        ...prevFormData,
        variants: updatedVariants,
      };
    });
  };

  const handleFileChangeForRow1 = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevFormData) => {
      const updatedHighlights = [...prevFormData.highlights];
      // Always add images to the last highlight
      const lastHighlight = updatedHighlights[updatedHighlights.length - 1];
      const existingImages = lastHighlight ? [...lastHighlight.images] : [];
      const newImages = [...existingImages, ...files];
      if (lastHighlight) {
        // If last highlight exists, update its images
        lastHighlight.images = newImages;
      } else {
        // If no highlight exists, create a new one
        updatedHighlights.push({ images: newImages });
      }
      return {
        ...prevFormData,
        highlights: updatedHighlights,
      };
    });
  };

  const handleFileChangeForRow2 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        clinical_study_image: file,
      }));
    }
  };
  const handleFileChangeForRow3 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        clinical_result_image: file,
      }));
    }
  };
  const handleFileChangeForRow4 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, before_image: file }));
    }
  };
  const handleFileChangeForRow5 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, after_image: file }));
    }
  };

  const handleFileChangeForRow6 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, shade_image: file }));
    }
  };

  const removeImageFromRow1 = (rowIndex, imageIndex) => {
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.highlights];

      // Check if the highlight at the specified rowIndex exists
      if (updatedVariants[rowIndex] && updatedVariants[rowIndex].images) {
        const updatedImages = [...updatedVariants[rowIndex].images];

        // Remove the image at the specified imageIndex
        updatedImages.splice(imageIndex, 1);

        // Update the images array for the specific highlight
        updatedVariants[rowIndex].images = updatedImages;
      }

      return {
        ...prevFormData,
        highlights: updatedVariants,
      };
    });
  };
  const removeImageFromRow2 = (rowIndex, imageIndex) => {
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.clinical_result_image];

      // Check if the highlight at the specified rowIndex exists
      if (updatedVariants[rowIndex] && updatedVariants[rowIndex].images) {
        const updatedImages = [...updatedVariants[rowIndex].images];

        // Remove the image at the specified imageIndex
        updatedImages.splice(imageIndex, 1);

        // Update the images array for the specific highlight
        updatedVariants[rowIndex].images = updatedImages;
      }

      return {
        ...prevFormData,
        clinical_result_image: updatedVariants,
      };
    });
  };
  const handleInputChange = (selectedValue, field, index) => {
    if (
      field === "product_description" ||
      field === "about_product" ||
      field === "ingredients" ||
      field === "how_to_use"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
    } else if (
      field === "cancellable" ||
      field === "returnable" ||
      field === "favorite_product"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue === false ? "No" : "Yes",
      }));
    } else if (field.startsWith("variant_")) {
      const variantIndex = parseInt(field.split("_")[1], 10);
      const sizeName = field.split("_")[3];
      setFormData((prevVal) => {
        const updatedVariants = [...prevVal.variants];
        if (!updatedVariants[variantIndex]) {
          updatedVariants[variantIndex] = {}; // Initialize the variant object if not already done
        }

        if (field.includes("color_code")) {
          updatedVariants[variantIndex].color_code = selectedValue;
        } else if (field.includes("weight")) {
          updatedVariants[variantIndex].weight = selectedValue;
        } else if (field.includes("shadeName")) {
          updatedVariants[variantIndex].shadeName = selectedValue;
        } else if (field.includes("ean_no")) {
          updatedVariants[variantIndex].ean_no = selectedValue;
        } else if (field.includes("mrp_price")) {
          updatedVariants[variantIndex].mrp_price = selectedValue;
        }  else if (field.includes("productShadeName")) {
          updatedVariants[variantIndex].productShadeName = selectedValue;
        }  else if (field.includes("productShadeDetail")) {
          updatedVariants[variantIndex].productShadeDetail = selectedValue;
        } else if (field.includes("selling_price")) {
          updatedVariants[variantIndex].selling_price = selectedValue;
        } else if (field.includes("productstock")) {
          updatedVariants[variantIndex].productstock = selectedValue;
        } else if (field.includes("show")) {
          updatedVariants[variantIndex].show = selectedValue;
        } else {
          const updatedSizes = updatedVariants[variantIndex].size || [];

          if (selectedValue) {
            updatedSizes.push(sizeName);
          } else {
            const sizeIndex = updatedSizes.indexOf(sizeName);
            if (sizeIndex !== -1) {
              updatedSizes.splice(sizeIndex, 1);
            }
          }

          updatedVariants[variantIndex].size = updatedSizes;
        }

        return { ...prevVal, variants: updatedVariants };
      });
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: selectedValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [field]: "",
      }));
    }
  };


  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchProduct = async () => {
    const options = {};
    try {
      const result = await getProductNameDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setProductData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleColorChange = (newColor) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      variants: prevFormData.variants.map((variant, index) => {
        if (index === colorPickerIndex) {
          return {
            ...variant,
            [colorPickerField]: newColor.hex, // Update color code for the selected variant
          };
        }
        return variant;
      }),
    }));
  };

  const toggleColorPicker = (index, field, e) => {
    e.preventDefault(); // Prevent form submission
    const isOpen = openColorPickers.includes(index);
    if (isOpen) {
      setOpenColorPickers(openColorPickers.filter((i) => i !== index));
    } else {
      setOpenColorPickers([...openColorPickers, index]);
    }
    setColorPickerIndex(index);
    setColorPickerField(field);
  };
  const handleSelectClick = () => {
    fetchCategories();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      } else if (!formData.sku) {
        setErrors((preError) => ({
          ...preError,
          sku: "Product sku no is required.",
        }));
      }else if (!formData.hsn) {
        setErrors((preError) => ({
          ...preError,
          hsn: "Product hsn no is required.",
        }));
      } else if (!formData.return_period) {
        setErrors((preError) => ({
          ...preError,
          return_period: "Return period is required.",
        }));
      } else if (!formData.productvariant) {
        setErrors((preError) => ({
          ...preError,
          productvariant: "Product variant is required.",
        }));
      } else if (!formData.category_id) {
        setErrors((preError) => ({
          ...preError,
          category_id: "Category is required.",
        }));
      } else if (!formData.subcategory_id) {
        setErrors((preError) => ({
          ...preError,
          subcategory_id: "Sub category is required.",
        }));
      } else if (!formData.product_type) {
        setErrors((preError) => ({
          ...preError,
          product_type: "Product type is required.",
        }));
      } else if (!formData.gst) {
        setErrors((preError) => ({
          ...preError,
          gst: "Gst is required.",
        }));
      } else if (!formData.discount) {
        setErrors((preError) => ({
          ...preError,
          discount: "Discount is required.",
        }));
      }  else if (!formData.product_description) {
        setErrors((preError) => ({
          ...preError,
          product_description: "Product description is required.",
        }));
      }else if (!formData.about_product) {
        setErrors((preError) => ({
          ...preError,
          about_product: "About us is required.",
        }));
      }else if (!formData.ingredients) {
        setErrors((preError) => ({
          ...preError,
          ingredients: "Ingredient is required.",
        }));
      }else if (!formData.how_to_use) {
        setErrors((preError) => ({
          ...preError,
          how_to_use: "How to use  is required.",
        }));
      }
      else if (!formData.founder_tips_detail) {
        setErrors((preError) => ({
          ...preError,
          founder_tips_detail: "Founder tips   is required.",
        }));
      }else if (!formData.shade_title) {
        setErrors((preError) => ({
          ...preError,
          shade_title: "Shade finder title  is required.",
        }));
      }else if (!formData.clinical_study) {
        setErrors((preError) => ({
          ...preError,
          clinical_study: "Clinical study  is required.",
        }));
      }else if (!formData.clinical_result) {
        setErrors((preError) => ({
          ...preError,
          clinical_result: "Clinical result  is required.",
        }));
      }else {
        setIsSubmitting(true); 
     
        formData.return_period = document.getElementById("return_period").value;

        const [product_name_id, product_name] =
          formData.product_name.split("-");
        formData.product_name_id = product_name_id.trim();
        formData.product_name = product_name.trim();
        const updateResult = await addProductformData(
          formData,
          adminInformation.token,
          formData.variants
        );
        if (updateResult.data.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
            category_id: "",
            subcategory_id: "",
            return_period: "",
            cancellable: "",
            returnable: "",
            sku:"",
            hsn:"",
            favorite_product: "",
            about_product: "",
            ingredients: "",
            how_to_use: "",
            product_description: "",
            variants: [
              {
                color_code: "",
                shadeName:"",
                weight: "",
                productShadeDetail:"",
                productShadeName:"",
                mrp_price: "",
                selling_price: "",
                productstock: "",
                show: false,
                images: [],
              },
            ],
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    const options = {
      category_id: categoryId,
    };

    try {
      const result = await getSubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setSubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching sub-category data:", error);
    }
  };

  const handleCategorySelect = (categoryId) => {
    fetchSubCategories(categoryId);
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [
        ...prevVal.variants,
        {
          color_code: "",
          shadeName:"",
          productShadeName:"",
          productShadeDetail:"",
          weight: "",
          mrp_price: "",
          selling_price: "",
          productstock: "",
          show: false,
          images: [],
        },
      ],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCategories();
    fetchProduct();
  }, []);

  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Product Content </h5>
                  </div>
                </div>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText">
                              Product Name<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "product_name"
                                )
                              }
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                            >
                              <option value="">Select Product Name</option>
                              {product.map((product) => (
                                <option
                                  key={product._id}
                                  value={`${product._id}-${product.product_name}`}
                                >
                                  {product.product_name}
                                </option>
                              ))}
                            </select>
                            {errors?.product_name ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_name}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">SKU No<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="sku"
                            name="sku"
                            value={formData.sku}
                            onChange={(e) => handleInputChange(e.target.value, "sku")}
                          />
                          {errors?.sku ? <p style={{ color: "red" }}>{errors?.sku}</p> : ""}
                          </div>
                          <div className="col-md-4">
                          <label htmlFor="exampleInputText">HSN No<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="hsn"
                            name="hsn"
                            value={formData.hsn}
                            onChange={(e) => handleInputChange(e.target.value, "hsn")}
                          />
                          {errors?.hsn ? <p style={{ color: "red" }}>{errors?.hsn}</p> : ""}
                          </div>
                          <div className="col-md-4 ">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">
                              Return Period(In Days)<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "return_period"
                                );
                                handleCategorySelect(e.target.value);
                              }}
                              id="return_period"
                              name="return_period"
                            >
                              <option value="">Select Return Day</option>
                              <option value="7">7</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                            </select>
                            {errors?.return_period ? (
                              <p style={{ color: "red" }}>
                                {errors?.return_period}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">
                              Product Variant<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "productvariant"
                                );
                              }}
                              id="productvariant"
                              name="productvariant"
                            >
                              <option value="">Select Variant </option>
                              <option value="30 ml">30 ml</option>
                              <option value="10 ml">10 ml</option>
                              <option value="5 ml">5 ml</option>
                            </select>
                            {errors?.productvariant ? (
                              <p style={{ color: "red" }}>
                                {errors?.productvariant}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">Category<span style={{ color: 'red' }}>*</span></label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "category_id"
                                );
                                handleCategorySelect(
                                  e.target.value.split("|")[0],
                                  e.target.value.split("|")[1]
                                );
                              }}
                              onClick={handleSelectClick}
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            {errors?.category_id ? (
                              <p style={{ color: "red" }}>
                                {errors?.category_id}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">
                              Sub Category<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "subcategory_id"
                                )
                              }
                            >
                              <option value="">Select Sub Category</option>
                              {subCategories.map((subCategory) => (
                                <option
                                  key={subCategory._id}
                                  value={subCategory._id}
                                >
                                  {subCategory.name}
                                </option>
                              ))}
                            </select>
                            {errors?.subcategory_id ? (
                              <p style={{ color: "red" }}>
                                {errors?.subcategory_id}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">
                              Product Type<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "product_type"
                                );
                              }}
                              id="product_type"
                              name="product_type"
                            >
                              <option value="">Select Product Type </option>
                              <option value="Foundation">Foundation</option>
                              <option value="Shade Adjusting Drops">Shade Adjusting Drops</option>
                            </select>
                            {errors?.product_type ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_type}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">
                              GST<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section "
                              onChange={(e) => {
                                handleInputChange(
                                  e.target.value,
                                  "gst"
                                );
                              }}
                              id="gst"
                              name="gst"
                            >
                              <option value="">Select GST </option>
                              <option value="8 %">8 %</option>
                              <option value="9 %">9 %</option>
                              <option value="12 %">12 %</option>
                              <option value="18 %">18 %</option>
                            </select>
                            {errors?.gst ? (
                              <p style={{ color: "red" }}>
                                {errors?.gst}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputText" className="all_top_and_bottom_for_input_text">
                              Discount<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="discount"
                            name="discount"
                            value={formData.discount}
                            onChange={(e) => handleInputChange(e.target.value, "discount")}
                          />
                            {errors?.discount ? (
                              <p style={{ color: "red" }}>
                                {errors?.discount}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-4">
                            <label htmlFor="exampleInputCheckbox">
                              Favorite Products
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input common_category_input_for_all_section "
                              id="favorite_product"
                              name="favorite_product"
                              checked={formData.favorite_product === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked ? "Yes" : "No",
                                  "favorite_product"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputCheckbox">
                              Returnable
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input common_category_input_for_all_section "
                              id="returnable"
                              name="returnable"
                              checked={formData.returnable === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked ? "Yes" : "No",
                                  "returnable"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="exampleInputCheckbox">
                              Cancellable
                            </label>
                            <input
                              type="checkbox"
                              style={{
                                marginLeft: "14px",
                                borderColor: "#df1616",
                              }}
                              className="form-check-input common_category_input_for_all_section "
                              id="cancellable"
                              name="cancellable"
                              checked={formData.cancellable === "Yes"}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.checked ? "Yes" : "No",
                                  "cancellable"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: 'red' }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="product_description"
                            name="product_description"
                            value={formData.product_description}
                            onChange={(value) =>
                              handleInputChange(value, "product_description")
                            }
                          />
                          {errors?.product_description ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_description}
                              </p>
                            ) : (
                              ""
                            )}
                        </div>
                        <div className="form-group">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Shade</th>
                                 <th>Shade Name</th> 
                                <th>Product Shade Name</th>
                                <th>Product Shade Detail</th>
                                <th>EAN No</th>
                                <th>Weight</th>
                                <th>MRP Price</th>
                                <th>Selling Price</th>
                                <th>Stock</th>
                                <th>Active</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                             <tbody>
                              {formData.variants.map((variant, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td style={{ width: "150px" }}>
                                      <div>
                                        <div
                                          style={{ display: "inline-block" }}
                                        >
                                          <div
                                            onClick={(e) =>
                                              toggleColorPicker(
                                                index,
                                                "color_code",
                                                e
                                              )
                                            } 
                                            style={{
                                              width: "36px",
                                              height: "14px",
                                              borderRadius: "2px",
                                              backgroundColor:
                                                variant.color_code,
                                            }}
                                          >Click</div>
                                          {openColorPickers.includes(index) && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                zIndex: "2",
                                              }}
                                            >
                                              <SketchPicker
                                                color={variant.color_code}
                                                onChange={(color) =>
                                                  handleColorChange(color)
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td style={{ width: "200px" }}>
                                      <input
                                        type="text"
                                        className="form-control common_category_input_for_all_section "
                                        id={`shadeName${index}`}
                                        name={`shadeName${index}`}
                                        value={variant.shadeName}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_shadeName`,
                                            index
                                          )
                                        }
                                      />
                                    </td> 
                                    <td style={{ width: "200px" }}>
                                      <input
                                        type="text"
                                        className="form-control common_category_input_for_all_section "
                                        id={`productShadeName${index}`}
                                        name={`productShadeName${index}`}
                                        value={variant.productShadeName}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_productShadeName`,
                                            index
                                          )
                                        }
                                      />
                                    </td> 
                                    <td style={{ width: "300px" }}>
                                      <input
                                        type="text"
                                        className="form-control common_category_input_for_all_section "
                                        id={`productShadeDetail${index}`}
                                        name={`productShadeDetail${index}`}
                                        value={variant.productShadeDetail}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_productShadeDetail`,
                                            index
                                          )
                                        }
                                      />
                                      
                                    </td>
                                    <td style={{ width: "200px" }}>
                                      <input
                                        type="text"
                                        className="form-control common_category_input_for_all_section "
                                        id={`ean_no${index}`}
                                        name={`ean_no${index}`}
                                        value={variant.ean_no}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_ean_no`,
                                            index
                                          )
                                        }
                                      />
                                    </td> 
                                    <td style={{ width: "100px" }}>
                                      <input
                                        type="text"
                                        className="form-control common_category_input_for_all_section "
                                        id={`weight${index}`}
                                        name={`weight${index}`}
                                        value={variant.weight}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_weight`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section "
                                        id={`mrp_price${index}`}
                                        name={`mrp_price${index}`}
                                        value={variant.mrp_price}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_mrp_price`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section "
                                        id={`selling_price${index}`}
                                        name={`selling_price${index}`}
                                        value={variant.selling_price}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_selling_price`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section "
                                        id={`productstock${index}`}
                                        name={`productstock${index}`}
                                        value={variant.productstock}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_productstock`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td style={{ width: "50px" }}>
                                      <input
                                        type="checkbox"
                                        className="form-check common_category_input_for_all_section "
                                        id={`show_${index}`}
                                        name={`show_${index}`}
                                        checked={variant.show}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.checked,
                                            `variant_${index}_show`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td style={{ width: "100px" }}>
                                      {index > 0 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger remove-variant"
                                          onClick={() => removeVariant(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex flex-row">
                                        <input
                                          type="file"
                                          accept="image/*"
                                          multiple
                                          onChange={(e) =>
                                            handleFileChangeForRow(e, index)
                                          }
                                        />
                                        {formData.variants[index].images.map(
                                          (file, imageIndex) => (
                                            <div
                                              key={imageIndex}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Image ${imageIndex}`}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  margin: "5px",
                                                }}
                                              />
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                                onClick={() =>
                                                  removeImageFromRow(
                                                    index,
                                                    imageIndex
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "red",
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody> 
                          </table>
                          <br />
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={addVariant}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                        <fieldset>
                          <legend>About Us<span style={{ color: 'red' }}>*</span></legend>

                          <div className="form-group">
                            <label htmlFor="about_product">About Product</label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="about_product"
                              name="about_product"
                              value={formData.about_product}
                              onChange={(value) =>
                                handleInputChange(value, "about_product")
                              }
                            />
                             {errors?.about_product ? (
                              <p style={{ color: "red" }}>
                                {errors?.about_product}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="ingredients">Ingredients<span style={{ color: 'red' }}>*</span></label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="ingredients"
                              name="ingredients"
                              value={formData.ingredients}
                              onChange={(value) =>
                                handleInputChange(value, "ingredients")
                              }
                            />
                            {errors?.ingredients ? (
                              <p style={{ color: "red" }}>
                                {errors?.ingredients}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="how_to_use">How to Use<span style={{ color: 'red' }}>*</span></label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="how_to_use"
                              name="how_to_use"
                              value={formData.how_to_use}
                              onChange={(value) =>
                                handleInputChange(value, "how_to_use")
                              }
                            />
                              {errors?.how_to_use ? (
                              <p style={{ color: "red" }}>
                                {errors?.how_to_use}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend>Highlights</legend>
                          <div className="form-group">
                            <label htmlFor="highlight_images">
                              Highlight Images<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={(e) => handleFileChangeForRow1(e)}
                            />

                            {formData.highlights.map((highlight, index1) => (
                              <div key={index1}>
                                {highlight.images.map((file, imageIndex) => (
                                  <div
                                    key={imageIndex}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`Highlight ${imageIndex}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "5px",
                                      }}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      onClick={() =>
                                        removeImageFromRow1(index1, imageIndex)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>

                          <div className="form-group">
                            <label htmlFor="founder_tips_detail">
                              Founder's Tips<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="founder_tips_detail"
                              name="founder_tips_detail"
                              value={formData.founder_tips_detail}
                              onChange={(value) =>
                                handleInputChange(value, "founder_tips_detail")
                              }
                            />
                            {errors?.founder_tips_detail ? (
                              <p style={{ color: "red" }}>
                                {errors?.founder_tips_detail}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Shade Finder</legend>
                          <div className="form-group">
                            <label htmlFor="shade_title">
                              Shade Finder Title<span style={{ color: 'red' }}>*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section "
                              id="shade_title"
                              name="shade_title"
                              value={formData.shade_title}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "shade_title")
                              }
                            />
                             {errors?.shade_title ? (
                              <p style={{ color: "red" }}>
                                {errors?.shade_title}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">Shade Finder Image<span style={{ color: 'red' }}>*</span></label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader upload_all_react_antd_pic"
                                name="shade_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow6(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.shade_image ? (
                                  <img
                                    src={URL.createObjectURL(
                                      formData.shade_image
                                    )}
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Clinical Study</legend>
                          <div className="form-group">
                            <label htmlFor="clinical_study">
                              Clinical Study<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="clinical_study"
                              name="clinical_study"
                              value={formData.clinical_study}
                              onChange={(value) =>
                                handleInputChange(value, "clinical_study")
                              }
                            />
                             {errors?.clinical_study ? (
                              <p style={{ color: "red" }}>
                                {errors?.clinical_study}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="fullname">
                              Clinical Study Image<span style={{ color: 'red' }}>*</span>
                            </label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader upload_all_react_antd_pic"
                                name="clinical_study_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow2(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.clinical_study_image ? (
                                  <img
                                    src={URL.createObjectURL(
                                      formData.clinical_study_image
                                    )}
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>

                          {/* Clinical Results */}
                          <div className="form-group">
                            <label htmlFor="clinical_result">
                              Clinical Results<span style={{ color: 'red' }}>*</span>
                            </label>
                            <ReactQuill
                              className="about_us_page_edit_react_quill"
                              id="clinical_result"
                              name="clinical_result"
                              value={formData.clinical_result}
                              onChange={(value) =>
                                handleInputChange(value, "clinical_result")
                              }
                            />
                             {errors?.clinical_result ? (
                          <p style={{ color: "red" }}>
                            {errors?.clinical_result}
                          </p>
                        ) : (
                          ""
                        )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="fullname">
                              Clinical Results Image<span style={{ color: 'red' }}>*</span>
                            </label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader upload_all_react_antd_pic"
                                name="clinical_study_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow3(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.clinical_result_image ? (
                                  <img
                                    src={URL.createObjectURL(
                                      formData.clinical_result_image
                                    )}
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </fieldset>

                        <fieldset>
                          <legend>Before/After</legend>
                          <div className="form-group">
                            <label htmlFor="fullname">Before Image<span style={{ color: 'red' }}>*</span></label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader upload_all_react_antd_pic"
                                name="before_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow4(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.before_image ? (
                                  <img
                                    src={URL.createObjectURL(
                                      formData.before_image
                                    )}
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>

                          <div className="form-group">
                            <label htmlFor="fullname">After Image<span style={{ color: 'red' }}>*</span></label>

                            <ImgCrop rotate aspect={310 / 213}>
                              <Upload
                                className="avatar-uploader upload_all_react_antd_pic"
                                name="after_image"
                                onChange={({ fileList }) =>
                                  handleFileChangeForRow5(fileList)
                                } // Corrected handleFileChange function
                                listType="picture-card"
                                fileList={null} // You may want to pass formData.top_image here instead of null
                                beforeUpload={() => false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                {formData.after_image ? (
                                  <img
                                    src={URL.createObjectURL(
                                      formData.after_image
                                    )}
                                    alt="subcategory"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <svg
                                      className="icon"
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="image"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                      ></path>
                                    </svg>
                                    <div className="ant-upload-text">
                                      Upload
                                    </div>
                                  </div>
                                )}
                              </Upload>
                            </ImgCrop>
                          </div>
                        </fieldset>

         <button type="submit" className="btn btn-primary" disabled={isSubmitting} 
        style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
        {isSubmitting ? (
          <span>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...
          </span>
        ) : (
          'Submit'
        )}
      </button>
         <Link to="/productListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default ProductCreate;
