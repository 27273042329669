import React, { useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Link, useLocation } from "react-router-dom";
import moment from 'moment';
function ReviewDetail() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const location = useLocation();
  const selectedItem = location.state.selectedItem;


  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Review Detail</h5>
                  </div>
                 
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <div
                      className="my_add_event_form_field"
                      style={{ float: "right" }}
                    >
                      <Link to="/reviewList">
                        <button type="button" className="btn btn-primary">
                          Back
                        </button>
                      </Link>
                      
                    </div>
                    <div className="responsive">
                   
    <div className="form-group">
      <label htmlFor="exampleInputText">Review Detail :- </label>
      <p>Name :- {capitalizeFirstLetter(selectedItem.name)}</p>
      <p>Email :- {selectedItem.email}</p>
      <p>Description: {capitalizeFirstLetter(selectedItem.description)}</p>
      <p>Review Date: {moment(selectedItem.date_time).format('MMMM Do YYYY, h:mm:ss a')}</p>
    </div>
    {/* <div className="form-group">
  <label htmlFor="exampleInputText">Order Details:</label>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th  scope="col">Product Image</th>
        <th  scope="col">Product Name</th>
        <th  scope="col">Color</th>
        <th  scope="col">Product Price</th>
        <th  scope="col">Quantity</th>
        <th  scope="col">Total Price</th>
        <th  scope="col">Payment Mode</th>
        <th  scope="col">Payment Status</th>
        <th  scope="col">Coupon Code</th>
        <th  scope="col">Discount</th>
        <th  scope="col">Order Date</th>
      </tr>
    </thead>
    <tbody>
  {selectedItem.orderdetails.map((orderDetail, index) => {
    // Check if color_size_details exists and has length greater than 0
    if (orderDetail.product_id.color_size_details && orderDetail.product_id.color_size_details.length > 0) {
      // Find the color_size_details object where color_name matches the orderDetail color
      const colorSizeDetail = orderDetail.product_id.color_size_details.find(
        (colorSize) => colorSize.color_code === orderDetail.color
      );

      return (
        <tr key={index}>
          <td>
            <img
              src={`${imageURL}${colorSizeDetail.images[0]}`}
              alt={orderDetail.product_name}
              style={{ width: '100px', height: '100px' }}
            />
          </td>
          <td>{orderDetail.product_name} ({orderDetail.productvariant})</td>
          <td>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: orderDetail.color,
                                            marginRight: '10px',
                                            border: '1px solid #000'
                                          }}
                                        ></div>
                                      </div>
                                    </td>
          <td>{colorSizeDetail ? colorSizeDetail.mrp_price : 'N/A'}</td>
          <td>{orderDetail.qty}</td>
          <td>{orderDetail.price}</td>
          <td>{orderDetail.payment_mode}</td>
          <td>{orderDetail.payment_status}</td>
          <td>{orderDetail.coupon ? orderDetail.coupon : 'No coupon applied'}</td>
          <td>{orderDetail.discount ? orderDetail.discount : 0}</td>
          <td>{formatDate(orderDetail.createdAt)}</td>
        </tr>
      );
    } else {
      // Handle case where color_size_details is not defined or empty
      return (
        <tr key={index}>
          <td colSpan="11">Color details not available</td>
        </tr>
      );
    }
  })}
</tbody>
  </table>
</div> */}



 

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewDetail;
