import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css"; 
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import { Upload } from "antd";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { addAboutformData } from "../Controller/Home"; 
import AddblogModel from "./AddHomepopup"; 
import ImgCrop from 'antd-img-crop';

function HomeCreate() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    sequence: "",
   image: null,
  });

 

  const handleInputChange = (value, field) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      }else if (!formData.sequence) {
        setErrors((preError) => ({
          ...preError,
          sequence: 'Sequence is required.',
        }));
      }else{ 
        const updateResult = await addAboutformData(formData, adminInformation.token);
        if(updateResult.data.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
              title: "",
              description: "",
              image: "", 
            }));
            togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


 


  const handleFileChange2 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };
  
  
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Add Home Content</h5>
                </div>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                          <div className="col-md-6">
                          <label htmlFor="exampleInputText">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            onChange={(e) => handleInputChange(e.target.value, 'title')}
                          />
                          {errors.title && <p style={{ color: "red" }}>{errors.title}</p>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Sequence</label>
                          <input
                            type="number"
                            className="form-control"
                            id="sequence"
                            name="sequence"
                            onChange={(e) => handleInputChange(e.target.value, 'sequence')}
                          />
                          {errors.sequence && <p style={{ color: "red" }}>{errors.sequence}</p>}
                        </div>
                        </div>
                         
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill
  className="about_us_page_edit_react_quill"
  id="description"
  name="description"
  onChange={(value) => handleInputChange(value, 'description')}
/>


                          </div>
                            
                          <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                                

<ImgCrop rotate aspect={1401/700}>
<Upload
  className="avatar-uploader"
  name="image"
  onChange={({ fileList }) => handleFileChange2(fileList)} // Corrected handleFileChange function
  listType="picture-card"
  fileList={null} // You may want to pass formData.top_image here instead of null
  beforeUpload={() => false}
  style={{ width: '100%', height: '100%' }}
>
  {formData.image ? (
    <img src={URL.createObjectURL(formData.image)} alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  ) : (
    <div>
      <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
      </svg>
      <div className="ant-upload-text">Upload</div>
    </div>
  )}
</Upload>
</ImgCrop>

                            </div>
                         <button type="submit" className="btn btn-primary" style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default HomeCreate;
