import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { SketchPicker } from 'react-color';
import Header from "../HeaderDashBoard/Header";
import {
  addColorformData,
} from "../Controller/Color";
import AddSizeModel from "./AddColorpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Color.css";

function AddColorData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
   const [color, setColor] = useState('#ffffff'); // Default colo
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  
  const [formData, setFormData] = useState({
    color_code: "",
  });
  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    setFormData(prevFormData => ({
      ...prevFormData,
      color_code: newColor.hex // Storing selected color code
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.color_code) {
        setErrors((prevError) => ({
          ...prevError,
          color_code: "color code is required.",
        }));
      }else {
        console.log(formData);
        const updateResult = await addColorformData(formData, adminInformation.token);
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            color_code: "",
            name: "",
          }));
          togglePopUp();
        } else {
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
          setErrors((prevError) => ({
            ...prevError,
            name: "Size name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  
  /*************************************************************
          End Function
   *************************************************************/
       
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Shade </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="fullname">Shade</label>
                        <SketchPicker color={color} onChange={handleColorChange} />
                      </div>
                     
                      <button
                        type="submit"
                        className="btn btn-primary"
                       
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            
            </div>
            <AddSizeModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddColorData;
