import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css"; 
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import { Upload } from "antd";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { addAboutformData } from "../Controller/Contact"; 
import AddblogModel from "./AddContactpopup"; 
import ImgCrop from 'antd-img-crop';

function ContactCreate() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
  const [formData, setFormData] = useState({
    luar_headquarters: "",
    contact_us: "",
    careers: "",
    business_enquire: "",
    press_bloggers: "",
    customer_service: "",
   image: null,
  });

 

  const handleInputChange = (value, field) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.luar_headquarters) {
        setErrors((preError) => ({
          ...preError,
          luar_headquarters: 'Luar headquarter is required.',
        }));
      }else if (!formData.contact_us) {
        setErrors((preError) => ({
          ...preError,
          contact_us: 'Contact us is required.',
        }));
      }else if (!formData.careers) {
        setErrors((preError) => ({
          ...preError,
          careers: 'Careers is required.',
        }));
      }else if (!formData.business_enquire) {
        setErrors((preError) => ({
          ...preError,
          business_enquire: 'Business enquire is required.',
        }));
      }else if (!formData.press_bloggers) {
        setErrors((preError) => ({
          ...preError,
          press_bloggers: 'Press/Bloggers is required.',
        }));
      }else if (!formData.customer_service) {
        setErrors((preError) => ({
          ...preError,
          customer_service: 'Customer service is required.',
        }));
      }else{ 
        setIsSubmitting(true);
        const updateResult = await addAboutformData(formData, adminInformation.token);
        if(updateResult.data.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
                luar_headquarters: "",
                contact_us: "",
                careers: "",
                business_enquire: "",
                press_bloggers: "",
                customer_service: "",
               image: null,
            }));
            setIsSubmitting(false);
            togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


 


  const handleFileChange2 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };
  
  
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Add Contact Content </h5>
                </div>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                      <div className="col-md-6">
                          <label htmlFor="exampleInputText">Luar Headquarter<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="luar_headquarters"
                            name="luar_headquarters"
                            onChange={(e) => handleInputChange(e.target.value, 'luar_headquarters')}
                          />
                          {errors.luar_headquarters && <p style={{ color: "red" }}>{errors.luar_headquarters}</p>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Contact Us<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="contact_us"
                            name="contact_us"
                            onChange={(e) => handleInputChange(e.target.value, 'contact_us')}
                          />
                          {errors.contact_us && <p style={{ color: "red" }}>{errors.contact_us}</p>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Careers<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="careers"
                            name="careers"
                            onChange={(e) => handleInputChange(e.target.value, 'careers')}
                          />
                          {errors.careers && <p style={{ color: "red" }}>{errors.careers}</p>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Business Enquire<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="business_enquire"
                            name="business_enquire"
                            onChange={(e) => handleInputChange(e.target.value, 'business_enquire')}
                          />
                          {errors.business_enquire && <p style={{ color: "red" }}>{errors.business_enquire}</p>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Press/Bloggers<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="press_bloggers"
                            name="press_bloggers"
                            onChange={(e) => handleInputChange(e.target.value, 'press_bloggers')}
                          />
                          {errors.press_bloggers && <p style={{ color: "red" }}>{errors.press_bloggers}</p>}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="exampleInputText">Customer Service<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="customer_service"
                            name="customer_service"
                            onChange={(e) => handleInputChange(e.target.value, 'customer_service')}
                          />
                          {errors.customer_service && <p style={{ color: "red" }}>{errors.customer_service}</p>}
                        </div>
                        </div>
                            
                          <div className="form-group">
                            <label htmlFor="fullname">Image</label>
                                

<ImgCrop rotate aspect={310/213}>
<Upload
  className="avatar-uploader"
  name="image"
  onChange={({ fileList }) => handleFileChange2(fileList)} // Corrected handleFileChange function
  listType="picture-card"
  fileList={null} // You may want to pass formData.top_image here instead of null
  beforeUpload={() => false}
  style={{ width: '100%', height: '100%' }}
>
  {formData.image ? (
    <img src={URL.createObjectURL(formData.image)} alt="subcategory" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
  ) : (
    <div>
      <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
      </svg>
      <div className="ant-upload-text">Upload</div>
    </div>
  )}
</Upload>
</ImgCrop>

                            </div>
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/contactListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6", marginLeft: "12px" }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default ContactCreate;
