import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import ProductDeletePopUp from "./ProductDeletePopUp";
import ProductOutPopUp from "./ProductStockPopUp";
import Pagination from "@mui/material/Pagination";
import {
  getProductDatafetch,
  getCategoryDatafetch,
  getsubCategoryDatafetch,
} from "../Controller/Product";

function ListProduct() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [itemIdToOut, setItemIdToOut] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstock, setPopUpOpenstock] = useState(false);
  const [productList, setProductData] = useState([]);
  const [categories, setCategoryData] = useState([]);
  const [subcategories, setsubCategoryData] = useState([]);
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getProductdata();
  };
  const handleRowClickout = (itemId, out_stock_status) => {
    setPopUpOpenstock(!popUpOpenstock);
    setItemIdToOut(itemId);
    setItemIdStatus(out_stock_status);
    getProductdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getProductdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getProductDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.product;
        const totalcount = result.data.response.result.totalcount;

        setProductData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN-INFO");
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleRowClick = (itemId) => {
    navigate(`/editProductData/${itemId}`);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getProductdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getProductdata(currentPage, value);
  };
  const capitalizeAfterSpace = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };
  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const fetchSubCategories = async () => {
    const options = {};
    try {
      const result = await getsubCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setsubCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(status === "A" ? "I" : "A");
    getProductdata();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductdata(currentPage, searchValue);
    fetchCategories();
    fetchSubCategories();
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const dropdownMenu = (itemId, status) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Products List </h1>
                <div
                  className="card shadow mb-4"
                  style={{ backgroundColor: "#fff8f9" }}
                >
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold">Products List Table</h6>
                    <div
                      className="my_add_event_form_field subcatgory_select_option"
                      style={{ float: "right" }}
                    >
                      <Link to="/createProductData">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#e7aeb6",
                            color: "#fff",
                            borderColor: "#e7aeb6",
                          }}
                        >
                          Add Product
                        </button>
                      </Link>
                       <Link to="/productBulkData">
                      <button
                        type="button"
                        className="btn btn-primary" 
                        style={{
                          backgroundColor: "#e7aeb6",
                          color: "#fff",
                          borderColor: "#e7aeb6",
                        }}
                      >
                        Add Bulk Product
                      </button>
                    </Link>  
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Category</th>
                            <th scope="col">Sub Category</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Product Variant</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Last Updated Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.length > 0 ? (
                            productList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>
                                  {categories.find(
                                    (category) =>
                                      category._id === item.category_id
                                  )?.name || ""}
                                </td>
                                <td>
                                  {subcategories.find(
                                    (subcategory) =>
                                      subcategory._id === item.subcategory_id
                                  )?.name || ""}
                                </td>
                                <td>
                                  {capitalizeAfterSpace(item.product_name)}
                                </td>
                                <td>
                                  {item.productvariant}
                                </td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                {/*<td
                                  style={{
                                    color:
                                      item.out_stock_status === "A"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {item.out_stock_status === "A"
                                    ? "In Stock"
                                    : "Out of Stock"}
                                </td>*/}
                                <td>
                                  <Dropdown
                                    overlay={() =>
                                      dropdownMenu(item._id, item.status)
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    {/* <FaRegCommentDots /> */}
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <p>
                        Show {(currentPage - 1) * 10 + 1} to{" "}
                        {Math.min(currentPage * 10, totalItems)} of {totalItems}
                      </p>

                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductDeletePopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      />
      <ProductOutPopUp
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange}
      />
    </div>
  );
}

export default ListProduct;
