import React, { useState } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { addBannerformData,getCategoryDatafetch} from '../../Controller/AboutProduct';
import AddbannerModel from "./Addproductpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import "react-dates/initialize";
import "./Productabout.css";
import ImgCrop from 'antd-img-crop';
function AddProductdetailData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
    const [errors, setErrors] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [categories, setCategoryData] = useState([]);
    const navigate = useNavigate();
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      clinical_title: "",
      clinical_detail: "",
      shade_title: "",
      before_title:"",
      clinical_image:"",
      shade_image:"",
      product_id:"",
      before_image:"",
      after_image:"",
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
    
    
     const handleFileChange2 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, shade_image: file }));
      }
    };
    const handleFileChange1 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, clinical_image: file }));
      }
    };
    const handleFileChange3 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, before_image: file }));
      }
    };
    const handleFileChange4 = (fileList) => {
      // Ensure fileList is not empty
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        setFormData((prevFormData) => ({ ...prevFormData, after_image: file }));
      }
    };

    
    const handleInputChange = (e) => {
      const { name, value} = e.target;
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: value,
        }));
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
    };
    
   
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_id) {
        setErrors((preError) => ({
          ...preError,
          product_id: 'Product is required.',
        }));
      }else if (!formData.clinical_title) {
        setErrors((preError) => ({
          ...preError,
          clinical_title: 'Clinical title is required.',
        }));
      }else if (!formData.shade_title) {
        setErrors((preError) => ({
          ...preError,
          shade_title: 'Shade title is required.',
        }));
      }else if (!formData.before_title) {
        setErrors((preError) => ({
          ...preError,
          before_title: 'Before title is required.',
        }));
      }else if (!formData.clinical_detail) {
        setErrors((preError) => ({
          ...preError,
          clinical_detail: 'Clinical detail is required.',
        }));
      }else if (!formData.clinical_image) {
        setErrors((preError) => ({
          ...preError,
          clinical_image: 'Clinical image is required.',
        }));
      }else if (!formData.shade_image) {
        setErrors((preError) => ({
          ...preError,
          shade_image: 'Shade image is required.',
        }));
      }else if (!formData.before_image) {
        setErrors((preError) => ({
          ...preError,
          before_image: 'Before image is required.',
        }));
      }else if (!formData.after_image) {
        setErrors((preError) => ({
          ...preError,
          after_image: 'After image is required.',
        }));
      }else{
        const updateResult = await addBannerformData(formData, adminInformation.token);
        if(updateResult.status === 200){
            setFormData((prevFormData) => ({
                ...prevFormData,
                clinical_title: "",
                clinical_detail: "",
                shade_title: "",
                before_title:"",
                clinical_image:"",
                shade_image:"",
                product_id:"",
                before_image:"",
                after_image:"",
            }));
          togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };


  const handleSelectClick = () => {
    fetchCategories();
  };
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add About Product </h1>
                <div className="card shadow mb-4"  style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group row">
            <div className="col-md-6">
                            <label htmlFor="exampleInputText">Product</label>
                            <select
                              className="form-control"
                              name="product_id"
                              id="product_id"
                              onChange={handleInputChange}
                              onClick={handleSelectClick}
                            >
                              <option value="">Select Product</option>
                               {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.product_name}
                                </option>
                              ))} 
                            </select>
                            {errors?.product_id ? <p style={{ color: "red" }}>{errors?.product_id}</p> : ""}
                          </div>
                       
             
              <div className="col-md-6">
                <label htmlFor="clinical_title">Clinical Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="clinical_title"
                  name="clinical_title"
                  onChange={handleInputChange}
                  value={formData.clinical_title}
                  placeholder="Enter Button URL"
                />
                {errors.clinical_title && <p style={{ color: "red" }}>{errors.clinical_title}</p>}
              </div>
              <div className="col-md-6">
                <label htmlFor="shade_title">Shade Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="shade_title"
                  name="shade_title"
                  onChange={handleInputChange}
                  value={formData.shade_title}
                  placeholder="Enter Button Title"
                />
                {errors.shade_title && <p style={{ color: "red" }}>{errors.shade_title}</p>}
              </div>
              <div className="col-md-6">
                <label htmlFor="before_title">Before Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="before_title"
                  name="before_title"
                  onChange={handleInputChange}
                  value={formData.before_title}
                  placeholder="Enter Button Title"
                />
                {errors.before_title && <p style={{ color: "red" }}>{errors.before_title}</p>}
              </div>
            </div>
            <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Clinical Detail</label>
                          <ReactQuill
  className="about_us_page_edit_react_quill"
  id="clinical_detail"
  name="clinical_detail"
  value={formData.clinical_detail}
  onChange={(value) => {
    const event = { target: { name: "clinical_detail", value } };
    handleInputChange(event);
  }}
/>

                        </div>
            <div className="form-group row">
              <div className="col-md-6">
                <label htmlFor="clinical_image">Clinical Image</label>
                 <ImgCrop rotate aspect={1514 / 749}>
                  <Upload
                    className="avatar-uploader"
                    name="clinical_image"
                    onChange={({ fileList }) => handleFileChange1(fileList)}
                    listType="picture-card"
                    fileList={null}
                    beforeUpload={() => false}
                    style={{ width: '100%', height: '100%' }}
                  >
                    {formData.clinical_image ? (
                      <img src={URL.createObjectURL(formData.clinical_image)} alt="banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    ) : (
                      <div>
                        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
                        </svg>
                        <div className="ant-upload-text">Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </div>
              <div className="col-md-6">
                <label htmlFor="shade_image">Shade Image</label>
                <ImgCrop rotate aspect={1514 / 749}>
                  <Upload
                    className="avatar-uploader"
                    name="shade_image"
                    onChange={({ fileList }) => handleFileChange2(fileList)}
                    listType="picture-card"
                    fileList={null}
                    beforeUpload={() => false}
                    style={{ width: '100%', height: '100%' }}
                  >
                    {formData.shade_image ? (
                      <img src={URL.createObjectURL(formData.shade_image)} alt="banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    ) : (
                      <div>
                        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
                        </svg>
                        <div className="ant-upload-text">Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop> 


              </div>
              <div className="col-md-6">
                <label htmlFor="before_image">Before Image</label>
                <ImgCrop rotate aspect={1514 / 749}>
                  <Upload
                    className="avatar-uploader"
                    name="before_image"
                    onChange={({ fileList }) => handleFileChange3(fileList)}
                    listType="picture-card"
                    fileList={null}
                    beforeUpload={() => false}
                    style={{ width: '100%', height: '100%' }}
                  >
                    {formData.before_image ? (
                      <img src={URL.createObjectURL(formData.before_image)} alt="banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    ) : (
                      <div>
                        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
                        </svg>
                        <div className="ant-upload-text">Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop> 


              </div>
              <div className="col-md-6">
                <label htmlFor="after_image">After Image</label>
                <ImgCrop rotate aspect={1514 / 749}>
                  <Upload
                    className="avatar-uploader"
                    name="after_image"
                    onChange={({ fileList }) => handleFileChange4(fileList)}
                    listType="picture-card"
                    fileList={null}
                    beforeUpload={() => false}
                    style={{ width: '100%', height: '100%' }}
                  >
                    {formData.after_image ? (
                      <img src={URL.createObjectURL(formData.after_image)} alt="banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    ) : (
                      <div>
                        <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M0 64v384h512V64H0zm480 352H32V96h448v320z"></path>
                        </svg>
                        <div className="ant-upload-text">Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop> 


              </div>
            </div>
            <button type="submit" className="btn btn-primary" style={{ backgroundColor: "#e7aeb6", color: "#fff", borderColor: "#e7aeb6" }}>
              Submit
            </button>
          </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductdetailData;
